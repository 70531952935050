import clsx from "clsx";
import { Badge } from "react-bootstrap";

const AccreditationStatusBadge = ({ accredited = null }) => {
  const isAccredited = accredited === true;
  const isNotAccredited = accredited === false;
  const isUnknown = accredited !== true && accredited !== false;

  return (
    <Badge
      bg={clsx({
        success: isAccredited,
        secondary: isUnknown,
        warning: isNotAccredited,
      })}
    >
      {isAccredited && "Accredited"}
      {isNotAccredited && "Not Accredited"}
      {isUnknown && "Not Reported"}
    </Badge>
  );
};

const AccreditationBadActorBadge = ({ badActor = null }) => {
  const isBadActor = badActor === true;
  const isGoodActor = badActor === false;
  const isUnknown = !badActor !== true && !badActor !== false;

  return (
    <Badge
      bg={clsx({
        success: isGoodActor,
        danger: isBadActor,
        secondary: isUnknown,
      })}
    >
      {isGoodActor && "Good Actor"}
      {isBadActor && "Bad Actor"}
      {isUnknown && "Not Reported"}
    </Badge>
  );
};

const AccreditationIncomeBadge = ({ income = null }) => {
  return (
    <Badge bg={income ? "success" : "secondary"}>
      {income ? "Encrypted" : "Not Reported"}
    </Badge>
  );
};

const AccreditationListItem = ({ title, children }) => {
  return (
    <div className="list-group-item">
      <div className="d-flex align-content-center align-items-center justify-content-between">
        <span>{title}</span>
        <span className="mx-auto"></span>
        <span className="fs-3">{children}</span>
      </div>
    </div>
  );
};

const InvestmentAccreditation = ({ investment }) => {
  if (!investment) return null;

  return (
    <div className="card">
      <div className="card-header">
        <h4 className="card-header-title">Self-Accreditation Report</h4>
      </div>
      <div className="card-body py-2">
        <div className="list-group list-group-flush">
          <AccreditationListItem title="Accreditation Status">
            <AccreditationStatusBadge accredited={investment.accredited} />
          </AccreditationListItem>
          <AccreditationListItem title="Annual Income Reported">
            <AccreditationIncomeBadge income={investment.annual_income} />
          </AccreditationListItem>
          <AccreditationListItem title="Bad Actor Status">
            <AccreditationBadActorBadge badActor={investment.bad_actor} />
          </AccreditationListItem>
        </div>
      </div>
    </div>
  );
};

export default InvestmentAccreditation;

import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { Formik } from "formik";

import FormControl from "../../../components/formControl";
import Spinner from "../../../components/spinner";
import { updateOffering } from "../../../helpers";
import { useOffering } from "../../../hooks/useOfferings";
import { profileFormSchema } from "../../../constants/forms";

const WizardProfile = (props) => {
  const history = useHistory();
  const [spinner, showSpinner] = useState(false);

  const { id } = useParams();
  const { data: offering, refetch } = useOffering(id);

  const handleSubmit = async (data) => {
    showSpinner(true);
    await updateOffering(offering.id, data);
    refetch();
    showSpinner(false);
    history.push(`/offering/${offering.id}/wizard/subscription`);
  };

  if (!offering) {
    return <Spinner show />;
  }

  return (
    <>
      <h2>Tell us more about your company</h2>
      <hr />

      <Spinner show={spinner}>
        <Formik
          enableReinitialize={true}
          initialValues={offering}
          validationSchema={profileFormSchema}
          onSubmit={handleSubmit}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ handleChange, handleSubmit, setFieldValue, values, errors }) => (
            <form noValidate onSubmit={handleSubmit}>
              <FormControl
                type="text"
                name="name"
                title="Offering Name"
                required
                value={values.name}
                error={errors.name}
                onChange={handleChange}
              />

              <FormControl
                type="textarea"
                name="description"
                required
                title="Company Business Description"
                value={values.description}
                error={errors.description}
                onChange={handleChange}
              />

              <FormControl
                type="checkbox"
                name="has_stock_ticker"
                label="Do you have a stock ticker?"
                value={values.has_stock_ticker}
                error={errors.has_stock_ticker}
                onChange={(e) => {
                  handleChange(e);
                  setFieldValue("stock_ticker", "");
                }}
              />

              {(values.has_stock_ticker || !!values.stock_ticker) && (
                <FormControl
                  type="text"
                  name="stock_ticker"
                  title="Stock Ticker"
                  value={values.stock_ticker}
                  error={errors.stock_ticker}
                  onChange={handleChange}
                />
              )}

              <hr />

              <button className="btn btn-eq-primary" type="submit">
                Save &amp; Continue
              </button>
            </form>
          )}
        </Formik>
      </Spinner>
    </>
  );
};

export default WizardProfile;

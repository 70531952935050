/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-loop-func */
import axios from "axios";
import FileSaver from "file-saver";
import { Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import NumberFormat from "react-number-format";
import { connect, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert";
import * as Yup from "yup";

import InfoCard from "../../components/card";
import StatusBadge from "../../components/investmentStatus";
import Spinner from "../../components/spinner";
import
  {
    getSettingById,
    getSettingsByOfferingId,
    isAdmin,
    isOfferingViewer,
    updateOffering,
  } from "../../helpers";
import AppHeader from "../../layouts/header";

import
  {
    AccountType,
    Company,
  } from "../../../../investor/src/pages/offering/OpenOfferingUserInfo";
import UserClient from "../../clients/UserClient";
import { onCleanForm } from "../../helpers/form";
import statusList from "./../../data/status_investment.json";

const Investors = (props) => {
  const { id } = useParams();
  const [offering, setOffering] = useState({});
  const [isAuthorizedSignatory, setIsAuthorizedSignatory] = useState(false);
  const [investments, setInvestments] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const [spinner, showSpinner] = useState(false);
  const [closeBtnLoader, setCloseBtnLoader] = useState(false);

  const [inviteModal, showInviteModal] = useState(false);
  const [addInvestorModal, showAddInvestorModal] = useState(false);

  const [stopModal, showStopModal] = useState(false);
  const [reconModal, showReconModal] = useState(false);

  const [email, setEmail] = useState("");
  const [filter, setFilter] = useState("");
  const [bulkEdit, setBulkEdit] = useState(false);

  const [summary, setSummary] = useState({});
  const user = useSelector((state) => state.user);

  const [bulkEditValues, setBulkEditValues] = useState([]);
  const [completeStatus, setCompleteStatus] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    showSpinner(true);
    axios.get(`/offering/${id}`).then((response) => {
      let offering = response.data;

      offering.unit_price = parseFloat(
        offering.unit_price.replace(/[^0-9.-]+/g, "")
      );
      offering.total_amount = parseFloat(
        offering.total_amount.replace(/[^0-9.-]+/g, "")
      );
      offering.investor_minimum_amount =
        offering.investor_minimum_units * offering.unit_price;
      setOffering(offering);

      axios.get(`/offering/${id}/investment`).then((response) => {
        let investments = response.data;

        for (let i = 0; i < investments.length; i++) {
          investments[i].amount = parseFloat(
            investments[i].amount.replace(/[^0-9.-]+/g, "")
          );
          investments[i].approved_amount = investments[i].approved_amount
            ? parseFloat(
                investments[i].approved_amount.replace(/[^0-9.-]+/g, "")
              )
            : investments[i].amount;
        }

        setInvestments(investments);
        showSpinner(false);
      });
    });

    getSettingsByOfferingId(id).then((settings) => {
      const isAuthorizedSignatory =
        getSettingById(settings, "authorised_signatory_email") === user.email;
      setIsAuthorizedSignatory(isAuthorizedSignatory);
    });
  };

  useEffect(() => {
    if (selectedItems.length > 0) {
      const arr = [];
      selectedItems.forEach((id) => {
        const filter = investments.filter((x) => x.id === id);
        const data = {
          id: filter[0]?.id,
          approved_amount: filter[0]?.approved_amount,
          approved_quantity: filter[0]?.approved_quantity,
        };
        arr.push(data);
      });
      setBulkEditValues(arr);
    } else {
      setBulkEdit(false);
    }

    if (bulkEditValues.length > 0) {
      bulkEditValues.forEach((item) => {
        if (!selectedItems.includes(item.id)) {
          let index = investments.findIndex((x) => x.id === item.id);
          investments[index].approved_amount = item.approved_amount;
          investments[index].approved_quantity = item.approved_quantity;
          investments[index].edited = false;
        }
      });
    }

    closeAllSetStatus();
  }, [selectedItems]);

  const closeAllSetStatus = () => {
    let arr = [];
    if (selectedItems?.length > 0) {
      setCloseBtnLoader(true);
      investments?.forEach((investment) => {
        selectedItems?.forEach((AcceptedId) => {
          if (investment?.id === AcceptedId) {
            if (investment?.agreements) {
              axios
                .get(
                  `investment/${AcceptedId}/agreement/${investment?.agreements[0]?.envelope_id}/recipients`
                )
                .then((res) => {
                  res.data.forEach((status) => {
                    if (status.status === "completed") {
                      arr.push(true);
                    } else {
                      arr.push(false);
                    }
                  });
                  arr.includes(false)
                    ? setCompleteStatus(false)
                    : setCompleteStatus(true);
                  setCloseBtnLoader(false);
                });
            }
          }
        });
      });
    } else {
      setCompleteStatus(false);
    }
  };

  useEffect(() => {
    let summary = {};

    summary.total = offering.total_amount;

    summary.paid = 0;
    investments
      .filter((item) => item.status === "Payment Successful")
      .forEach((item) => {
        if (item.approved_amount) {
          summary.paid += parseFloat(item.approved_amount);
        } else {
          summary.paid += parseFloat(item.amount);
        }
      });

    summary.accepted = 0;
    investments
      .filter((item) => item.status === "Accepted")
      .forEach((item) => (summary.accepted += item.amount));

    summary.closed = 0;
    investments
      .filter((item) => item.status === "Completed")
      .forEach((item) => (summary.closed += item.amount));

    summary.progress = parseFloat((summary.closed * 100) / summary.total);

    summary.allow_closing =
      summary.paid + summary.accepted + summary.closed >
      offering.minimum_units * offering.unit_price;

    setSummary(summary);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investments]);

  const phoneRegExp = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

  const inviteInitialValues = {
    email: "",
    first_name: null,
    middle_name: null,
    last_name: null,
  };

  const reconInitialValues = {
    reason: "",
    recon_amount: offering?.total_amount,
    recon_unit_price: offering?.unit_price,
  };

  const createInitialValues = {
    first_name: "",
    middle_name: "",
    last_name: "",
    entity_type: null,
    title: "",
    phone: "",
  };

  const createSchema = Yup.object().shape({
    first_name: Yup.string().min(2).required("Required"),
    middle_name: Yup.string().nullable(true),
    last_name: Yup.string().min(2).required("Required"),
    phone: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
    entity_type: Yup.string().nullable().required(
      "Please select either individual or entity"
    ),
    company: Yup.string()
      .nullable()
      .when("entity_type", {
        is: (entity_type) => entity_type === "Entity",
        then: Yup.string().required(
          "Company name is required when investing as an entity"
        ),
      }),
  });

  const inviteSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email")
      .required("Required."),
  });

  const reconSchema = Yup.object().shape({
    reason: Yup.string().required("Required"),
    recon_amount: Yup.number().required("Required"),
    recon_unit_price: Yup.number().required("Required."),
  });

  const sendInvite = async (form) => {
    if (investments.find((inv) => inv.investor.email === form.email))
      return toast.error("Investor already invited");
    showSpinner(true);
    axios
      .get(`/user/${form.email}`)
      .then((response) => {
        let data = { ...offering, launch_date: new Date() };
        updateOffering(offering.id, data).then((_) => {
          showSpinner(false);
        });
        createInvestment(response.data);
      })
      .catch((error) => {
        setEmail(form.email);
        showInviteModal(false);
        showAddInvestorModal(true);
        showSpinner(false);
      });
  };

  const sendInviteToNewInvestor = async (form) => {
    showSpinner(true);

    const registerData = {
      first_name: form.first_name,
      middle_name: form.middle_name || null,
      last_name: form.last_name,
      company: form.company || null,
      entity_type: form.entity_type.toUpperCase(),
      email: email.toLowerCase().trim(),
      title: form.title || null,
      phone: form.phone,
    };

    const newUser = await UserClient.invite(registerData);
    createInvestment(newUser);
  };

  const handleCheckChange = (e, id) => {
    let items = [...selectedItems];
    if (e.target.checked) {
      items.push(id);
      setSelectedItems(items);
    } else {
      items = items.filter((x) => x !== id);
      setSelectedItems(items);
    }
  };

  const createInvestment = (user) => {
    axios
      .post("investment", {
        issuer_id: offering.issuer_id,
        offering_id: offering.id,
        investor_id: user.id,
      })
      .then((response) => {
        axios
          .post(`investment/${response.data.id}/status/invite_sent`)
          .then((response) => {
            getData();
            showAddInvestorModal(false);
            showInviteModal(false);
            toast.success(
              `${user.first_name} ${user.last_name} has been invited`
            );
          });
      });
  };

  const handleAcceptAll = async () => {
    const status = await Swal({
      title: `Accept Investments?`,
      text: `You will be redirected to DocuSign to countersign the pending investments, do you want to continue?`,
      buttons: ["Cancel", "Yes"],
      icon: "success",
      dangerMode: true,
    });

    if (status) {
      showSpinner(true);

      if (selectedItems.length === 0)
        investments
          .filter((x) => x.status === filter)
          .forEach((item) => selectedItems.push(item.id));
      const items = investments.filter((x) => selectedItems.includes(x.id));

      for (const investment of items) {
        const agreement = investment.agreements?.filter(
          (x) =>
            x.investor_status === "complete" && x.issuer_status !== "complete"
        );

        if (agreement?.length > 0) {
          try {
            const response = await axios.get(
              `investment/${investment.id}/agreement/${agreement[0].envelope_id}/url/issuer`
            );
            window.open(response.data.url, "_blank");
          } catch (error) {
            console.log(error);
          }
        }
      }

      showSpinner(false);
    }
  };

  const handleCloseAll = () => {
    Swal({
      title: `Close Investments?`,
      text: `The closing letter for all accepted investors will be sent to the Transer Agent for closing, do you want to continue?`,
      buttons: ["Cancel", "Yes"],
      icon: "success",
      dangerMode: true,
    }).then((status) => {
      if (status) {
        showSpinner(true);
        let amount = 0;
        if (selectedItems.length === 0)
          investments
            .filter((x) => x.status === filter)
            .forEach((item) => selectedItems.push(item.id));
        selectedItems.forEach((item) => {
          const total = investments.filter((x) => x.id === item);
          amount += total[0].amount;
        });
        getSettingsByOfferingId(id).then((settings) => {
          const bankType = getSettingById(settings, "payment_bank_type");
          if (
            bankType.toLowerCase() === "escrow" ||
            bankType.toLowerCase() === "escrow est"
          ) {
            toast.success(
              `The funds in the amount of $${amount.toLocaleString()} will be wired to your bank account within 24 hours.`
            );
          }
        });
        axios.post(`investment/close`, selectedItems).then((response) => {
          getData();
          setFilter("");
          setSelectedItems([]);
        });
      }
    });
  };

  const handleRecon = () => {
    Swal({
      title: `Send Recon?`,
      text: `Recon will be sent to all investors who have signed the subscription agreement, except the onces already accepted or closed, do you want to continue?`,
      buttons: ["Cancel", "Yes"],
      icon: "success",
      dangerMode: true,
    }).then((status) => {
      if (status) {
        const investmentStatuses = [
          "Agreement Pending",
          "Agreement Signed",
          "Payment Pending",
          "Payment Successful",
          "Payment Failed",
        ];
        investments.forEach((investment) => {
          if (investmentStatuses.includes(investment.status)) {
            axios
              .post(`investment/${investment.id}/status/recon_pending`)
              .then((response) => {
                getData();
              });
          }
        });
      }
    });
  };

  const handleBulkEdit = (id, value) => {
    let index = investments.findIndex((x) => x.id === id);
    investments[index].approved_amount = value;
    investments[index].approved_quantity = value / offering.unit_price;
    investments[index].edited = true;
    setInvestments(Object.create(investments));
  };

  const handleAcceptBulkEdit = async () => {
    showSpinner(true);
    let items = investments.filter((x) => x.status === filter);
    for (let i in items) {
      if (items[i].edited)
        await axios.post(
          `investment/${items[i].id}/approved_quantity/${items[i].approved_quantity}`
        );
      else if (items[i].approved_quantity === undefined)
        await axios.post(
          `investment/${items[i].id}/approved_quantity/${items[i].quantity}`
        );
    }

    showSpinner(false);
    setBulkEdit(false);
  };

  const downloadFormD = async () => {
    showSpinner(true);
    axios.get(`offering/${id}/form-d`, selectedItems).then((response) => {
      FileSaver.saveAs(
        `data:application/pdf;base64,${response.data}`,
        "form-d.pdf"
      );
      showSpinner(false);
    });
  };

  const downloadTail = async () => {
    const response = await axios.get(`offering/${id}/closing`);

    FileSaver.saveAs(
      `data:application/pdf;base64,${response.data}`,
      `Offering TAIL - ${offering.name}.pdf`
    );
  };

  const deleteAllInvestor = async (id, index) => {
    Swal({
      title: `Delete`,
      text: `Are you sure you want to delete it?`,
      buttons: ["Cancel", "Delete"],
      icon: "warning",
      dangerMode: true,
    }).then((status) => {
      if (status) {
        let temp = [...investments];
        if (selectedItems.length > 0) {
          selectedItems.forEach((item, key) => {
            temp.forEach((i) => {
              if (i.id === item) {
                const ind = temp.indexOf(i);
                axios
                  .delete(`/investment/${item}`)
                  .then((res) => {
                    temp.splice(ind, 1);
                    setInvestments(temp);
                    setSelectedItems([]);
                  })
                  .catch(() => {
                    toast.error(
                      "Something went wrong, please try again later."
                    );
                  });
              }
            });
            if (selectedItems.length - 1 === key) {
              toast.success("Investors has been deleted");
            }
          });
        } else if (id) {
          axios.delete(`/investment/${id}`).then((res) => {
            temp.splice(index, 1);
            setInvestments(temp);
            toast.success("Investor has been deleted");
          });
        }
      }
    });
  };

  const canEdit = !isOfferingViewer(user, offering.legacy_id);

  return (
    <>
      <AppHeader id={id} name="Investors" title={offering.name}>
        {offering.status === "Active" && canEdit && (
          <div
            className="d-flex d-justify-content-end"
            style={{ columnGap: "1em" }}
          >
            <Dropdown>
              <Dropdown.Toggle variant="secondary" id="documents-dropdown">
                Documents
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {investments.filter((item) => item.status === "Completed")
                  .length > 0 && (
                  <Dropdown.Item onClick={() => downloadFormD()}>
                    <i className="fe fe-download" /> Download Form D
                  </Dropdown.Item>
                )}
                {isAdmin(user) && (
                  <>
                    <Dropdown.Item onClick={() => downloadTail()}>
                      <i className="fe fe-download" /> Download TAIL
                    </Dropdown.Item>
                    {investments?.filter(
                      (item) =>
                        item.status === "Completed" || item.status === "Closed"
                    ).length > 0 && (
                      <Dropdown.Item
                        as={Link}
                        to={`/offering/${offering.id}/certificate`}
                      >
                        <i className="fe fe-download" /> Closing Certificate
                      </Dropdown.Item>
                    )}
                  </>
                )}
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown>
              <Dropdown.Toggle variant="secondary" id="invite-dropdown">
                Send Invites
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {moment(offering.termination_date) >= moment() && canEdit && (
                  <>
                    <Dropdown.Item onClick={() => showInviteModal(true)}>
                      <i className="fe fe-mail" /> Invite Investor
                    </Dropdown.Item>
                    <Dropdown.Item
                      as={Link}
                      to={`/offering/${offering.id}/bulk-invite`}
                      // className="btn btn-light ms-2"
                    >
                      <i className="fe fe-mail" /> Bulk Invite
                    </Dropdown.Item>
                  </>
                )}
                {canEdit && (
                  <Dropdown.Item onClick={() => handleRecon()}>
                    <i className="fe fe-alert-triangle" /> Send Recon
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}
      </AppHeader>

      <div className="container-fluid">
        <Spinner show={spinner}>
          {summary.progress > 0 && (
            <div id="progress" className="progress" style={{ height: 24 }}>
              <div
                className="progress-bar"
                role="progressbar"
                style={{ width: `${summary.progress}%` }}
              >
                {parseInt(summary.progress)}%
              </div>
            </div>
          )}

          <div id="summary">
            <div className="row g-3 mt-3">
              <InfoCard title="Maximum Offering">
                <NumberFormat
                  displayType="text"
                  thousandSeparator={true}
                  value={summary.total}
                  prefix="$"
                  decimalScale={0}
                />
              </InfoCard>
              <InfoCard title="Total Funds Received">
                <NumberFormat
                  displayType="text"
                  thousandSeparator={true}
                  value={summary.paid + summary.accepted + summary.closed}
                  prefix="$"
                  decimalScale={0}
                />
              </InfoCard>
              <InfoCard title="Funds Accepted">
                <NumberFormat
                  displayType="text"
                  thousandSeparator={true}
                  value={summary.accepted + summary.closed}
                  prefix="$"
                  decimalScale={0}
                />
              </InfoCard>
              <InfoCard title="Funds Available for Closing">
                <NumberFormat
                  displayType="text"
                  thousandSeparator={true}
                  value={summary.paid + summary.accepted}
                  prefix="$"
                  decimalScale={0}
                />
              </InfoCard>
            </div>
            <div className="row g-3">
              <InfoCard title="Current Closing Amount Pending">
                <NumberFormat
                  displayType="text"
                  thousandSeparator={true}
                  value={summary.paid}
                  prefix="$"
                  decimalScale={0}
                />
              </InfoCard>
              <InfoCard title="Funds Available for Closing">
                <NumberFormat
                  displayType="text"
                  thousandSeparator={true}
                  value={summary.accepted}
                  prefix="$"
                  decimalScale={0}
                />
              </InfoCard>
              <InfoCard title="Balance Available">
                <NumberFormat
                  displayType="text"
                  thousandSeparator={true}
                  value={summary.total - summary.closed - summary.accepted}
                  prefix="$"
                  decimalScale={0}
                />
              </InfoCard>
            </div>
          </div>

          <div className="card mt-3">
            <div className="card-header">
              <div id="status-filter">
                <button
                  className="btn btn-light btn-sm dropdown-toggle"
                  type="button"
                  id="statusFilter"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Status: {filter ? filter : "All"}
                </button>
                <div className="dropdown-menu" aria-labelledby="statusFilter">
                  {statusList.map((item, index) => {
                    return (
                      <button
                        key={index}
                        className="dropdown-item"
                        onClick={() => {
                          setFilter(item.name);
                        }}
                      >
                        {" "}
                        {item.name}
                      </button>
                    );
                  })}
                  <hr className="my-1" />
                  <button
                    className="dropdown-item"
                    onClick={() => setFilter("")}
                  >
                    Clear Filter
                  </button>
                </div>
              </div>
              {offering.status === "Active" && (
                <div id="actions">
                  {!bulkEdit &&
                    filter === "Payment Successful" &&
                    isAdmin(user) &&
                    investments?.filter((x) =>
                      filter ? x.status === filter : true
                    )?.length > 0 && (
                      <button
                        className="btn btn-light btn-sm ms-2"
                        onClick={() => setBulkEdit(true)}
                        {...(isAuthorizedSignatory && selectedItems.length
                          ? { disabled: false }
                          : { disabled: true })}
                      >
                        <i className="fe fe-edit-2" /> Bulk Edit
                      </button>
                    )}

                  {!bulkEdit &&
                    filter === "Payment Successful" &&
                    investments?.filter((x) =>
                      filter ? x.status === filter : true
                    )?.length > 0 && (
                      <button
                        className="btn btn-eq-primary btn-sm ms-2"
                        onClick={() => handleAcceptAll()}
                        {...(isAuthorizedSignatory && selectedItems.length
                          ? { disabled: false }
                          : { disabled: true })}
                      >
                        <i className="fe fe-check-circle"></i> Accept All
                      </button>
                    )}

                  {bulkEdit && filter === "Payment Successful" && canEdit && (
                    <button
                      className="btn btn-eq-primary btn-sm ms-2"
                      onClick={() => handleAcceptBulkEdit()}
                      {...(isAuthorizedSignatory
                        ? { disabled: false }
                        : { disabled: true })}
                    >
                      <i className="fe fe-check-circle"></i> Accept Changes
                    </button>
                  )}
                  {investments?.filter((x) =>
                    filter ? x.status === filter : true
                  )?.length > 0 &&
                    filter === "Accepted" && (
                      <Spinner show={closeBtnLoader}>
                        <button
                          button
                          className="btn btn-eq-primary btn-sm ms-2"
                          onClick={() => {
                            handleCloseAll();
                          }}
                          disabled={!completeStatus}
                        >
                          <i className="fe fe-check-circle"></i>
                          Close All
                        </button>
                      </Spinner>
                    )}
                  {user?.roles?.admin && filter === "Invite Sent" && (
                    <button
                      className="btn btn-eq-primary btn-sm ms-2"
                      onClick={() => deleteAllInvestor()}
                      disabled={selectedItems.length > 0 ? false : true}
                    >
                      <i className="fe fe-trash"></i> Delete All
                    </button>
                  )}
                </div>
              )}
            </div>

            <div className="table-responsive">
              <table className="table table-hover table-nowrap card-table">
                <thead>
                  {investments?.filter((x) =>
                    filter ? x.status === filter : true
                  )?.length ? (
                    <tr>
                      <th></th>
                      <th>Investor</th>
                      <th>Invite Date</th>
                      {!offering?.stock_type?.split(" ").includes("Safe") && (
                        <th className="text-center">Subscription</th>
                      )}

                      <th className="text-end">Investment Amount</th>
                      {bulkEdit ? <th>Edit Investment Amount</th> : <th></th>}
                      <th className="text-center">Status</th>
                      {user?.roles?.admin && (
                        <th className="text-center">Action</th>
                      )}
                    </tr>
                  ) : (
                    <tr className="noDataExist">
                      <th>No data exist</th>
                    </tr>
                  )}
                </thead>
                <tbody>
                  {investments
                    .filter((x) => x.status === filter || filter === "")
                    .map((item, index) => {
                      return (
                        <tr key={index}>
                          <td className="align-baseline">
                            {filter &&
                              [
                                "Payment Successful",
                                "Accepted",
                                `${isAdmin(user) && "Invite Sent"}`,
                              ].includes(item.status) && (
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={
                                    selectedItems.length > 0 &&
                                    selectedItems.includes(item.id)
                                      ? true
                                      : false
                                  }
                                  onChange={(e) =>
                                    handleCheckChange(e, item.id)
                                  }
                                />
                              )}
                          </td>
                          <td
                            className="align-baseline"
                            onClick={() => {
                              props.history.push(
                                `/offering/${id}/investment/${item.id}/overview`
                              );
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            {item.investor?.name}
                            <p className="text-muted mb-0">
                              {item.investor?.email}
                            </p>
                          </td>
                          <td className="align-baseline">
                            {moment(item.create_date).format("ll")}
                          </td>
                          {!offering?.stock_type
                            .split(" ")
                            .includes("Safe") && (
                            <td className="text-center align-baseline">
                              {item.approved_quantity &&
                              item?.approved_quantity !== item?.quantity ? (
                                <>
                                  <div>
                                    <NumberFormat
                                      value={item.approved_quantity}
                                      displayType="text"
                                      thousandSeparator={true}
                                      decimalScale={0}
                                    />
                                  </div>
                                  <del className="small text-muted">
                                    <NumberFormat
                                      value={item.quantity}
                                      displayType="text"
                                      thousandSeparator={true}
                                      decimalScale={0}
                                    />
                                  </del>
                                </>
                              ) : (
                                <NumberFormat
                                  value={item.quantity}
                                  displayType="text"
                                  thousandSeparator={true}
                                  decimalScale={0}
                                />
                              )}
                            </td>
                          )}

                          <td className="text-end align-baseline">
                            {item.approved_amount &&
                            item?.amount !== item?.approved_amount ? (
                              <>
                                <div>
                                  <NumberFormat
                                    value={item.approved_amount}
                                    displayType="text"
                                    thousandSeparator={true}
                                    decimalScale={0}
                                    prefix="$"
                                  />
                                </div>
                                <del className="small text-muted">
                                  <NumberFormat
                                    value={item.amount}
                                    displayType="text"
                                    thousandSeparator={true}
                                    decimalScale={0}
                                    prefix="$"
                                  />
                                </del>
                              </>
                            ) : (
                              <NumberFormat
                                value={item.amount}
                                displayType="text"
                                thousandSeparator={true}
                                decimalScale={0}
                                prefix="$"
                              />
                            )}
                          </td>
                          {bulkEdit && selectedItems.includes(item.id) ? (
                            <td className="align-baseline">
                              <NumberFormat
                                className="form-control"
                                allowNegative={false}
                                thousandSeparator={true}
                                prefix="$"
                                decimalScale={0}
                                value={item.approved_amount}
                                onValueChange={({ value }) =>
                                  handleBulkEdit(item.id, value)
                                }
                                isAllowed={({ floatValue }) => {
                                  if (!floatValue) {
                                    return true;
                                  }
                                  return floatValue <= item.amount;
                                }}
                              />
                            </td>
                          ) : (
                            <td></td>
                          )}
                          <td className="text-center align-baseline vertical-align-middle">
                            <StatusBadge status={item.status} />
                          </td>
                          {isAdmin(user) && (
                            <td className="text-center align-baseline">
                              {/* {item.status.toLowerCase() === "invite sent" ? ( */}
                              {
                                <div
                                  onClick={() =>
                                    deleteAllInvestor(item.id, index)
                                  }
                                  className="cursor-pointer"
                                >
                                  <i className="fe fe-trash" />
                                </div>
                              }
                            </td>
                          )}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </Spinner>
      </div>

      <Modal centered show={reconModal} onHide={() => showReconModal(false)}>
        <Modal.Header>
          <Modal.Title className="mb-0">
            <h2 className="mb-0">Send Reconfirmation to Investor</h2>
          </Modal.Title>
        </Modal.Header>

        <Formik
          initialValues={reconInitialValues}
          validationSchema={reconSchema}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({
            handleChange,
            handleSubmit,
            values,
            errors,
            touched,
            handleBlur,
            setFieldValue,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Modal.Body>
                <div className="row">
                  <div className="col col-12">
                    <div className="form-group mb-3">
                      <label>
                        Reason <span className="text-danger">*</span>
                      </label>
                      <textarea
                        className="form-control"
                        as="textarea"
                        rows="3"
                        name="reason"
                        placeholder="Please provide a brief reason"
                        onChange={handleChange}
                        value={values.reason}
                        maxLength={300}
                        onBlur={handleBlur}
                      />
                      {errors.reason && touched.reason && (
                        <div className="text-danger mt-2 ms-1 h5">
                          {errors.reason}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group mb-3">
                      <label className="mb-2">
                        Total amount <span className="text-danger">*</span>
                      </label>
                      <NumberFormat
                        placeholder="$100,000"
                        className="form-control"
                        value={values.recon_amount}
                        allowNegative={false}
                        thousandSeparator={true}
                        prefix={"$"}
                        onValueChange={({ value }) => {
                          setFieldValue("recon_amount", value);
                        }}
                      />
                      {errors.recon_amount && touched.recon_amount && (
                        <div className="text-danger mt-2 ms-1 h5">
                          {errors.recon_amount}
                        </div>
                      )}
                    </div>
                  </div>
                  {offering.stock_type === "Common Stock" && (
                    <div className="col">
                      <div className="mb-3 form-group">
                        <label className="mb-2">
                          Price per share <span className="text-danger">*</span>
                        </label>
                        <NumberFormat
                          placeholder="$10.00"
                          className="form-control"
                          value={values.recon_unit_price}
                          allowNegative={false}
                          thousandSeparator={true}
                          prefix={"$"}
                          onValueChange={({ value }) => {
                            setFieldValue("recon_unit_price", value);
                          }}
                        />
                        {errors.recon_unit_price &&
                          touched.recon_unit_price && (
                            <div className="text-danger mt-2 ms-1 h5">
                              {errors.recon_unit_price}
                            </div>
                          )}
                      </div>
                    </div>
                  )}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="btn btn-link"
                  type="button"
                  onClick={() => showReconModal(false)}
                >
                  Cancel
                </button>
                <button className="btn btn-eq-primary" type="submit">
                  Send
                </button>
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </Modal>
      <Modal
        show={inviteModal}
        onHide={() => {
          showInviteModal(false);
        }}
        centered
      >
        <Formik
          initialValues={inviteInitialValues}
          validationSchema={inviteSchema}
          onSubmit={sendInvite}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({
            handleChange,
            handleSubmit,
            values,
            errors,
            touched,
            handleBlur,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Modal.Body>
                <h2>Invite Investor</h2>
                <div className="form-group">
                  <input
                    className="form-control"
                    type="text"
                    name="email"
                    placeholder="john@gmail.com"
                    value={values.email || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.email && touched.email && (
                    <div className="text-danger mt-2 ms-1 h5">
                      {errors.email}
                    </div>
                  )}
                </div>
                <Spinner show={spinner}>
                  <button className="btn btn-eq-primary" type="submit">
                    Send Invite
                  </button>
                  <button
                    className="btn btn-link"
                    type="button"
                    onClick={() => showInviteModal(false)}
                  >
                    Cancel
                  </button>
                </Spinner>
              </Modal.Body>
            </form>
          )}
        </Formik>
      </Modal>
      <Modal
        show={stopModal}
        onHide={() => {
          showStopModal(false);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="md"
        dialogClassName="stopModal"
      >
        <Modal.Body className="">
          <div className="d-flex align-items-center justify-content-center flex-column">
            <img alt="no-data" src="/img/stop.svg" className="w-25 mb-4" />
          </div>
          <div className="d-flex justify-content-center">
            <ul>
              <li className="list-unstyled ms-n5 mb-3">
                Invite investor is disabled for one or more reasons listed
                below:
              </li>
              <li>Offering Status is not 'Active' or 'Recon Active'</li>
              {/* <li>Launch date is in future.</li> */}
              <li>Offering is terminated</li>
            </ul>
          </div>
          <div className="d-flex align-items-center justify-content-center flex-column">
            {" "}
            <button
              className="btn btn-eq-primary btn-lg"
              onClick={() => showStopModal(false)}
              type="button"
            >
              Okay
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={addInvestorModal}
        onHide={() => {
          showAddInvestorModal(false);
        }}
        centered
      >
        <Formik
          enableReinitialize={true}
          initialValues={createInitialValues}
          validationSchema={createSchema}
          onSubmit={(values) => onCleanForm(values, sendInviteToNewInvestor)}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({
            handleChange,
            handleSubmit,
            setFieldValue,
            values,
            errors,
            touched,
            handleBlur,
          }) => {
            return (
              <form noValidate onSubmit={handleSubmit}>
                <Modal.Body>
                  <h2>Invite Investor</h2>
                  <div className="row">
                    <div className="col-4">
                      <label className="mb-2">
                        Legal First Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="first_name"
                        className="form-control"
                        placeholder="eg: John"
                        value={values.first_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.first_name && touched.first_name && (
                        <div className="text-danger mt-2 ms-1 h5">
                          {errors.first_name}
                        </div>
                      )}
                    </div>
                    <div className="col-4">
                      <label className="mb-2">Legal Middle Name</label>
                      <input
                        type="text"
                        name="middle_name"
                        className="form-control"
                        placeholder="eg: Alan"
                        value={values.middle_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.middle_name && touched.middle_name && (
                        <div className="text-danger mt-2 ms-1 h5">
                          {errors.middle_name}
                        </div>
                      )}
                    </div>
                    <div className="col-4">
                      <label className="mb-2">
                        Legal Last Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="last_name"
                        className="form-control"
                        placeholder="eg: Smith"
                        value={values.last_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.last_name && touched.last_name && (
                        <div className="text-danger mt-2 ms-1 h5">
                          {errors.last_name}
                        </div>
                      )}
                    </div>
                  </div>

                  <br />
                  <AccountType
                    label="Is this investor investing as an individual or an entity? (Corp, LLC, etc...)"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    values={values}
                    errors={errors}
                  />

                  <Company
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    inputClassName=""
                    values={values}
                    errors={errors}
                  />

                  <div className="form-group">
                    <label className="mb-2">Title</label>
                    <input
                      type="text"
                      name="title"
                      className="form-control"
                      placeholder="eg: CEO"
                      value={values.title}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <label className="mb-2">Email</label>
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          placeholder="name@address.com"
                          value={email}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label className="mb-2">Phone</label>
                        <NumberFormat
                          placeholder="eg: +1 (444) 444-4444"
                          className="form-control"
                          format="+1 (###) ###-####"
                          mask="_"
                          value={values.phone}
                          onValueChange={({ value }) => {
                            setFieldValue("phone", value);
                          }}
                        />
                        {errors.phone && touched.phone && (
                          <div className="text-danger mt-2 ms-1 h5">
                            {errors.phone}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <Spinner show={spinner}>
                    <button className="btn btn-eq-primary" type="submit">
                      Continue
                    </button>
                    <button
                      className="btn btn-link me-2"
                      type="button"
                      onClick={() => {
                        showAddInvestorModal(false);
                      }}
                    >
                      Cancel
                    </button>
                  </Spinner>
                </Modal.Body>
              </form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    offering: state.offering,
    user: state.user,
  };
};

export default connect(mapStateToProps)(Investors);

/* eslint-disable react-hooks/exhaustive-deps */
import { Formik } from "formik";
import React, { useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import FormControl from "../../components/formControl";
import Spinner from "../../components/spinner";
import Header from "../../layouts/header";

import {
  useBroker,
  useBrokerCreate,
  useBrokerUpdate,
} from "../../hooks/useBrokers";

import { brokerFormSchema } from "../../constants/forms";

const DEFAULT_DATA = {
  first_name: null,
  last_name: null,
  company: null,
  crd_number: null,
  active: true,
};

const BrokerEditor = (props) => {
  const history = useHistory();
  const { id } = useParams();
  const [spinner, showSpinner] = useState(!!id);

  const { data: broker } = useBroker(id, {
    onSettled: () => {
      showSpinner(false);
    },
    refetchOnMount: true
  });

  const brokerCreate = useBrokerCreate({
    onSuccess: (data) => {
      toast.success(
        `Broker ${data.first_name} ${data.last_name} has been created`
      );
      history.push(`/brokers`);
    },
    onSettled: () => { showSpinner(false); },
    onError: (error) => {
      console.error(error);
      error?.response.data.errors.forEach((e) => {
        toast.error(e);
      })
    },
  });

  const brokerUpdate = useBrokerUpdate(id, {
    onSuccess: (data) => {
      toast.success(
        `Broker ${data.first_name} ${data.last_name} has been updated`
      );
      history.push(`/brokers`);
    },
    onSettled: () => { showSpinner(false); },
    onError: (error) => {
      console.error(error);
      error?.response.data.errors.forEach((e) => {
        toast.error(e);
      })
    },
  });

  const handleOnSubmit = async (data) => {
    showSpinner(true);

    if (id) {
      await brokerUpdate.mutateAsync({ data });
    } else {
      await brokerCreate.mutateAsync({ data });
    }

    showSpinner(false);
  };

  return (
    <>
      <Header title="EquiDeFi" name={id ? "Edit Broker" : "Create Broker"} />
      <div className="container-fluid mb-5">
        <Formik
          enableReinitialize={true}
          initialValues={broker ?? DEFAULT_DATA}
          validationSchema={brokerFormSchema}
          onSubmit={handleOnSubmit}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ handleChange, handleSubmit, values, errors }) => {
            return (
              <form noValidate onSubmit={handleSubmit}>
                <FormControl
                  type="text"
                  name="first_name"
                  title="First Name"
                  placeholder="e.g. John"
                  value={values.first_name}
                  error={errors.first_name}
                  required
                  onChange={handleChange}
                />

                <FormControl
                  type="text"
                  name="last_name"
                  title="Last Name"
                  placeholder="e.g. Smith"
                  value={values.last_name}
                  error={errors.last_name}
                  required
                  onChange={handleChange}
                />

                <FormControl
                  type="text"
                  name="company"
                  title="Company Name"
                  placeholder="e.g. Acme Corporation"
                  value={values.company}
                  error={errors.company}
                  onChange={handleChange}
                />

                <FormControl
                  type="text"
                  name="crd_number"
                  title="CRD Number"
                  placeholder="e.g. 5432541"
                  value={values.crd_number}
                  error={errors.crd_number}
                  maxLength={7}
                  required
                  onChange={handleChange}
                  disabled={!!id}
                />

                <FormControl
                  type="checkbox"
                  name="active"
                  title="Active"
                  value={values.active}
                  error={errors.active}
                  default={true}
                  required
                  onChange={handleChange}
                />

                <hr />

                <Spinner show={spinner}>
                  <div className="d-flex flex-row justify-content-start">
                    <button className="btn btn-eq-primary me-2" type="submit">
                      {id ? "Update" : "Create"}
                    </button>
                    <Link to={"/brokers"} className="btn btn-outline-secondary">
                      Cancel
                    </Link>
                  </div>
                </Spinner>
              </form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default BrokerEditor;

/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import moment from "moment";
import swal from "sweetalert";

import Spinner from "../../components/spinner";
import
  {
    createOfferingAgreement,
    deleteOfferingAgreement,
    isOfferingViewer,
    updateOfferingAgreement,
    upload,
  } from "../../helpers";
import { useOffering } from "../../hooks/useOfferings";
import Header from "../../layouts/header";

const Agreements = (props) => {
  const { id } = useParams();
  const { data: offering, refetch: refetchOffering } = useOffering(id);

  const user = useSelector((state) => state.user);

  const [modal, showModal] = useState(false);
  const [spinner, showSpinner] = useState(false);
  const [fileSpinner, showFileSpinner] = useState(false);
  const [agreement, setAgreement] = useState({});
  const [files, setFiles] = useState([]);

  const handleDelete = (agreement) => {
    swal({
      title: `Remove Agreement`,
      text: `Are you sure you want to remove '${agreement.name}'?`,
      buttons: ["Cancel", "Yes"],
      icon: "error",
      dangerMode: true,
    }).then((status) => {
      if (status) {
        showSpinner(true);
        deleteOfferingAgreement(offering.id, agreement.id).then(
          async (response) => {
            toast.success(`${agreement.name} has been removed`);
            await refetchOffering();
            showSpinner(false);
          }
        );
      }
    });
  };

  const handleUpdate = async () => {
    if (!agreement.name) return toast.error(`Name is required`);
    if (!agreement.template_id) return toast.error(`Tempalte ID is required`);
    if (!agreement.document_url)
      return toast.error(`At least one document is required`);
    showSpinner(true);
    updateOfferingAgreement(offering.id, agreement.id, agreement).then(
      async (_) => {
        toast.success(`${agreement.name} has been updated`);
      }
    );
    showModal(false);
    await refetchOffering();
    showSpinner(false);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: { "application/msword": [".doc", ".docx"] },
    onDrop: (acceptedFiles) => {
      if (!agreement.name) return toast.error(`Name is required for upload`);
      showFileSpinner(true);
      setFiles(acceptedFiles);
      acceptedFiles.forEach((file) => {
        let data = new FormData();
        data.append("file", file);

        upload(data).then((response) => {
          let document = {
            name: agreement.name,
            template_id: agreement.template_id,
            agreement_url: response.url,
          };
          createOfferingAgreement(offering.id, document).then((data) => {
            setAgreement((prev) => ({
              ...prev,
              document_url: response.url,
            }));
          });
          showFileSpinner(false);
        });
      });
    },
  });

  const canEdit = useMemo(
    () =>
      !isOfferingViewer(user, offering.legacy_id) &&
      ["In Review", "Draft"].includes(offering?.status),
    [user, offering?.legacy_id, offering?.status]
  );

  return (
    <>
      <Header
        id={id}
        name="Execution Documents"
        iconName="fe fe-info ms-2"
        title={offering?.name}
        tooltipContent="Please upload Word versions of only the agreements your investors will be executing. We will label these documents in DocuSign for signature by the appropriate parties"
      >
        {canEdit && (
          <button
            className="btn btn-eq-primary"
            onClick={() => {
              setAgreement({});
              setFiles([]);
              showModal(true);
            }}
          >
            Upload Agreements
          </button>
        )}
      </Header>

      <Spinner show={spinner}>
        <div className="container-fluid">
          <div className="card">
            <table className="table table-hover table-nowrap card-table">
              <thead>
                <tr>
                  <th>Agreement Name</th>
                  <th>Date Uploaded</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {offering?.agreements &&
                  offering.agreements.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td className="text-start align-baseline">
                          {item.name}{" "}
                          {item.template_id && (
                            <i
                              className="fe fe-file-text text-muted ms-3"
                              data-toggle="tooltip"
                              data-placement="top"
                              title={`${item.template_id}`}
                            ></i>
                          )}
                        </td>
                        <td className="align-baseline">
                          {item.create_date &&
                            moment(item.create_date).format("ll")}
                        </td>
                        <td className="text-end align-baseline">
                          <a
                            className="btn btn-sm btn-light"
                            href={item.agreement_url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="fe fe-download" />
                          </a>
                          {canEdit && (
                            <button
                              className="btn btn-sm btn-light ms-1"
                              onClick={() => {
                                setAgreement(item);
                                setFiles(() => {
                                  const url = new URL(item.agreement_url);
                                  return [decodeURIComponent(url.pathname)];
                                });
                                showModal(true);
                              }}
                            >
                              <i className="fe fe-edit" />
                            </button>
                          )}
                          {canEdit && (
                            <button
                              className="btn btn-sm btn-light ms-1"
                              onClick={() => handleDelete(item)}
                            >
                              <i className="fe fe-trash" />
                            </button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>

          <Modal show={modal} onHide={() => showModal(false)}>
            <Modal.Body>
              <h2 className="mb-0">Upload Agreement</h2>
              <hr />
              <div className="form-group">
                <label className="mb-2">
                  Name<span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder="eg: Recon Document"
                  value={agreement.name || ""}
                  onChange={(event) =>
                    setAgreement({ ...agreement, name: event.target.value })
                  }
                />
              </div>
              <div className="form-group">
                <label className="mb-2">
                  Template Id
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="template_id"
                  className="form-control"
                  value={agreement.template_id}
                  onChange={(event) =>
                    setAgreement({
                      ...agreement,
                      template_id: event.target.value,
                    })
                  }
                />
              </div>
              <Spinner show={fileSpinner}>
                <div {...getRootProps({ className: "dropzone" })}>
                  <input {...getInputProps()} />
                  <p>
                    {files.length
                      ? files.map((file) => <div>{file?.path || file} </div>)
                      : "Drag 'n' drop the respective files here, or click to select files"}
                  </p>
                </div>
              </Spinner>
              <hr />
              <div className="mt-3">
                <button
                  className="btn btn-eq-primary"
                  onClick={() => handleUpdate()}
                >
                  Continue
                </button>
                <button
                  className="btn btn-link ms-2"
                  type="button"
                  onClick={() => showModal(false)}
                >
                  Cancel
                </button>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </Spinner>
    </>
  );
};

export default Agreements;

import axios from "axios";

const find = async (value) => {
  const response = await axios.get(`/sec/cik/${value}`);
  return response.data;
};

const search = async (value) => {
  const response = await axios.get(`/sec/search`, { params: { s: value } });
  return response.data;
};

const SecClient = {
  find,
  search,
};

export default SecClient;

import { format as dateFnsFormat, parseISO as dateFnsParseISO } from "date-fns";
import moment from "moment";

export const MomentDateTimeAdapter = class {
  parseISO(iso) {
    return moment(iso);
  }

  formatMonthDayYear(date) {
    return moment(date).format("MMMM DD, YYYY");
  }

  formatReadableTimestamp(date) {
    // TODO: Confirm this date-fns Unicode format is a match against moment()
    return moment(date).format("MMM d, YYYY h:mm aa");
  }

  setGlobalLocale(tz) {
    return moment.locale(tz);
  }
};

export const DateFnsAdapter = class {
  parseISO(iso) {
    if (!iso) return null;
    return dateFnsParseISO(iso);
  }

  formatMonthDayYear(date) {
    if (!date) return null;
    return dateFnsFormat(this.parseISO(date), "MMMM dd, yyyy");
  }

  formatReadableTimestamp(date) {
    if (!date) return null;
    return dateFnsFormat(this.parseISO(date), "MMM d, yyyy h:mm aa");
  }

  timezone(date, tz) {
    // TODO: Implement
  }

  setGlobalLocale(tz) {
    // TODO: Implement
  }
};

export const DateTimeService = class {
  adapter;

  constructor(adapter) {
    this.adapter = adapter;
  }

  parseISO(iso) {
    return this.adapter.parseISO(iso);
  }

  formatMonthDayYear(date) {
    return this.adapter.formatMonthDayYear(date);
  }

  formatReadableTimestamp(date) {
    return this.adapter.formatReadableTimestamp(date);
  }

  timezone(date, tz) {
    return this.adapter.timezone(date, tz);
  }

  setGlobalLocale(tz) {
    return this.adapter.setGlobalLocale(tz);
  }
};

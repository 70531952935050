import axios from "axios";

import { CONFIG } from "../constants/config";
import UserToken from "./token";

const API = () => {
  const token = UserToken.token();

  return axios.create({
    baseURL: CONFIG.EQ_API_URL,
    headers: {
      ...(token
        ? {
            Authorization: `Bearer ${token}`,
          }
        : {}),
    },
  });
};

export default API;

import { useMutation, useQuery } from "@tanstack/react-query";
import InquiryClient from "../clients/InquiryClient";
import InvestmentClient from "../clients/InvestmentClient";

/**
 * @param {string} id
 * @param {import("@tanstack/react-query").QueryObserverOptions} options
 */
export const useInquiry = (id, options = {}) =>
  useQuery(["persona-inquiry", id], () => InquiryClient.get(id), options ?? {});

export const useInvestmentInquiryUpdate = (id, options = {}) =>
  useMutation(
    ({ data }) => InvestmentClient.updateInquiryStatus(id, data),
    options ?? {}
  );

/**
 * @param {string} id
 * @param {string} stage
 * @param {import("@tanstack/react-query").UseMutationOptions} options
 */
export const useInvestmentUpdateStage = (id, stage, options = {}) =>
  useMutation(
    ({ data }) => InvestmentClient.updateStage(id, stage, data),
    options ?? {}
  );

import Spinner from "../spinner";
import moment from "moment/moment";
import {CurrencyFormatter} from "../../constants/format";
import {PDFDownloadLink} from "@react-pdf/renderer";
import {Invoice} from "../../pages/invoices/Invoice";

const Invoices = ({invoices, isLoading}) => {

  if (!invoices || isLoading) return <Spinner show={true} />

  return (
    <div className="card">
      <div className="table-responsive">
        <table className="table table-nowrap table-hover mb-0">
          <thead>
            <tr>
              <th>Issuer</th>
              <th>Date</th>
              <th>Amount</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {invoices.map((invoice, index) => {
              return (
                <tr key={index} style={{ cursor: "pointer" }}>
                  <td className="align-baseline">{invoice.name}</td>
                  <td className="align-baseline">{moment(invoice.create_date).format("ll")}</td>
                  <td className=" align-baseline">{CurrencyFormatter.format(invoice.total_amount)}</td>
                  <td className=" align-baseline">{invoice.status}</td>
                  <td>
                    <PDFDownloadLink document={<Invoice invoice={invoice} />} fileName={`invoice-${invoice.id}.pdf`}>
                      Download
                    </PDFDownloadLink>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Invoices;

import * as FullStory from "@fullstory/browser";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

import App from "./App";
import { sentryStart } from "./helpers/sentry";
import Store from "./store";

// css
import "./style/app.css";
import "./style/custom.css";
import "./style/icons.css";

sentryStart();

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  },
});


if (process.env.NODE_ENV === "production") {
  FullStory.init({ orgId: "o-1FJ1ZH-na1" });
}

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Provider store={Store}>
    <QueryClientProvider client={queryClient}>
      <App />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </Provider>
);

import React from "react";
import { Form } from "react-bootstrap";

const RadioListItem = ({
  name,
  value,
  children,
  onChange,
  disabled = false,
  formik = undefined,
}) => {
  return (
    <label
      htmlFor={`${name}-${value}`}
      className="d-inline-flex gap-3 align-items-start"
    >
      <Form.Check
        className="mt-2 flex-shrink-0"
        type="radio"
        id={`${name}-${value}`}
        name={name}
        value={value}
        checked={formik?.values?.[name] === value}
        disabled={disabled}
        aria-disabled={disabled}
        onChange={onChange}
      />
      <span>{children}</span>
    </label>
  );
};

export default RadioListItem;

import API from "./api";
class OfferingClient {
  static async legacyCreate(data) {
    const response = await API().post(`/offering`, data);
    return response.data;
  }

  static async update(id, data) {
    const response = await API().patch(`/v2/offerings/${id}`, data);
    return response.data;
  }
}

export default OfferingClient;

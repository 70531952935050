import { useFormik } from "formik";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";

import DisqualifyingEventQuestionnaire from "../../../../investor/src/components/offerings/DisqualifyingEventQuestionnaire";
import EntityAccreditationCertification from "../../../../investor/src/components/offerings/EntityAccreditationCertification";
import IndividualAccreditationCertification from "../../../../investor/src/components/offerings/IndividualAccreditationCertification";
import { MESSAGE } from "../../constants/forms";
import { useInvestmentUpdateStage } from "../../hooks/investments";
import Spinner from "../spinner";

const AccreditationReviewModal = ({
  investment,
  show = false,
  onHide = () => {},
  onApprove = () => {},
  onReject = () => {},
}) => {
  const formik = useFormik({
    initialValues: {
      comment: undefined,
    },
    validationSchema: Yup.object({
      comment: Yup.string().required(MESSAGE.REQUIRED("description")),
    }),
    validateOnBlur: true,
  });

  const updateStage = useInvestmentUpdateStage(
    investment.id,
    "approve-accreditation",
    {
      onError: () => {
        toast.error("Something went wrong.");
      },
    }
  );

  const handleApprove = async () => {
    await updateStage.mutateAsync({
      data: {
        comment: formik.values.comment,
        approve: true,
      },
    });

    toast.success("Successfully approved the investor's accreditation");

    onApprove();
  };

  const handleReject = async () => {
    await updateStage.mutateAsync({
      data: {
        comment: formik.values.comment,
        approve: false,
      },
    });

    toast.success("Successfully rejected the investor's accreditation");

    onReject();
  };

  const isDisabled = formik.dirty
    ? !formik.isValid || updateStage.isLoading
    : true;

  return (
    <Modal centered size="xl" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <div className="d-flex justify-content-between align-items-center w-100">
          <h3>Review Investor Accreditation</h3>
        </div>
      </Modal.Header>
      <Modal.Body className="mt-0 pt-0">
        <div>
          <EntityAccreditationCertification
            user={investment.investor}
            disabled
            showButton={false}
            stage="accreditation"
          />
          <IndividualAccreditationCertification
            user={investment.investor}
            disabled
            showButton={false}
            stage="accreditation"
          />
          <DisqualifyingEventQuestionnaire
            user={investment.investor}
            stage="disqualifying"
            disabled
            showButton={false}
            values={{ bad_actor: `${investment?.bad_actor}` }}
            issuerName={investment?.issuer?.name}
          />
        </div>

        <Spinner show={false}>
          <div className="form-group">
            <label className="mb-2">Description / Reason</label>
            <textarea
              className="form-control"
              as="textarea"
              rows="5"
              {...formik.getFieldProps("comment")}
            />
            {formik.errors.comment && formik.touched.comment && (
              <div className="text-danger mt-2 ms-1 h5">
                {formik.errors.comment}
              </div>
            )}
          </div>
          <div className="d-flex align-items-center gap-2">
            <button
              className="btn btn-eq-primary"
              disabled={isDisabled}
              aria-disabled={isDisabled}
              onClick={handleApprove}
            >
              Approve
            </button>
            <button
              className="btn btn-light"
              disabled={isDisabled}
              aria-disabled={isDisabled}
              onClick={handleReject}
            >
              Reject
            </button>
          </div>
        </Spinner>
      </Modal.Body>
    </Modal>
  );
};

export default AccreditationReviewModal;

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import NumberFormat from 'react-number-format';

import * as Yup from 'yup';
import { Formik } from 'formik';


import Spinner from './../../components/spinner';

import axios from 'axios';
import Header from '../../layouts/header';

const MyProfile = (props) => {
  const dispatch = useDispatch();

  const user = useSelector((store) => store.user);
  const [spinner, setSpinner] = useState(false);

  const profileSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    company: Yup.string().required('Required'),
    title: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email').required('Required.'),
    phone: Yup.string().matches(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, 'Invalid phone number')
  });

  const handleSubmit = async (form) => {
    setSpinner(true);

    const request = {
      email: form.email.toLowerCase().trim(),
      name: form.name,
      phone: form.phone,
      company: form.company,
      title: form.title,
      active: true
    };

    axios.post(`user/${user.id}`, request).then(response => {
      axios.get(`user/${user.id}`).then(response => {
        toast.success(`Your profile has been updated successfully`);
        let data ={
          ...user,
          email: response.data.email,
          name: response.data.name,
          phone: response.data.phone,
          company: response.data.company,
          title: response.data.title,
          active: true
        }
        dispatch({ type: 'user', payload: data });
        setSpinner(false);
      });
    });
  };

  return (
    <>
      <Header name='My Profile'>

      </Header>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12 col-md-7 col-lg-6'>
            <div className='card'>
              <div className='card-body'>
                <Formik initialValues={user} validationSchema={profileSchema} enableReinitialize onSubmit={handleSubmit} validateOnChange={false} validateOnBlur={false}>
                  {({ handleChange, handleSubmit, values, errors, touched, handleBlur, setFieldValue }) => (
                    <form noValidate onSubmit={handleSubmit}>
                      <div className='form-group'>
                        <label className='form-label'>
                          Full Name<span className='text-danger'>*</span>
                        </label>
                        <input className='form-control' type='text' name='name' placeholder='e.g. John Doe' value={values.name} onChange={handleChange} />
                        {errors.name && touched.name && <div className='text-danger mt-2 ms-1 h5'>{errors.name}</div>}
                      </div>
                      <div className='form-group'>
                        <label className='form-label'>Title<span className='text-danger'>*</span></label>
                        <input className='form-control' type='text' name='title' placeholder='e.g. Investor' value={values.title} onChange={handleChange} />
                        {errors.title && touched.title && <div className='text-danger mt-2 ms-1 h5'>{errors.title}</div>}
                      </div>
                      <div className='form-group'>
                        <label className='form-label'>Company<span className='text-danger'>*</span></label>
                        <input className='form-control' type='text' name='company' placeholder='e.g. Equidefi' value={values.company} onChange={handleChange} />
                        {errors.company && touched.company && <div className='text-danger mt-2 ms-1 h5'>{errors.company}</div>}
                      </div>
                      <div className='form-group'>
                        <label className='form-label'>
                          Email Address<span className='text-danger'>*</span>
                        </label>
                        <input className='form-control bg-light' type='text' name='email' placeholder='e.g. address@example.com' value={values.email} onChange={handleChange} disabled />
                        {errors.email && touched.email && <div className='text-danger mt-2 ms-1 h5'>{errors.email}</div>}
                      </div>
                      <div className='form-group'>
                        <label className='form-label'>Phone</label>
                        <NumberFormat
                          placeholder='eg: +1 (444) 444-4444'
                          className='form-control'
                          format='+1 (###) ###-####'
                          mask='_'
                          value={values.phone}
                          onValueChange={({ value }) => {
                            setFieldValue('phone', value);
                          }}
                        />
                        {errors.phone && touched.phone && <div className='text-danger mt-2 ms-1 h5'>{errors.phone}</div>}
                      </div>
                      <Spinner show={spinner}>
                        <button type='submit' form='profile-form' className='btn btn-eq-primary ms-2' onClick={handleSubmit}>
                          Update Profile
                        </button>
                      </Spinner>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
          <div className='col-12 col-md-5 col-lg-6 d-none d-lg-flex'>
            <img src='/img/profile.svg' className='img-fluid mb-6 mb-md-0 aos-init aos-animate' alt='...' data-aos='fade-up' data-aos-delay='100' />
          </div>
        </div>
      </div>
    </>
  );
};

export default MyProfile;
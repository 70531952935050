import { useQuery } from "@tanstack/react-query";

import { getIssuerById, getIssuerList } from "../helpers";

/**
 * @param {string} string
 * @param {import("@tanstack/react-query").QueryObserverOptions} options
 */
export const useIssuers = (search, options = {}) => {
  return useQuery(
    ["issuers", { search }],
    () => getIssuerList(search),
    options
  );
};

/**
 * @param {string} id
 * @param {import("@tanstack/react-query").QueryObserverOptions} options
 */
export const useIssuer = (id, options = {}) => {
  return useQuery(["issuers", id], () => getIssuerById(id), options);
};

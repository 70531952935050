/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import Swal from "sweetalert";
import StatusBadge from "../../components/offeringStatus";
import Spinner from "../../components/spinner";
import Header from "../../layouts/header";

import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";

import FloatingSearch from "../../components/floating-search";
import { isAdmin, stockTypeOfferingPage } from "../../helpers";
import { useOfferings } from "../../hooks/useOfferings";
import useDebounce from "../../hooks/useDebounce";

const OfferingList = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [inputSearchValue, setSearchVal] = useState("");
  const searchVal = useDebounce(inputSearchValue, 500);

  const {
    data: offerings,
    refetch,
    isLoading: spinner,
  } = useOfferings(searchVal);

  useEffect(() => {
    dispatch({ type: "reset" });
  }, []);

  const user = useSelector((store) => store.user);

  const goToOffering = async (offering) => {
    dispatch({ type: "offering", payload: offering });
    history.push(`/offering/${offering.id}/overview`);
  };

  const deleteOffering = (id, index) => {
    let temp = [...offerings];
    //API
    Swal({
      title: `Delete`,
      text: `Are you sure you want to delete it?`,
      buttons: ["Cancel", "Delete"],
      icon: "warning",
      dangerMode: true,
    }).then((status) => {
      if (status) {
        axios
          .delete(`/offering/${id}`)
          .then((response) => {
            temp.splice(index, 1);
            toast.success("Offering has been deleted");
            refetch();
          })
          .catch((err) => {
            toast.error("Something went wrong, please try again later.");
          });
      }
    });
  };

  return (
    <>
      <Header name="Offerings">
        {isAdmin(user) && (
          <Link to="/offering/create" className="btn btn-eq-primary">
            New Offering
          </Link>
        )}
      </Header>
      <div className="container-fluid">
        <FloatingSearch
          placeholder="Search by name, description, type..."
          id="search-offerings"
          label="Search Offerings"
          onChange={(e) => setSearchVal(e.target.value)}
        />

        <div className="card">
          <Spinner show={spinner}>
            <div className="table-responsive">
              <table className="table table-nowrap table-hover mb-0">
                <thead>
                  <tr>
                    <th className="">Offering</th>
                    <th className="">Type</th>
                    <th className="">Launch Date</th>
                    <th className="">Amount</th>
                    <th className="">Status</th>
                    {user?.roles?.admin && <th className="">Action</th>}
                  </tr>
                </thead>
                <tbody>
                  {offerings?.map((offering, index) => {
                    return (
                      <tr key={index} style={{ cursor: "pointer" }}>
                        <td
                          className="align-baseline  "
                          onClick={() => goToOffering(offering)}
                          title={
                            offering?.name?.length > 30
                              ? offering?.name
                              : undefined
                          }
                        >
                          {offering?.name?.length > 30
                            ? `${offering?.name.substring(0, 30)}...`
                            : offering?.name}
                          <div className="small text-muted">
                            {offering.issuer?.name}
                          </div>
                        </td>
                        <td
                          className=" align-baseline"
                          onClick={() => goToOffering(offering)}
                        >
                          {stockTypeOfferingPage(offering?.stock_type)}
                        </td>
                        <td
                          className=" align-baseline"
                          onClick={() => goToOffering(offering)}
                        >
                          {offering.launch_date &&
                            moment(offering.launch_date).format("ll")}
                        </td>
                        <td
                          className=" align-baseline"
                          onClick={() => goToOffering(offering)}
                        >
                          <NumberFormat
                            value={offering.total_amount}
                            displayType="text"
                            thousandSeparator={true}
                            decimalScale={0}
                            prefix="$"
                          />
                        </td>
                        <td
                          className=" align-baseline"
                          onClick={() => goToOffering(offering)}
                        >
                          <StatusBadge status={offering.status} />
                        </td>
                        {user?.roles?.admin && (
                          <td className=" align-baseline">
                            {offering.status === "Draft" ||
                            offering.status === "In Review" ? (
                              <div
                                onClick={() =>
                                  deleteOffering(offering.id, index)
                                }
                              >
                                <i className="fe fe-trash" />
                              </div>
                            ) : (
                              ""
                            )}
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Spinner>
        </div>
      </div>
    </>
  );
};

export default OfferingList;

import { Formik } from "formik";
import React from "react";
import { Modal } from "react-bootstrap";
import NumberFormat from "react-number-format";
import { toast } from "react-toastify";
import * as Yup from "yup";

import { MESSAGE } from "../../../constants/forms";
import { useInvestmentAddManualPayment } from "../../../hooks/investments";

const InitialPaymentValues = {
  reference_number: "",
  description: "",
};

const PaymentSchema = Yup.object().shape({
  amount: Yup.string().required(MESSAGE.REQUIRED("amount")),
  reference_number: Yup.string().required(MESSAGE.REQUIRED("reference number")),
});

const ManualPaymentModal = ({
  investmentId,
  isVisible = false,
  onHide = () => {},
  onSubmit = () => {},
}) => {
  const addPayment = useInvestmentAddManualPayment(investmentId, {
    onSuccess: () => {
      onHide();
      onSubmit();
      toast.success("Manual payment has been added");
    },
    onError: () => {
      toast.error("Something went wrong. Could not add manual payment.");
    },
  });

  const handleManualPayment = async (data) => addPayment.mutateAsync({ data });

  return (
    <Modal centered show={isVisible} onHide={onHide}>
      <Modal.Header>Add Manual Payment</Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={InitialPaymentValues}
          validationSchema={PaymentSchema}
          onSubmit={handleManualPayment}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ handleChange, handleSubmit, values, errors }) => (
            <form noValidate onSubmit={handleSubmit}>
              <div className="form-group">
                <label className="mb-2">
                  Payment Recieved <span className="text-danger">*</span>
                </label>
                <NumberFormat
                  name="amount"
                  className="form-control"
                  value={values.amount}
                  allowNegative={false}
                  thousandSeparator={true}
                  prefix={"$"}
                  onChange={handleChange}
                />
                {errors.amount && (
                  <div className="text-danger">{errors.amount}</div>
                )}
              </div>

              <div className="form-group">
                <label className="mb-2">
                  FedWire Reference Number{" "}
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="reference_number"
                  className="form-control"
                  value={values.reference_number}
                  onChange={handleChange}
                />
                {errors.reference_number && (
                  <div className="text-danger">{errors.reference_number}</div>
                )}
              </div>

              <div className="form-group">
                <label className="mb-2">Description</label>
                <textarea
                  rows={3}
                  name="description"
                  className="form-control"
                  value={values.description}
                  onChange={handleChange}
                />
              </div>

              <button
                disabled={addPayment.isLoading}
                aria-disabled={addPayment.isLoading ? "true" : "false"}
                className="btn btn-eq-primary mt-3"
                type="submit"
              >
                Continue
              </button>
              <button
                className="btn btn-link me-2 mt-3"
                type="button"
                onClick={onHide}
              >
                Cancel
              </button>
            </form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default ManualPaymentModal;

/* eslint-disable react-hooks/exhaustive-deps */
import FileSaver from "file-saver";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { Modal, Tab, Tabs } from "react-bootstrap";
import Spinner from "../../components/spinner";
import { STOCK_TYPES } from "../../constants";
import { CurrencyFormatter, NumberFormatter } from "../../constants/format";
import {
  getClosingCertificate,
  getOfferingFormD,
  getOfferingTail,
} from "../../helpers";
import { useOfferingCertificatePreview } from "../../hooks/useOfferings";
import Header from "../../layouts/header";
import {
  DateTimeService,
  MomentDateTimeAdapter,
} from "../../services/DateTimeService";

const dateFormat = new DateTimeService(new MomentDateTimeAdapter());

const DocumentDownloadCard = ({
  title,
  content,
  buttonText,
  onClick,
  isSpinning = false,
}) => {
  return (
    <div className="card">
      <div className="card-header">{title}</div>
      <Spinner show={isSpinning}>
        <div className="card-body">
          <p className="card-text d-print-none">{content}</p>
          <button onClick={onClick} className="btn btn-eq-primary d-print-none">
            {buttonText}
          </button>
        </div>
      </Spinner>
    </div>
  );
};

const CertificateSummaryDataPoint = ({ label, value }) => {
  return (
    <li className="list-group-item px-4 d-flex justify-content-between w-100 align-items-center">
      <strong style={{ maxWidth: "75%" }}>{label}</strong>
      <span>{value}</span>
    </li>
  );
};

const OfferingCertificate = () => {
  const { id } = useParams();
  const { data: preview } = useOfferingCertificatePreview(id);
  const { offering, issuer, investments, summary } = preview?.data ?? {};
  // const { issuer } = offering?.issuer ?? []

  const [tailSpinner, showTailSpinner] = useState(false);
  const [formDSpinner, showFormDSpinner] = useState(false);
  const [printSpinner, setPrintSpinner] = useState(false);
  const [currentInvestor, setCurrentInvestor] = useState(null);

  const downloadFormD = async () => {
    showFormDSpinner(true);
    try {
      const data = await getOfferingFormD(
        id,
        investments.map((i) => i.id)
      );
      FileSaver.saveAs(
        `data:application/pdf;base64,${data}`,
        `Offering Form D - ${offering.name}.pdf`
      );
    } catch (e) {}
    showFormDSpinner(false);
  };

  const downloadTail = async () => {
    showTailSpinner(true);
    try {
      const data = await getOfferingTail(id);

      FileSaver.saveAs(
        `data:application/pdf;base64,${data}`,
        `Offering Tail - ${offering.name}.pdf`
      );
    } catch (e) {}
    showTailSpinner(false);
  };
  const printCertificate = async () => {
    setPrintSpinner(true);
    const data = await getClosingCertificate(id);
    let timerOut = data ? 10 : 10000;
    FileSaver.saveAs(
      `data:application/pdf;base64,${data}`,
      `Closing Certificate - ${offering.name}.pdf`
    );
    setTimeout(() => {
      setPrintSpinner(false);
    }, timerOut);
  };

  if (!preview) {
    return <Spinner show />;
  }

  return (
    <>
      <Header
        name="Closing Certificate"
        badge={
          <span className="fs-4 badge rounded-pill bg-info d-print-none">
            Beta
          </span>
        }
        title={offering.name}
      >
        <Spinner show={printSpinner}>
          <button
            onClick={printCertificate}
            className="btn btn-eq-primary d-print-none "
            disabled={!investments?.length}
          >
            Print Certificate
          </button>
        </Spinner>
      </Header>

      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-lg-6">
            <h3>Summary</h3>
            <div className="card">
              <ul className="list-group list-group-flush">
                <CertificateSummaryDataPoint
                  label="Issuer Name"
                  value={issuer?.name}
                />
                <CertificateSummaryDataPoint
                  label="Offering Name"
                  value={offering?.name}
                />
                <CertificateSummaryDataPoint
                  label="Launch Date"
                  value={dateFormat.formatMonthDayYear(offering?.launch_date)}
                />
                <CertificateSummaryDataPoint
                  label="Closing Date"
                  value={dateFormat.formatMonthDayYear(
                    offering?.initial_closing_date
                  )}
                />
                <CertificateSummaryDataPoint
                  label="Total Closing Amount"
                  value={CurrencyFormatter.format(
                    summary?.total_closing_amount
                  )}
                />
                <CertificateSummaryDataPoint
                  label={`Total ${
                    STOCK_TYPES[offering.stock_type] ?? offering.stock_type
                  } Sold`}
                  value={NumberFormatter.format(
                    summary.total_security_type_sold
                  )}
                />
                <CertificateSummaryDataPoint
                  label="Number of Investors Ready to Close"
                  value={investments?.length ?? 0}
                />
              </ul>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <h3>Documents</h3>

            <DocumentDownloadCard
              title="Transfer Agent Instruction Letter (TAIL)"
              content="Do you want to generate a TAIL to close these investors?"
              buttonText="Generate TAIL"
              onClick={() => downloadTail()}
              isSpinning={tailSpinner}
            />

            <hr />

            <DocumentDownloadCard
              title="Form D"
              content="Do you want to generate a Form D before closing on these investors?"
              buttonText="Generate Form D"
              onClick={() => downloadFormD()}
              isSpinning={formDSpinner}
            />
          </div>
        </div>

        <hr style={{ pageBreakAfter: "always" }} />

        <h3>Closed Investors</h3>
        <div className="card">
          <div className="table-responsive">
            <table className="table table-hover table-nowrap card-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Accreditation Status</th>
                  <th>Investment Amount</th>
                  <th>Unit Quantity</th>
                  <th>Fund Method</th>
                  <th>Funding Date</th>
                </tr>
              </thead>
              <tbody>
                {investments?.map((investor) => {
                  const paymentType = investor.payments.map(
                    (item) => item.status
                  );
                  return (
                    <tr
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setCurrentInvestor(investor);
                      }}
                      key={investor.id}
                    >
                      <td>{investor.investor.name}</td>
                      <td>{investor.bad_actor ? "No" : "Yes"}</td>
                      <td>{CurrencyFormatter.format(investor.amount)}</td>
                      <td>{NumberFormatter.format(investor.quantity)}</td>
                      <td>
                        {paymentType.includes("manual_payment_success")
                          ? "Wire"
                          : "ACH"}
                      </td>
                      <td>
                        {dateFormat.formatMonthDayYear(
                          investor?.status_history.at(-1).create_date
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Modal
        show={!!currentInvestor}
        size="lg"
        centered
        onHide={() => setCurrentInvestor(null)}
      >
        <Modal.Header closeButton>
          <h3 className="fs-1 mb-0">{currentInvestor?.investorJSON?.name}</h3>
        </Modal.Header>
        <Modal.Body>
          <Tabs>
            <Tab eventKey="statusHistory" title="Status History">
              <div
                className="table-responsive mb-4"
                style={{ overflowY: "auto", maxHeight: "30em" }}
              >
                <table className="table fs-5">
                  <thead>
                    <tr>
                      <th>Action</th>
                      <th>User/Actor</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentInvestor?.status_history?.map((hist, i) => (
                      <tr key={`${i}-${hist.create_date}`}>
                        <td className="align-baseline">{hist.status}</td>
                        <td className="align-baseline">{hist?.user?.name}</td>
                        <td className="align-baseline">
                          {dateFormat.formatMonthDayYear(hist.create_date)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Tab>

            <Tab eventKey="acceptTosInfo" title="Accept TOS Details">
              <div
                className="table-responsive"
                style={{ overflowY: "auto", maxHeight: "30em" }}
              >
                <table className="table table-hover fs-5">
                  <tbody>
                    {Object.entries(currentInvestor?.accept_tos ?? {}).map(
                      ([key, value], i) => (
                        <tr key={`${key}-${i}`}>
                          <td
                            style={{
                              wordBreak: "keep-all",
                              wordWrap: "normal",
                              whiteSpace: "nowrap",
                            }}
                            className="align-baseline"
                          >
                            <strong>
                              <pre className="mb-0">
                                <code>{key}</code>
                              </pre>
                            </strong>
                          </td>
                          <td
                            className="align-baseline"
                            style={{
                              whiteSpace: "pre-line",
                              wordBreak: "break-all",
                              wordWrap: "break-word",
                            }}
                          >
                            {value}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </Tab>
          </Tabs>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OfferingCertificate;

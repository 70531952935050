import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import moment from "moment";
import swal from "sweetalert";

import Spinner from "../../../components/spinner";
import {
  createOfferingAgreement,
  deleteOfferingAgreement,
  IconControl,
  upload,
} from "../../../helpers";
import { useOffering } from "../../../hooks/useOfferings";

const WizardAgreements = (props) => {
  const { id } = useParams();
  const { data: offering, refetch } = useOffering(id);

  const [modal, showModal] = useState(false);
  const [spinner, showSpinner] = useState(false);
  const [agreement, setAgreement] = useState({});

  const handleDelete = (agreement) => {
    swal({
      title: `Remove Agreement`,
      text: `Are you sure you want to remove '${agreement.name}'?`,
      buttons: ["Cancel", "Yes"],
      icon: "error",
      dangerMode: true,
    }).then((status) => {
      if (status) {
        showSpinner(true);
        deleteOfferingAgreement(offering.id, agreement.id).then(async () => {
          toast.success(`${agreement.name} has been removed`);
          await refetch();
          showSpinner(false);
        });
      }
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: { "application/msword": [".doc", ".docx"] },
    onDrop: (acceptedFiles) => {
      showSpinner(true);
      acceptedFiles.forEach((file) => {
        let data = new FormData();
        data.append("file", file);

        upload(data).then((response) => {
          let document = {
            name: agreement.name,
            template_id: agreement.template_id,
            agreement_url: response.url,
          };

          createOfferingAgreement(offering.id, document).then(async (_) => {
            await refetch();
            showSpinner(false);
            showModal(false);
          });
        });
      });
    },
  });

  if (!offering) {
    return <Spinner show />;
  }

  return (
    <>
      <div className="d-flex justify-content-between">
        <h2>
          <IconControl
            placement="right"
            iconName="fe fe-info ms-2"
            inputTitle="Execution Documents"
            tooltipTitle="Please upload Word versions of only the agreements your investors will be executing. We will label these documents in DocuSign for signature by the appropriate parties"
          />
        </h2>
        <button
          className="btn btn-sm btn-light"
          onClick={() => {
            showModal(true);
            setAgreement({});
          }}
        >
          Upload
        </button>
      </div>
      <hr />

      <Spinner show={spinner}>
        <table className="table table-hover mb-0">
          <thead>
            <tr>
              <th>Agreement Name</th>
              <th>Date Uploaded</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {offering.agreements &&
              offering.agreements.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className="text-start align-baseline">
                      {item.name}{" "}
                      {item.template_id && (
                        <i
                          className="fe fe-file-text text-muted ms-3"
                          data-toggle="tooltip"
                          data-placement="top"
                          title={`${item.template_id}`}
                        ></i>
                      )}
                    </td>
                    <td className="align-baseline">
                      {item.create_date &&
                        moment(item.create_date).format("ll")}
                    </td>
                    <td className="text-end align-baseline">
                      <a
                        className="btn btn-sm btn-light"
                        href={item.agreement_url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fe fe-download" />
                      </a>
                      <button
                        className="btn btn-sm btn-light ms-1"
                        onClick={() => {
                          setAgreement(item);
                          showModal(true);
                        }}
                      >
                        <i className="fe fe-edit" />
                      </button>
                      <button
                        className="btn btn-sm btn-light ms-1"
                        onClick={() => handleDelete(item)}
                      >
                        <i className="fe fe-trash" />
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>

        <Modal show={modal} onHide={() => showModal(false)}>
          <Modal.Body>
            <h2 className="mb-0">Upload Agreement</h2>
            <hr />
            <div className="form-group">
              <label className="mb-2">
                Name<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="name"
                className="form-control"
                placeholder="eg: Recon Document"
                value={agreement.name || ""}
                onChange={(event) =>
                  setAgreement({ ...agreement, name: event.target.value })
                }
              />
            </div>
            <div className="form-group">
              <label className="mb-2">Template Id</label>
              <input
                type="text"
                name="template_id"
                className="form-control"
                value={agreement.template_id || ""}
                onChange={(event) =>
                  setAgreement({
                    ...agreement,
                    template_id: event.target.value,
                  })
                }
              />
            </div>
            {agreement.name && (
              <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                <p>
                  Drag 'n' drop the respective files here, or click to select
                  files
                </p>
              </div>
            )}
            <hr />
            <div className="mt-3">
              <button
                className="btn btn-eq-primary"
                type="button"
                onClick={() => showModal(false)}
              >
                Cancel
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </Spinner>

      <hr />
      <Link
        to={`/offering/${offering.id}/wizard/kyc`}
        className="btn btn-eq-primary"
      >
        Save &amp; Continue
      </Link>
    </>
  );
};

export default WizardAgreements;

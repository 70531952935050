export const COMMON_STOCK_OPTIONS = [
  {
    value: "none",
    label: "None",
  },
  {
    value: "class",
    label: "Class",
  },
];

export const STOCK_TYPE_OPTIONS = [
  {
    value: "common_stock",
    label: "Common Stock",
  },
  {
    value: "convertible_note",
    label: "Convertible Notes",
  },
  {
    value: "preferred_stock",
    label: "Preferred Stock",
  },
  {
    value: "unit",
    label: "Units",
  },
  {
    value: "safe",
    label: "SAFE",
  },
  {
    value: "in_lieu_of_cash",
    label: "In Lieu of Cash",
  },
];

export const PREFERRED_SERIES_OPTIONS = [
  {
    value: "none",
    label: "None",
  },
  {
    value: "percentage",
    label: "Percentage %",
  },
];

export const UNIT_DROPDOWN1_OPTIONS = [
  {
    value: "unit_common_stock",
    label: "Common Stock",
  },
  {
    value: "unit_convertable_notes",
    label: "Convertible Notes",
  },
  {
    value: "unit_preferred_stock",
    label: "Preferred Stock",
  },
];

export const UNIT_DROPDOWN2_OPTIONS = [
  {
    value: "with_warrants",
    label: "Warrants",
  },
  {
    value: "unit_preferred_stock",
    label: "Preferred Stock",
  },
  {
    value: "none",
    label: "None",
  },
];

export const SAFE_DROPDOWN_OPTIONS = [
  {
    value: "post_money_valuation_cap",
    label: "Post-Money Valuation Cap",
  },
  {
    value: "discount_rate",
    label: "Discount Rate",
  },
  {
    value: "valuation_cap_with_discount",
    label: "Valuation Cap With Discount",
  },
];

export const BLOCKERS_OPTIONS = [
  {
    value: "4.99",
    label: "4.99",
  },
  {
    value: "9.99",
    label: "9.99",
  },
  {
    value: "other",
    label: "Other",
  },
];

export const PREFERRED_STOCK_OPTIONS = [
  {
    value: "none",
    label: "None",
  },
  {
    value: "series",
    label: "Series",
  },
];

export const ILOC_DROPDOWN_OPTIONS = [
  {
    value: "in_lieu_of_cash_common_stock",
    label: "Common Stock",
  },
  {
    value: "in_lieu_of_cash_convertible_note",
    label: "Convertible Notes",
  },
];

export const IN_LIEU_SUBTYPE_CLASS_OPTIONS = [
  {
    value: "class",
    label: "Class",
  },
  {
    value: "none",
    label: "None",
  },
];

export const FLOAT_VAULT = {
  HUNDRED_MILLION: 100_000_000,
  ONE_MILLION: 1_000_000,
  HUNDRED_THOUSAND: 100_000,
  HUNDRED: 100,
};

import API from "./api";

class BrokerClient {
  static async all() {
    const response = await API().get("/v2/brokers");
    return response.data;
  }

  static async get(id) {
    const response = await API().get(`/v2/brokers/${id}`);
    return response.data;
  }

  static async create(data) {
    const response = await API().post("/v2/brokers", data);
    return response.data;
  }

  static async update(id, data = {}) {
    const response = await API().put(`/v2/brokers/${id}`, data);
    return response.data;
  }
}

export default BrokerClient;

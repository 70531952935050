import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { Formik } from "formik";
import * as Yup from "yup";

import FormControl from "../../../components/formControl";
import Spinner from "../../../components/spinner";
import { STOCK_TYPES } from "../../../constants/offerings";
import { IconControl, updateOffering } from "../../../helpers";
import { toMoney } from "../../../helpers/utils";
import { useOffering } from "../../../hooks/useOfferings";
import { MESSAGE } from "../../../constants/forms";
import { isUnitOffering } from "../helpers";

const WizardOffering = (props) => {
  const history = useHistory();
  const [spinner, showSpinner] = useState(false);

  const { id } = useParams();
  const { data: offering, refetch } = useOffering(id);

  const formSchema = Yup.object().shape({
    minimum_offering_amount: Yup.number()
      .round()
      .transform((value) =>
        isNaN(value) || value === null || value === undefined ? 0 : value
      )
      .min(
        0,
        `Minimum amount should be more than or equal to $${offering?.unit_price}`
      )
      .max(
        offering?.total_amount,
        `Minimum amount should be less than ${offering?.total_amount}`
      )
      .nullable(true),
    optional_maximum_share: Yup.number()
      .round()
      .test(
        "invalid decimal",
        MESSAGE.DECIMAL_VALID,
        (value) => !(value + "").match(/^\d*\.{1}\d*$/)
      )
      .typeError(""),
    minimum_units: Yup.string()
      .nullable(true)
      .when("stock_type", (data) => {
        return Yup.number()
          .round()
          .test(
            "invalid decimal",
            MESSAGE.DECIMAL_VALID,
            (value) => !(value + "").match(/^\d*\.{1}\d*$/)
          )
          .typeError("");
      }),
  });
  const formSchemaConvertibleNotes = Yup.object().shape({
    minimum_offering_amount: Yup.number()
      .transform((value) =>
        isNaN(value) || value === null || value === undefined || value === 0
          ? null
          : value
      )
      .min(
        offering?.minimum_offering_amount == null ? null : offering?.unit_price,
        `Minimum amount should be more than or equal to $${offering?.unit_price}`
      )
      .max(
        offering?.total_amount,
        `Minimum amount should be less than ${offering?.total_amount}`
      )
      .nullable(true),
    optional_maximum_share: Yup.number()
      .round()
      .test(
        "invalid decimal",
        MESSAGE.DECIMAL_VALID,
        (value) => !(value + "").match(/^\d*\.{1}\d*$/)
      )
      .typeError(""),
  });

  const handleSubmit = async (data) => {
    showSpinner(true);

    data.minimum_units = data.minimum_offering_amount / offering.unit_price;
    data.extension_units = Number(
      (data.optional_maximum_percent * offering.total_units) / 100
    );

    await updateOffering(offering.id, data);
    await refetch();

    showSpinner(false);
    history.push(`/offering/${offering.id}/wizard/contacts`);
  };

  const percentValue = (values) => {
    if (
      offering.optional_maximum_percent !== values?.optional_maximum_percent
    ) {
      if (
        values?.optional_maximum_percent &&
        values?.optional_maximum_percent > 100
      ) {
        return values?.optional_maximum_percent?.slice(0, -1);
      }
    }
    return parseFloat(values?.optional_maximum_percent).toFixed(2);
  };

  const supportsMinMaxUnits =
    offering?.stock_type &&
    offering?.stock_type !== STOCK_TYPES.CONVERTIBLE_NOTE &&
    offering?.stock_type !== STOCK_TYPES.SAFE;

  if (!offering) {
    return <Spinner show />;
  }

  return (
    <>
      <h2>Define the size of your offering</h2>
      <hr />
      <Spinner show={spinner}>
        <Formik
          enableReinitialize={true}
          initialValues={offering}
          validationSchema={
            offering?.type !== STOCK_TYPES.CONVERTIBLE_NOTE
              ? formSchema
              : formSchemaConvertibleNotes
          }
          onSubmit={handleSubmit}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ handleChange, handleSubmit, values, errors }) => {
            return (
              <form noValidate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col">
                    <IconControl
                      placement="right"
                      iconName="fe fe-info ms-2"
                      inputTitle="Minimum Offering Amount "
                      tooltipTitle="The minimum offering amount in USD the issuer is able to accept and close the offering"
                    />
                    <FormControl
                      type="money"
                      name="minimum_offering_amount"
                      decimalScale={2}
                      fixedDecimalScale={2}
                      value={toMoney(values.minimum_offering_amount)}
                      error={errors.minimum_offering_amount}
                      onChange={handleChange}
                    />
                  </div>
                  {supportsMinMaxUnits && (
                    <div className="col">
                      <FormControl
                        type="number"
                        name="minimum_units"
                        title={
                          isUnitOffering(offering)
                            ? "Minimum Units"
                            : "Minimum Shares"
                        }
                        disabled={true}
                        value={Math.round(
                          values.minimum_offering_amount / offering.unit_price
                        )}
                        error={errors.minimum_units}
                        onChange={handleChange}
                      />
                    </div>
                  )}
                </div>

                <div className="row">
                  <div className="col">
                    <FormControl
                      type="money"
                      name="total_amount"
                      title="Maximum Offering Amount"
                      decimalScale={2}
                      fixedDecimalScale={2}
                      disabled={true}
                      value={values.total_amount}
                      error={errors.total_amount}
                    />
                  </div>
                  {supportsMinMaxUnits && (
                    <div className="col">
                      <FormControl
                        type="number"
                        name="maximum_units"
                        title={
                          isUnitOffering(offering)
                            ? "Maximum Units"
                            : "Maximum Shares"
                        }
                        disabled={true}
                        value={Math.round(
                          offering.total_amount / offering.unit_price
                        )}
                      />
                    </div>
                  )}
                </div>

                <div className="row">
                  <div className="col">
                    <FormControl
                      type="percent"
                      name="optional_maximum_percent"
                      title="Optional Maximum %"
                      value={percentValue(values)}
                      error={errors.optional_maximum_percent}
                      onChange={handleChange}
                    />
                  </div>
                  {supportsMinMaxUnits && (
                    <div className="col">
                      <FormControl
                        type="number"
                        name="optional_maximum_share"
                        title={
                          isUnitOffering(offering)
                            ? "Optional Maximum Units"
                            : "Optional Maximum Shares"
                        }
                        disabled={true}
                        value={Math.round(
                          (values.optional_maximum_percent *
                            offering.total_units) /
                            100
                        )}
                        error={errors.optional_maximum_share}
                        onChange={handleChange}
                      />
                    </div>
                  )}
                </div>

                <button className="btn btn-eq-primary" type="submit">
                  Save &amp; Continue
                </button>
              </form>
            );
          }}
        </Formik>
      </Spinner>
    </>
  );
};

export default WizardOffering;

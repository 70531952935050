/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch, useParams } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";

import WizardAccount from "./account";
import WizardAddOns from "./addons";
import WizardAgreements from "./agreements";
import WizardCalendar from "./calendar";
import WizardContacts from "./contacts";
import WizardDocuments from "./documents";
import WizardExemptions from "./exemptions";
import WizardNav from "./nav";
import WizardOffering from "./offering";
import WizardProfile from "./profile";
import WizardSubscription from "./subscription";

import Header from "../../../layouts/header";

import { getSettingById, getSettingsByOfferingId } from "../../../helpers";
import { useOffering } from "../../../hooks/useOfferings";
import WizardBroker from "./brokers";
import WizardKyc from "./kyc";

const Wizard = (props) => {
  const dispatch = useDispatch();

  const { id, section } = useParams();

  const { data: offering } = useOffering(id);

  useEffect(() => {
    (async () => {
      if (!offering) return;

      const settings = await getSettingsByOfferingId(id);
      offering.settings = {};

      const paymentBankType = getSettingById(settings, "payment_bank_type");

      offering.has_escrow = paymentBankType
        ? paymentBankType === "escrow" || paymentBankType === "escrow est"
          ? "Yes"
          : "No"
        : "";
      offering.has_equityStock = paymentBankType
        ? paymentBankType === "escrow est"
          ? "Yes"
          : "No"
        : "";
      offering.need_escrow_agent =
        paymentBankType === "Need Escrow Agent" ? "Yes" : "No";
      offering.payment_bank_type = getSettingById(
        settings,
        "payment_bank_type"
      );
      offering.payment_bank_name = getSettingById(
        settings,
        "payment_bank_name"
      );
      offering.payment_account_name = getSettingById(
        settings,
        "payment_account_name"
      );
      offering.payment_account_number = getSettingById(
        settings,
        "payment_account_number"
      );
      offering.payment_account_number_confirm = getSettingById(
        settings,
        "payment_account_number"
      );
      offering.payment_routing_number = getSettingById(
        settings,
        "payment_routing_number"
      );
      offering.payment_routing_number_confirm = getSettingById(
        settings,
        "payment_routing_number"
      );
      offering.has_stock_ticker = offering.stock_ticker ? true : false;

      dispatch({ type: "offering", payload: { ...offering } });
      dispatch({ type: "settings", payload: settings });
    })();
  }, [section, offering]);

  return (
    <>
      <Header name={offering?.name} title={offering?.issuer?.name}></Header>

      <div className="container-fluid">
        <div className="row">
          <div className="col-auto">
            <WizardNav id={offering?.id} section={section} />
          </div>
          <div className="col">
            <div className="card">
              <div className="card-body">
                <Switch>
                  <Route
                    path="/offering/:id/wizard/account"
                    component={WizardAccount}
                  />
                  <Route
                    path="/offering/:id/wizard/offering"
                    component={WizardOffering}
                  />
                  <Route
                    path="/offering/:id/wizard/contacts"
                    component={WizardContacts}
                  />
                  <Route
                    path="/offering/:id/wizard/kyc"
                    component={WizardKyc}
                  />
                  <Route
                    path="/offering/:id/wizard/profile"
                    component={WizardProfile}
                  />
                  <Route
                    path="/offering/:id/wizard/subscription"
                    component={WizardSubscription}
                  />
                  <Route
                    path="/offering/:id/wizard/exemptions"
                    component={WizardExemptions}
                  />
                  <Route
                    path="/offering/:id/wizard/documents"
                    component={WizardDocuments}
                  />
                  <Route
                    path="/offering/:id/wizard/brokers"
                    component={WizardBroker}
                  />
                  <Route
                    path="/offering/:id/wizard/agreements"
                    component={WizardAgreements}
                  />
                  <Route
                    path="/offering/:id/wizard/calendar"
                    component={WizardCalendar}
                  />
                  <Route
                    path="/offering/:id/wizard/addons"
                    component={WizardAddOns}
                  />
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Wizard;

import React from "react";
import { NavLink } from "react-router-dom";

import items from "../../../data/nav_offering_wizard.json";

const WizardNav = ({ id, section }) => {
  return (
    <ul className="nav flex-column nav-pills">
      {items.map((item) => (
        <li key={item.path} className="nav-item">
          <NavLink
            to={`/offering/${id}/wizard/${item.path}`}
            activeClassName="active"
            className="nav-link"
          >
            {item.name}
          </NavLink>
        </li>
      ))}
    </ul>
  );
};

export default WizardNav;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";

import { Formik } from "formik";
import swal from "sweetalert";

import { values } from "lodash";
import FormControl from "../../../components/formControl";
import Spinner from "../../../components/spinner";
import {
  createOfferingContact,
  createUser,
  deleteOfferingContact,
  getUserByEmail,
  updateOfferingContact,
  updateOfferingUserRole,
} from "../../../helpers";
import { useOffering } from "../../../hooks/useOfferings";

import contactTypes from "../../../data/contact_types.json";
import roles from "../../../data/roles.json";
import { contractFormSchema } from "../../../constants/forms";

const defaultFormData = {
  name: "",
  title: "",
  company: "",
  email: "",
  type: "",
  portal_access: false,
  portal_role: "",
};

const WizardContact = (props) => {
  const { id } = useParams();
  const { data: offering, refetch } = useOffering(id);

  const [modal, showModal] = useState(false);
  const [spinner, showSpinner] = useState(false);
  const [formData, setFormData] = useState(defaultFormData);

  const handleSubmit = async (form) => {
    showSpinner(true);

    const data = {
      first_name: form.first_name,
      middle_name: form.middle_name?.trim() || null,
      last_name: form.last_name,
      type: form.type,
      company: form.company,
      phone: form.phone,
      email: form.email,
      title: form.title,
      portal_access: form.portal_access,
      portal_role: form.portal_access ? form.portal_role : "",
    };
    if (form.id) {
      await updateOfferingContact(offering.id, form.id, data);
    } else {
      await createOfferingContact(offering.id, data);
    }

    if (form.portal_access) handleUserRole(data);
    refetch();
    showSpinner(false);
    showModal(false);
  };

  const handleDelete = async (contact) => {
    const status = await swal({
      title: `Remove Contact`,
      text: `Are you sure you want to remove ${contact.name}?`,
      buttons: ["Cancel", "Yes"],
      icon: "error",
      dangerMode: true,
    });

    if (status) {
      showSpinner(true);
      await deleteOfferingContact(offering.id, contact.id);
      refetch();
      showSpinner(false);
    }
  };

  const handleUserRole = (data) => {
    getUserByEmail(data.email)
      .then((user) =>
        updateOfferingUserRole(
          user.id,
          offering.id,
          data.portal_role,
          offering.issuer_id
        )
      )
      .catch((_) =>
        createUser(data).then((user) =>
          updateOfferingUserRole(
            user.id,
            offering.id,
            data.portal_role,
            offering.issuer_id
          )
        )
      );
  };

  if (!offering) {
    return <Spinner show />;
  }

  return (
    <>
      <div className="d-flex justify-content-between">
        <h2>Please add your company Authorized Persons</h2>
        <div>
          {offering.contacts.length < 5 && (
            <button
              className="btn btn-light"
              onClick={() => {
                setFormData(defaultFormData);
                showModal(true);
              }}
            >
              Add Contact
            </button>
          )}
        </div>
      </div>

      <hr />

      <Spinner show={spinner}>
        <div className="table-responsive">
          <table className="table table-hover mb-0">
            <thead>
              <tr>
                <th>Name</th>
                <th>Contact Type</th>
                <th>Portal Role</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {offering.contacts.map((contact, index) => {
                return (
                  <tr key={index}>
                    <td className="align-baseline">
                      {contact.name}
                      <div className="small text-muted">
                        {contact.title}, {contact.company}
                      </div>
                    </td>
                    <td className="align-baseline">{contact.type}</td>
                    <td className="text-capitalize align-baseline">
                      {contact.portal_role}
                    </td>
                    <td className="text-end align-baseline">
                      <button
                        className="btn btn-sm btn-light"
                        onClick={() => {
                          setFormData(contact);
                          showModal(true);
                        }}
                      >
                        <i className="fe fe-edit" />
                      </button>
                      <button
                        className="btn btn-sm btn-light ms-2"
                        onClick={() => handleDelete(contact)}
                      >
                        <i className="fe fe-trash" />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <hr />

        <Link
          to={`/offering/${offering.id}/wizard/profile`}
          className="btn btn-eq-primary"
        >
          Save &amp; Continue
        </Link>
      </Spinner>

      <Modal
        show={modal}
        onHide={() => showModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="my-modal"
      >
        <Modal.Body>
          <Spinner show={spinner}>
            <h1>{values.id ? "Update" : "Add"} Contact</h1>
            <Formik
              enableReinitialize={true}
              initialValues={formData}
              validationSchema={contractFormSchema}
              onSubmit={handleSubmit}
              validateOnChange={false}
              validateOnBlur={false}
            >
              {({
                handleChange,
                handleSubmit,
                setFieldValue,
                values,
                errors,
              }) => {
                const selectedType = values.type
                  ? contactTypes.filter((x) => x.value === values.type)[0]
                  : {};
                const selectedRole = values.portal_role
                  ? roles.filter((x) => x.value === values.portal_role)[0]
                  : {};
                return (
                  <form noValidate onSubmit={handleSubmit}>
                    <FormControl
                      type="select"
                      name="type"
                      title="Contact Type"
                      required={true}
                      options={contactTypes}
                      value={selectedType}
                      error={errors.type}
                      onChange={handleChange}
                    />

                    <div className="row">
                      <div className="col-4">
                        <FormControl
                          type="text"
                          name="first_name"
                          title="First Name"
                          required={true}
                          placeholder="John"
                          value={values.first_name}
                          error={errors.first_name}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-4">
                        <FormControl
                          type="text"
                          name="middle_name"
                          title="Middle Name"
                          placeholder="J."
                          value={values.middle_name}
                          error={errors.middle_name}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-4">
                        <FormControl
                          type="text"
                          name="last_name"
                          title="Last Name"
                          required={true}
                          placeholder="Doe"
                          value={values.last_name}
                          error={errors.last_name}
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <FormControl
                      type="text"
                      name="company"
                      title="Company / Firm"
                      required={true}
                      placeholder="eg EquiDeFi"
                      value={values.company}
                      error={errors.company}
                      onChange={handleChange}
                    />

                    <FormControl
                      type="text"
                      name="title"
                      title="Title"
                      required={true}
                      placeholder="eg CEO"
                      value={values.title}
                      error={errors.title}
                      onChange={handleChange}
                    />

                    <FormControl
                      type="email"
                      name="email"
                      title="Email"
                      required={true}
                      placeholder="eg john@equidefi.com"
                      value={values.email}
                      error={errors.email}
                      onChange={handleChange}
                    />

                    <FormControl
                      type="phone"
                      name="phone"
                      title="Phone"
                      value={values.phone}
                      error={errors.phone}
                      onChange={handleChange}
                    />
                    <FormControl
                      type="checkbox"
                      name="portal_access"
                      label="Allow portal access?"
                      value={values.portal_access}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue("role", "");
                      }}
                    />
                    {values.portal_access && (
                      <div className="mt-2">
                        <FormControl
                          type="select"
                          name="portal_role"
                          title="Access Level"
                          required={true}
                          options={roles}
                          value={selectedRole}
                          error={errors.portal_role}
                          onChange={handleChange}
                        />
                      </div>
                    )}
                    <hr />
                    <div className="mt-3">
                      <button className="btn btn-eq-primary" type="submit">
                        Continue
                      </button>
                      <button
                        className="btn btn-link ms-2"
                        type="button"
                        onClick={() => showModal(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </Spinner>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default WizardContact;

import { useFormik } from "formik";
import React from "react";
import * as Yup from "yup";

import RadioListItem from "../RadioListItem";

const EntityAccreditationCertification = ({
  user,
  stage,
  showButton = true,
  onContinue = () => {},
}) => {
  const formik = useFormik({
    initialValues: {
      accreditation_type: undefined,
    },
    validationSchema: Yup.object({
      accreditation_type: Yup.string().required(),
    }),
    onSubmit: (values) => {
      onContinue(values);
    },
  });

  if (stage !== "accreditation" || user?.entity_type !== "ENTITY") {
    return null;
  }

  return (
    <div className="mb-6">
      <h2 className="mt-4">Accredited Investor Certification - Entity</h2>
      <p>
        The undersigned makes one of the following representations regarding its
        net worth and certain related matters and has agreed the applicable
        representation is listed below.
      </p>
      <p>
        <strong>Please select the provided accurate statement.</strong>
      </p>
      <form onSubmit={formik.handleSubmit}>
        <ul className="list-unstyled">
          <li>
            <RadioListItem
              formik={formik}
              name="accreditation_type"
              value="TRUST_ASSETS"
              onChange={formik.handleChange}
            >
              The undersigned is a trust with total assets in excess of
              $5,000,000 whose purchase is directed by a person with such
              knowledge and experience in financial and business matters that
              such person is capable of evaluating the merits and risks of the
              prospective investment.
            </RadioListItem>
          </li>
          <li>
            <RadioListItem
              formik={formik}
              name="accreditation_type"
              value="BANK"
              onChange={formik.handleChange}
            >
              The undersigned is a bank, an investment adviser registered
              pursuant to Section 203 of the Advisers Act or registered pursuant
              to the laws of a state, any investment adviser relying on the
              exemption from registering with the SEC under Section 203(l) or
              (m) of the Advisers Act, an insurance company, an investment
              company registered under the United States Investment Company Act
              of 1940, as amended, a broker or dealer registered pursuant to
              Section 15 of the United States Securities Exchange Act of 1934,
              as amended, a business development company, a Small Business
              Investment Company licensed by the United States Small Business
              Administration, a Rural Business Investment Company as defined in
              Section 384A of the Consolidated Farm and Rural Development Act,
              as amended, a plan with total assets in excess of $5,000,000
              established and maintained by a state for the benefit of its
              employees, or a private business development company as defined in
              Section 202(a)(22) of the Advisers Act.
            </RadioListItem>
          </li>
          <li>
            <RadioListItem
              formik={formik}
              name="accreditation_type"
              value="EMPLOYEE_BENEFIT_PLAN"
              onChange={formik.handleChange}
            >
              The undersigned is an employee benefit plan and either all
              investment decisions are made by a bank, savings and loan
              association, insurance company, or registered investment advisor,
              or the undersigned has total assets in excess of $5,000,000 or, if
              such plan is a self-directed plan, investment decisions are made
              solely by persons who are accredited investors.
            </RadioListItem>
          </li>
          <li>
            <RadioListItem
              formik={formik}
              name="accreditation_type"
              value="BUSINESS"
              onChange={formik.handleChange}
            >
              The undersigned is a corporation, limited liability company,
              partnership, business trust, not formed for the purpose of
              acquiring the Securities, or an organization described in Section
              501(c)(3) of the Internal Revenue Code of 1986, as amended (the
              “Code”), in each case with total assets in excess of $5,000,000.
            </RadioListItem>
          </li>
          <li>
            <RadioListItem
              formik={formik}
              name="accreditation_type"
              value="OWNERS_ACCREDITED"
              onChange={formik.handleChange}
            >
              The undersigned is an entity in which all of the equity owners (in
              the case of a revocable living trust, its grantor(s)) qualify
              under any of the above subparagraphs, or, if an individual, each
              such individual has a net worth, either individually or upon a
              joint basis with such individual&apos;s spouse or spousal
              equivalent, as applicable, in excess of $1,000,000 (within the
              meaning of such terms as used in the definition of “accredited
              investor” contained in Rule 501 under the Act), or has had an
              individual income in excess of $200,000 for each of the two most
              recent years, or a joint income with such individual&apos;s spouse
              or spousal equivalent, as applicable, in excess of $300,000 in
              each of those years, and has a reasonable expectation of reaching
              the same income level in the current year.
            </RadioListItem>
          </li>
          <li>
            <RadioListItem
              formik={formik}
              name="accreditation_type"
              value="ENTITY_SUFFICIENT_ASSETS"
              onChange={formik.handleChange}
            >
              The undersigned is an entity, of a type not listed in any of the
              paragraphs above, which was not formed for the specific purpose of
              acquiring the securities offered, owning investments in excess of
              $5,000,000.
            </RadioListItem>
          </li>
          <li>
            <RadioListItem
              formik={formik}
              name="accreditation_type"
              value="FAMILY_OFFICE"
              onChange={formik.handleChange}
            >
              The undersigned is a “family office,” as defined in rule
              202(a)(11)(G)-1 under the Advisers Act, (i) with assets under
              management in excess of $5,000,000, (ii) that is not formed for
              the specific purpose of acquiring the securities offered, and
              (iii) whose prospective investment is directed by a person who has
              such knowledge and experience in financial and business matters
              that such family office is capable of evaluating the merits and
              risks of the prospective investment.
            </RadioListItem>
          </li>
          <li>
            <RadioListItem
              formik={formik}
              name="accreditation_type"
              value="FAMILY_CLIENT"
              onChange={formik.handleChange}
            >
              The undersigned is a “family client,” as defined in rule
              202(a)(11)(G)-1 under the Advisers Act, of a family office meeting
              the requirements in the above paragraph and whose prospective
              investment is directed by such family office pursuant to clause
              (iii) of the above paragraph.
            </RadioListItem>
          </li>
        </ul>
        <p>
          <strong>Please select the provided accurate statement.</strong>
        </p>
        {showButton && (
          <button
            type="submit"
            className="btn btn-sm btn-eq-primary"
            disabled={!formik.dirty ? true : !formik.isValid}
          >
            Continue <i className="fe fe-arrow-right-circle ms-1"></i>
          </button>
        )}
      </form>
    </div>
  );
};

export default EntityAccreditationCertification;

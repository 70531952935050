const TOKEN_KEY = "user:token";

/**
 *
 * @param {string} token
 * @return {void}
 */
export function setUserToken(token) {
  localStorage.setItem(TOKEN_KEY, token);
}

/**
 * @returns {string | null}
 */
export function userToken() {
  return localStorage.getItem(TOKEN_KEY) ?? null;
}

class UserJWTToken {
  constructor() {
    this.key = TOKEN_KEY;
  }
  /**
   *
   * @param {string} token
   * @return {void}
   */
  setToken(value) {
    localStorage.setItem(this.key, value);
  }

  /**
   * @returns {string | null}
   */
  token() {
    return localStorage.getItem(this.key) ?? null;
  }

  /**
   * @description Removes the JWT token from the user's local storage
   */
  clear() {
    localStorage.removeItem(this.key);
  }
}

const UserToken = new UserJWTToken();

export default UserToken;

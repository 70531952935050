import APIClient from "./api";

class InvestmentClient {
  /**
   * @param {string} id
   * @return {Promise<object>}
   */
  static async get(id) {
    const response = await APIClient().get(`/v2/investments/${id}`);
    return response.data;
  }

  /**
   * @param {string} id
   * @return {Promise<object>}
   */
  static async getInquiry(id) {
    const response = await APIClient().get(`/v2/investments/${id}/inquiry`);
    return response.data;
  }

  /**
   * @param {string} id
   * @param {object} data
   * @return {Promise<object>}
   */
  static async update(id, data = {}) {
    const response = await APIClient().patch(`/v2/investments/${id}`, data);
    return response.data;
  }

  /**
   * @param {string} id
   * @param {string} stage
   * @param {object} data
   * @return {Promise<object>}
   */
  static async updateStage(id, stage, data = {}) {
    const response = await APIClient().patch(
      `/v2/investments/${id}/stage/${stage}`,
      data
    );
    return response.data;
  }

  /**
   * @param {string} id
   * @param {object} data
   * @return {Promise<object>}
   */
  static async legacyAddRefund(id, data = {}) {
    const response = await APIClient().post(`/investment/${id}/refund`, data);
    return response.data;
  }

  /**
   * @param {string} id
   * @param {object} data
   * @return {Promise<object>}
   */
  static async legacyAddManualPayment(id, data = {}) {
    const response = await APIClient().post(`/investment/${id}/payment`, data);
    return response.data;
  }

  /**
   * @param {string} id
   * @param {{ status: "APPROVE" | "DECLINE", comment?: string }} data
   * @return {Promise<object>}
   */
  static async updateInquiryStatus(id, data = {}) {
    const response = await APIClient().put(
      `/v2/investments/${id}/inquiry/status`,
      data
    );
    return response.data;
  }

  static async getAgreementDocusignUrl(id, envelopeId, type) {
    const response = await APIClient().get(
      `/investment/${id}/agreement/${envelopeId}/url/${type}`
    );
    return response.data;
  }
}

export default InvestmentClient;

import { useQuery } from "@tanstack/react-query";
import store from "../store";

import axios from "axios";
import {
  getOfferingById,
  getOfferingCertificatePreview,
  getOfferingInvestments,
  getOfferings,
  getSettingsByOfferingId,
} from "../helpers";

export const useOfferings = (search) => {
  return useQuery(["offerings", "search", search], () => getOfferings(search));
};

/**
 * @typedef {Parameters<typeof useQuery>[2]} UseQueryOptions
 * @param {*} id
 * @param {UseQueryOptions} options
 * @returns
 */
export const useOffering = (id, options = {}) => {
  return useQuery(["offerings", id], () => getOfferingById(id), {
    enabled: !!id,
    ...options,
    onSuccess: (...args) => {
      const [offering] = args;
      store.dispatch({ type: "offering", payload: offering });
      options?.onSuccess?.(...args);
    },
  });
};

export const useOfferingBrokers = (id) => {
  return useQuery(["offeringBrokers", id], () => {
    return new Promise(function (resolve, reject) {
      axios
        .get(`/v2/offerings/${id}/brokers`)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  });
};

export const addOfferingBroker = (offeringId, brokerId) => {
  return new Promise(function (resolve, reject) {
    axios
      .post(`/v2/offerings/${offeringId}/broker/${brokerId}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

export const removeOfferingBroker = (offeringId, brokerId) => {
  return new Promise(function (resolve, reject) {
    axios
      .delete(`/v2/offerings/${offeringId}/broker/${brokerId}`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

export const useOfferingSettings = (id, options = {}) => {
  return useQuery(
    ["offerings", id, "settings"],
    () => getSettingsByOfferingId(id),
    {
      enabled: !!id,
      ...options,
    }
  );
};

export const useOfferingInvestments = (id, options = {}) =>
  useQuery(["offerings", id, "investments"], () => getOfferingInvestments(id), {
    enabled: !!id,
    ...options,
  });

export const useOfferingCertificatePreview = (id, options = {}) =>
  useQuery(
    ["offerings", id, "certificate.preview"],
    () => getOfferingCertificatePreview(id),
    {
      enabled: !!id,
      refetchInterval: 60_000,
      ...options,
    }
  );

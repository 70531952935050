import clsx from "clsx";
import { Formik } from "formik";
import { useMemo, useState } from "react";
import { Badge, Button, ButtonGroup, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

import { MESSAGE } from "../../../constants/forms";
import
  {
    INQUIRY_STATUS_ALERT_TYPE,
    INQUIRY_STATUS_TEXT,
  } from "../../../constants/inquiries";
import { IconControl, isAdmin, isIssuerOwner } from "../../../helpers";
import { personaInquiryLink } from "../../../helpers/persona";
import { useInvestmentInquiryUpdate } from "../../../hooks/inquiries";
import
  {
    useInvestment,
    useInvestmentInquiry,
  } from "../../../hooks/investments";
import { useOffering } from "../../../hooks/useOfferings";
import FormControl from "../../formControl";

import styles from "./styles.module.css";

const INQUIRY_REFETCH_INTERVAL = 20_000;

const InvestmentInquiry = () => {
  const user = useSelector((store) => store.user);
  const { id: offeringId, investment_id: investmentId } = useParams();

  const { data: investment } = useInvestment(investmentId);
  const { data: offering } = useOffering(offeringId);
  const { data: inquiry } = useInvestmentInquiry(investmentId, {
    enabled: !!investment?.persona_inquiry_id,
    refetchInterval: INQUIRY_REFETCH_INTERVAL,
    refetchOnWindowFocus: true,
  });

  const [disabledActions, setDisabledActions] = useState(false);
  const [modalAction, setModalAction] = useState(null);

  const updateInquiryStatus = useInvestmentInquiryUpdate(investmentId);
  const handleInquiryUpdate = async (data) => {
    try {
      await updateInquiryStatus.mutateAsync({
        data: {
          ...data,
          status: modalAction,
        },
      });
      setDisabledActions(true);
      setModalAction(null);
    } catch (error) {
      toast.error(
        `Something went wrong, and we cannot ${modalAction.toLowerCase()} this inquiry`
      );
    }
  };

  const notUpdatable = useMemo(() => {
    if (!["completed", "needs_review"].includes(inquiry?.status)) {
      return true;
    };

    return disabledActions;
  }, [disabledActions, inquiry?.status]);

  const canApproveOrDeny = useMemo(
    () => isAdmin(user) || isIssuerOwner(user, offering?.issuer_id),
    [user, offering?.issuer_id]
  );

  if (!inquiry) {
    return null;
  }

  return (
    <div className="card">
      <div className="card-header">
        <h4 className="card-header-title">KYC/KYB</h4>
        {isAdmin(user) && (
          <a
            href={personaInquiryLink(inquiry.inquiry_id)}
            rel="external noreferrer noopener"
            target="_blank"
            className="btn btn-sm btn-light"
          >
            View in Persona
          </a>
        )}
      </div>
      <div className="card-body">
        <div className="d-flex align-content-center align-items-center justify-content-between mb-3">
          <span className="fs-2">
            <Badge bg={INQUIRY_STATUS_ALERT_TYPE[inquiry.status]}>
              {INQUIRY_STATUS_TEXT[inquiry.status]}
            </Badge>
          </span>
          <span className="mx-auto"></span>
          <IconControl
            placement="left"
            iconName="fe fe-info ms-auto me-2"
            tooltipTitle="Persona Inquiries can be approved or declined from a Completed or Needs Review state"
          />
          {canApproveOrDeny && (
            <ButtonGroup size="sm" aria-label="inquiry actions">
              <Button
                onClick={() => setModalAction("APPROVE")}
                disabled={notUpdatable}
                variant="outline-primary"
              >
                Approve
              </Button>
              <Button
                onClick={() => setModalAction("DECLINE")}
                disabled={notUpdatable}
                variant="outline-warning"
              >
                Decline
              </Button>
            </ButtonGroup>
          )}
        </div>
      </div>
      <Modal show={modalAction}>
        <Modal.Header closeButton onHide={() => setModalAction(null)}>
          <span className={clsx(styles.modalTitle, "fs-2")}>
            {modalAction} Inquiry
          </span>
        </Modal.Header>
        <Modal.Body>
          <Formik
            enableReinitialize={true}
            initialValues={{ comment: undefined }}
            validationSchema={Yup.object().shape({
              comment: Yup.string().required(MESSAGE.REQUIRED("comment")),
            })}
            onSubmit={handleInquiryUpdate}
            validateOnChange={false}
            validateOnBlur={false}
          >
            {({ handleSubmit, handleChange, values, errors }) => (
              <form onSubmit={handleSubmit}>
                <FormControl
                  type="text"
                  name="comment"
                  title="Comment"
                  placeholder="e.g. Approving or Declining because..."
                  required
                  value={values.comment}
                  error={errors?.comment}
                  onChange={handleChange}
                />

                <Button variant="outline-primary" type="submit">
                  Update
                </Button>
              </form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default InvestmentInquiry;

import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import moment from "moment";
import swal from "sweetalert";

import Spinner from "../../../components/spinner";
import { documentCategories } from "../../../constants";
import {
  createOfferingDocument,
  deleteOfferingDocument,
  IconControl,
  upload,
} from "../../../helpers";
import { useOffering } from "../../../hooks/useOfferings";

const WizardDocuments = (props) => {
  const { id } = useParams();
  const { data: offering, refetch } = useOffering(id);

  const [modal, showModal] = useState(false);
  const [category, setCategory] = useState("");
  const [spinner, showSpinner] = useState(false);
  const [dropdownOther, setDropdownOther] = useState("");
  const [isValid, setIsValid] = useState(false);

  const handleDelete = (document) => {
    swal({
      title: `Remove Document`,
      text: `Are you sure you want to remove '${document.type}'?`,
      buttons: ["Cancel", "Yes"],
      icon: "error",
      dangerMode: true,
    }).then((status) => {
      if (status) {
        showSpinner(true);
        deleteOfferingDocument(offering.id, document.id).then(async (_) => {
          await refetch();
          toast.success(`${document.type} has been removed.`);
        });
      }
    });
  };

  const handleChange = (e) => {
    let value = e.target.value.trim();
    if (value) {
      setDropdownOther(e.target.value);
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: { "application/pdf": [] },
    onDrop: (acceptedFiles) => {
      showSpinner(true);
      acceptedFiles.forEach((file) => {
        let data = new FormData();
        data.append("file", file);
        upload(data).then((file) => {
          let document = {
            name: category,
            type: category !== "Other" ? category : dropdownOther,
            document_url: file.url,
          };

          createOfferingDocument(offering.id, document).then(async (_) => {
            setCategory("");
            await refetch();
            showSpinner(false);
            showModal(false);
          });
        });
      });
    },
  });

  if (!offering) {
    return <Spinner show />;
  }

  return (
    <>
      <div className="d-flex justify-content-between">
        <h2>
          <IconControl
            placement="right"
            iconName="fe fe-info ms-2"
            inputTitle="Investor Package"
            tooltipTitle="Please use the upload button to upload PDF files of your offering documents for investor review. In the next step, you can upload only the documents investors will execute."
          />
        </h2>
        <button
          className="btn btn-sm btn-light"
          onClick={() => showModal(true)}
        >
          Upload
        </button>
      </div>
      <hr />

      <Spinner show={spinner}>
        <table className="table table-hover mb-0">
          <thead>
            <tr>
              <th>Name</th>
              <th className="text-nowrap">Uploaded On</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {offering.documents &&
              offering.documents.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className="text-start align-baseline">{item.type}</td>
                    <td className="align-baseline">
                      {item.create_date &&
                        moment(item.create_date).format("ll")}
                    </td>
                    <td className="text-end text-nowrap align-baseline">
                      <a
                        className="btn btn-sm btn-light"
                        href={item.document_url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fe fe-download" />
                      </a>
                      <button
                        className="btn btn-sm btn-light ms-2"
                        onClick={() => {
                          handleDelete(item);
                        }}
                      >
                        <i className="fe fe-trash" />
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </Spinner>

      <Modal show={modal} onHide={() => showModal(false)}>
        <Modal.Body>
          <h2 className="mb-0">Upload Documents</h2>
          <hr />
          <Spinner show={spinner}>
            <div className="form-group">
              <label className="mb-2">
                Category<span className="text-danger">*</span>
              </label>
              <select
                className="form-control"
                as="select"
                name="document.primary"
                defaultValue={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                <option hidden value="" disabled>
                  Select Category
                </option>
                {documentCategories.map((type, index) => {
                  return (
                    <option key={index} value={type.name}>
                      {type.name}
                    </option>
                  );
                })}
              </select>
            </div>
            {category && category !== "Other" && (
              <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                <p>
                  Drag 'n' drop the respective files here, or click to select
                  files
                </p>
              </div>
            )}
            {category === "Other" && (
              <>
                <input
                  type="text"
                  name="otherCategory"
                  placeholder="Please specify category"
                  className="form-control"
                  onChange={(e) => handleChange(e)}
                />
                {isValid && (
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <p>
                      Drag 'n' drop the respective files here, or click to
                      select files
                    </p>
                  </div>
                )}
              </>
            )}
          </Spinner>
        </Modal.Body>
      </Modal>
      <hr />

      <Link
        to={`/offering/${offering.id}/wizard/agreements`}
        className="btn btn-eq-primary"
      >
        Save &amp; Continue
      </Link>
    </>
  );
};

export default WizardDocuments;

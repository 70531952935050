import {useEffect, useMemo, useState} from 'react';
import {usePaymentMethod} from "../../hooks/useStripe";
import {useSelector} from "react-redux";
import Spinner from "../../components/spinner";
import './style.css';

export default function Success(props) {
  const issuer = useSelector((state) => state.issuer_ref);
  const [isLoading, setLoading] = useState(true);
  const [paymentIntent, setPaymentIntent] = useState();

  const title = useMemo(() => {
    if (!paymentIntent) return null;
    if (paymentIntent.last_setup_error || paymentIntent.status === "requires_payment_method") {
      return "There was a problem";
    }
    return "Success";
  }, [paymentIntent]);

  const message = useMemo(() => {
    if (!paymentIntent) return null;
    if (paymentIntent.last_setup_error) {
      return paymentIntent.last_setup_error.message;
    }
    if (paymentIntent.status === "requires_payment_method") {
      return "Confirmation failed. Attempt again with a different payment method.";
    }
    if (["processing", "succeeded"].includes(paymentIntent.status)) {
      return "Your bank has been successfully connected!"
    }
    if (paymentIntent.next_action?.type === "verify_with_microdeposits") {
      return "Your bank account needs to be verified, please check your email inbox."
    }
    return "";
  }, [paymentIntent]);

  const {
    setup_intent: setupIntentId,
    // setup_intent_client_secret: clientSecret,
    // redirect_status: status
  } = Object.fromEntries(new URLSearchParams(props.location.search));

  const {paymentMutate} = usePaymentMethod(issuer.id, setupIntentId);

  useEffect(() => {
    const SavePaymentMethod = async () => {
      const result = await paymentMutate(null);
      setPaymentIntent(result);
      setLoading(false);
    }
    SavePaymentMethod().then()
  }, []);

  if (isLoading) {
    return <Spinner show={true} />
  }

  return (
    <div className="container-fluid success">
        <h1>{title}</h1>
        <div className="row">
          <div className="setup-intent-message">
            {message}
          </div>
        </div>
    </div>
  );
}

import {useEffect, useState} from 'react';

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {useStripeSetup} from "../../hooks/useStripe";
import Spinner from "../../components/spinner";
import PaymentForm from "../../components/stripe/PaymentForm";
import {useSelector} from "react-redux";
import './style.css';

const appearance = {
  theme: 'stripe'
}

export default function Payment() {
  const issuer = useSelector((state) => state.issuer_ref);
  const [isLoading, setLoading] = useState(true);
  const [stripeSetup, setStripeSetup] = useState();
  const {setupMutate} = useStripeSetup(issuer.id);

  useEffect(() => {
    const getAPIData = async () => {
      const setupResult = await setupMutate(null);
      setStripeSetup(setupResult);
      setLoading(false);
    }
    getAPIData().then();
  }, []);

  if (isLoading) {
    return <Spinner show={true} />
  }

  return (
    <>
      <div className="container-fluid payment">
          <h1>Connect your Bank</h1>
          <div className="row">
            <Elements
              stripe={loadStripe(stripeSetup.publishableKey)}
              options={{ clientSecret: stripeSetup.setupIntent.client_secret }}
              apperance={appearance}
            >
              <PaymentForm clientSecret={stripeSetup.setupIntent.client_secret} />
            </Elements>
          </div>
      </div>
    </>
  );
}

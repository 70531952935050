import React, { useCallback, useState } from "react";

import { Formik } from "formik";

import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";

import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import OfferingClient from "../../../clients/OfferingClient";
import FormControl from "../../../components/formControl";
import Spinner from "../../../components/spinner";
import { useOffering } from "../../../hooks/useOfferings";

Yup.addMethod(Yup.string, "stripEmptyString", function () {
  return this.transform((value) =>
    typeof value === "string" && value.trim() === "" ? null : value
  );
});

const KycOfferingSchema = Yup.object().shape({
  us_kyc_template_id: Yup.string().stripEmptyString().nullable(),
  us_kyb_template_id: Yup.string().stripEmptyString().nullable(),
  intl_kyc_template_id: Yup.string().stripEmptyString().nullable(),
  intl_kyb_template_id: Yup.string().stripEmptyString().nullable(),
});

const WizardKyc = () => {
  const params = useParams();
  const { data: offering } = useOffering(params.id);
  const history = useHistory();
  const [spinner, showSpinner] = useState(false);

  const handleUpdateKYC = useCallback(
    async (data) => {
      const result = KycOfferingSchema.cast(data);
      showSpinner(true);

      try {
        await OfferingClient.update(offering.id, result)
        history.push(`/offering/${offering.id}/wizard/brokers`);
      } catch (e) {
        toast.error("Something went wrong. Our team has been notified.");
      } finally {
        showSpinner(false);
      }
    },
    [history, offering?.id]
  );

  if (!offering) {
    return <Spinner show />;
  }

  return (
    <>
      <h2>Persona KYC/KYB Templates</h2>

      <Spinner show={spinner}>
        <Formik
          enableReinitialize={true}
          initialValues={{
            us_kyc_template_id: offering?.us_kyc_template_id ?? null,
            us_kyb_template_id: offering?.us_kyb_template_id ?? null,
            intl_kyc_template_id: offering?.intl_kyc_template_id ?? null,
            intl_kyb_template_id: offering?.intl_kyb_template_id ?? null,
          }}
          validationSchema={KycOfferingSchema}
          onSubmit={handleUpdateKYC}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ handleChange, handleSubmit, values, errors }) => {
            return (
              <form noValidate onSubmit={handleSubmit}>
                <hr />
                <h3>USA Investor KYC Templates</h3>

                <div id="us_kyc_template_id">
                  <FormControl
                    type="text"
                    name="us_kyc_template_id"
                    title="KYC Template ID for US Investors"
                    value={values.us_kyc_template_id}
                    error={errors.us_kyc_template_id}
                    onChange={handleChange}
                  />
                </div>

                <hr />

                <div id="us_kyb_template_id">
                  <FormControl
                    type="text"
                    name="us_kyb_template_id"
                    title="KYB Template ID for US Investors"
                    value={values.us_kyb_template_id}
                    error={errors.us_kyb_template_id}
                    onChange={handleChange}
                  />
                </div>

                <hr />

                <h3>International Investor KYC Templates</h3>

                <div id="intl_kyc_template_id">
                  <FormControl
                    type="text"
                    name="intl_kyc_template_id"
                    title="KYC Template ID for International Investors"
                    value={values.intl_kyc_template_id}
                    error={errors.intl_kyc_template_id}
                    onChange={handleChange}
                  />
                </div>

                <hr />

                <div id="intl_kyb_template_id">
                  <FormControl
                    type="text"
                    name="intl_kyb_template_id"
                    title="KYB Template ID for International Investors"
                    value={values.intl_kyb_template_id}
                    error={errors.intl_kyb_template_id}
                    onChange={handleChange}
                  />
                </div>

                <hr />

                <div className="d-flex flex-row justify-content-start">
                  <button className="btn btn-eq-primary" type="submit">
                    Save &amp; Continue
                  </button>
                  <Link
                    to={`/offering/${params.id}/wizard/brokers`}
                    className="btn btn-link"
                    type="submit"
                  >
                    Skip
                  </Link>
                </div>
              </form>
            );
          }}
        </Formik>
      </Spinner>
    </>
  );
};

export default WizardKyc;

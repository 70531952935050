import { Formik } from "formik";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";

import FormControl from "../../../components/formControl";
import Spinner from "../../../components/spinner";
import { IconControl, updateOffering } from "../../../helpers";
import { toMoney } from "../../../helpers/utils";
import { useOffering } from "../../../hooks/useOfferings";
import { isUnitOffering } from "../helpers";
import { CurrencyFormatter } from "../../../constants/format";

const WizardSubscription = (props) => {
  const history = useHistory();
  const [spinner, showSpinner] = useState(false);

  const { id } = useParams();
  const { data: offering, refetch } = useOffering(id);

  const handleSubmit = async (data) => {
    showSpinner(true);
    data.investor_minimum_units =
      data.investor_minimum_amount / data.unit_price;
    await updateOffering(offering.id, data);
    refetch();
    showSpinner(false);
    history.push(`/offering/${offering.id}/wizard/exemptions`);
  };

  const formSchema = Yup.object().shape({
    investor_minimum_amount: Yup.number()
      .transform((value) =>
        isNaN(value) || value === null || value === undefined || value === 0
          ? null
          : value
      )
      .min(
        0,
        `Minimum amount should be more than or equal to $${offering?.unit_price}`
      )
      .max(
        offering?.minimum_offering_amount,
        `Minimum amount should be less than ${CurrencyFormatter.format(
          offering?.minimum_offering_amount
        )}`
      )
      .nullable(true),
    investor_minimum_units: Yup.string()
      .nullable(true)
      .when("stock_type", (data) => {
        return Yup.number()
          .test(
            "invalid decimal",
            "Value should not be in decimal format",
            (value) => !(value + "").match(/^\d*\.{1}\d*$/)
          )
          .typeError("");
      }),
  });
  const formSchemaConvertibleNote = Yup.object().shape({
    investor_minimum_amount: Yup.number()
      .transform((value) =>
        isNaN(value) || value === null || value === undefined || value === 0
          ? null
          : value
      )
      .min(
        offering?.investor_minimum_amount == null ? null : offering?.unit_price,
        `Minimum amount should be more than or equal to $${offering?.unit_price}`
      )
      .max(
        offering?.minimum_offering_amount,
        `Minimum amount should be less than ${CurrencyFormatter.format(
          offering?.minimum_offering_amount
        )}`
      )
      .nullable(true),
  });

  if (!offering) {
    return <Spinner show />;
  }

  return (
    <>
      <h2>Would you like to define subscription limitations?</h2>
      <hr />

      <Spinner show={spinner}>
        <Formik
          enableReinitialize={true}
          initialValues={offering}
          validationSchema={
            offering?.stock_type !== "convertible_note"
              ? formSchema
              : formSchemaConvertibleNote
          }
          onSubmit={handleSubmit}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ handleChange, handleSubmit, values, errors }) => (
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col">
                  <IconControl
                    placement="right"
                    iconName="fe fe-info ms-2"
                    inputTitle="Minimum Subscription Amount"
                    tooltipTitle="This is the minimum subscription amount you are willing to accept from a subscriber"
                  />
                  <FormControl
                    type="money"
                    name="investor_minimum_amount"
                    decimalScale={2}
                    fixedDecimalScale={2}
                    value={toMoney(
                      values.investor_minimum_amount > offering.total_amount
                        ? values.investor_minimum_amount.toString().slice(0, -1)
                        : values.investor_minimum_amount
                    )}
                    error={errors.investor_minimum_amount}
                    onChange={handleChange}
                  />
                </div>
                {offering?.stock_type !== "convertible_note" &&
                  !offering?.stock_type?.split(" ").includes("Safe") && (
                    <div className="col">
                      <FormControl
                        type="number"
                        name="investor_minimum_units"
                        title={
                          isUnitOffering(offering)
                            ? "Minimum Units"
                            : "Minimum Shares"
                        }
                        disabled={true}
                        value={Math.round(
                          values.investor_minimum_amount / offering.unit_price
                        )}
                        error={errors.investor_minimum_units}
                        onChange={handleChange}
                      />
                    </div>
                  )}
              </div>
              <hr />
              <button className="btn btn-eq-primary" type="submit">
                Save &amp; Continue
              </button>
            </form>
          )}
        </Formik>
      </Spinner>
    </>
  );
};

export default WizardSubscription;

import { useState } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import axios from 'axios';

import { Formik } from 'formik';

import Spinner from '../../components/spinner';
import FormControl from '../../components/formControl';

import { updatePassword } from "../../helpers";
import { resetFormSchema } from "../../constants/forms";

const formData = {
    password: '',
    confirmPassword: '',
};

const ResetPassword = (props) => {
  document.title = "Reset Password | Powered by EquiDeFi";
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${props.match.params.token}`;

  const [spinner, showSpinner] = useState(false);

  const handleResetPassword = async ({ password }) => {
    showSpinner(true);
    updatePassword(props.match.params.id, password)
      .then((_) => {
        toast.info(`Your password has been updated. Please login to continue`);
        props.history.push("/");
      })
      .catch((_) => {
        toast.info(`An error occured while trying to process your request`);
        showSpinner(false);
      });
  };

  return (
    <div className="container-fluid">
      <div className="row vh-100 justify-content-center">
        <div className="col-12 col-md-5 col-lg-6 col-xl-4 px-lg-6 my-5 align-self-center">
          <img
            src="/img/logo.svg"
            width="200px"
            alt="logo"
            className="d-block m-auto mb-5"
          />
          <h1 className="display-4 text-center mb-2">Reset Password</h1>
          <p className="text-muted text-center mb-5">
            Enter a secure password for your account
          </p>

          <Formik
            initialValues={formData}
            validationSchema={resetFormSchema}
            onSubmit={handleResetPassword}
            validateOnChange={false}
            validateOnBlur={false}
          >
            {({ handleChange, handleSubmit, values, errors }) => (
              <form noValidate onSubmit={handleSubmit}>
                <FormControl
                  type="password"
                  name="password"
                  title="New Password"
                  required={true}
                  value={values.password}
                  error={errors.password}
                  onChange={handleChange}
                />

                <FormControl
                  type="password"
                  name="confirmPassword"
                  title="Confirm Password"
                  required={true}
                  value={values.confirmPassword}
                  error={errors.confirmPassword}
                  onChange={handleChange}
                />

                <Spinner show={spinner}>
                  <button type="submit" className="btn w-100 btn-eq-primary">
                    Reset Password
                  </button>
                </Spinner>

                <div className="small text-center text-muted mt-3">
                  Remember your password? <Link to="/">Sign In</Link>
                </div>
              </form>
            )}
          </Formik>
        </div>
        <div className="col-12 col-md-7 col-lg-6 col-xl-8 d-none d-lg-block">
          <div
            className="bg-cover h-100 min-vh-100 mt-n1 me-n3"
            style={{ backgroundImage: "url(/img/login.jpg)" }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;

import clsx from "clsx";
import { Formik } from "formik";
import React, { useCallback, useMemo, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import * as Yup from "yup";
import MFAModal from "../../components/MFAModal";
import {
  useCurrentInvestment,
  useInvestmentCreate,
  useInvestmentUpdateStage,
} from "../../hooks/useInvestments";
import { useCurrentUser, useUpdateCurrentUser } from "../../hooks/useUsers";
import { useRegister } from "../../hooks/useAuthentication";

import NumberFormat from "react-number-format";
import { investmentContinueUrlOpenOffering } from "../../helpers/investment";
import "./style.css";

const userSchema = Yup.object().shape({
  first_name: Yup.string().nullable().required("Please enter your first name"),
  last_name: Yup.string().nullable().required("Please enter your last name"),
  phone: Yup.string().matches(
    /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
    "Invalid phone number"
  ),
  email: Yup.string()
    .nullable()
    .email("Must be a valid email")
    .required("Please enter your email address"),
  entity_type: Yup.string().required(
    "Please select either individual or entity"
  ),
  company: Yup.string()
    .nullable()
    .when("entity_type", {
      is: (entity_type) => entity_type === "Entity",
      then: Yup.string().required(
        "Company name is required when investing as an entity"
      ),
    }),
});

const FirstName = ({ handleChange, handleBlur, errors, values }) => {
  return (
    <div className="form-group">
      <label htmlFor="first_name">
        Legal First Name <span className="text-danger">*</span>
      </label>
      <input
        id="first_name"
        name="first_name"
        value={values.first_name}
        onChange={handleChange}
        onBlur={handleBlur}
        type="text"
        className="form-control form-control-sm"
      />
      {errors?.first_name && (
        <span className="text-danger">{errors?.first_name}</span>
      )}
    </div>
  );
};

const MiddleName = ({ handleChange, handleBlur, errors, values }) => {
  return (
    <div className="form-group">
      <label htmlFor="middle_name">Legal Middle Name</label>
      <input
        id="middle_name"
        name="middle_name"
        value={values?.middle_name}
        onChange={handleChange}
        onBlur={handleBlur}
        type="text"
        className="form-control form-control-sm"
      />
      {errors?.middle_name && (
        <span className="text-danger">{errors?.middle_name}</span>
      )}
    </div>
  );
};

const LastName = ({ handleChange, handleBlur, errors, values }) => {
  return (
    <div className="form-group">
      <label htmlFor="last_name">
        Legal Last Name <span className="text-danger">*</span>
      </label>
      <input
        id="last_name"
        name="last_name"
        value={values?.last_name}
        onChange={handleChange}
        onBlur={handleBlur}
        type="text"
        className="form-control form-control-sm"
      />
      {errors?.last_name && (
        <span className="text-danger">{errors?.last_name}</span>
      )}
    </div>
  );
};

const Phone = ({ handleChange, errors, values, setFieldValue, ...rest }) => {
  return (
    <div className="form-group">
      <label htmlFor="phone">
        Mobile Phone <span className="text-danger">*</span>
      </label>
      <NumberFormat
        {...rest}
        placeholder="eg: +1 (444) 444-4444"
        className="form-control form-control-sm"
        format="+1 (###) ###-####"
        mask="_"
        name="phone"
        value={values.phone}
        handleChange={handleChange}
        onValueChange={({ value }) => {
          setFieldValue("phone", value);
        }}
      />
      {errors?.phone && (
        <div className="text-danger mt-2 ms-1 h5">{errors.phone}</div>
      )}
    </div>
  );
};

const Email = ({ handleChange, handleBlur, errors, values }) => {
  const { data: me } = useCurrentUser();

  return (
    <div className="form-group">
      <label htmlFor="email">
        Primary Email <span className="text-danger">*</span>
      </label>
      <input
        id="email"
        name="email"
        value={values.email}
        onChange={handleChange}
        disabled={me?.id}
        onBlur={handleBlur}
        type="email"
        className="form-control form-control-sm"
      />
      {errors?.email && <span className="text-danger">{errors?.email}</span>}
    </div>
  );
};

const DEFAULT_LABEL_TEXT =
  "Are you investing as an individual or an entity? (Corp, LLC, etc.)";

export const AccountType = ({
  label = DEFAULT_LABEL_TEXT,
  handleChange,
  handleBlur,
  errors,
  values,
}) => {
  return (
    <div className="form-group account-type">
      <label>{label}</label>
      <div>
        <label className="form-check-label me-2" htmlFor="entity_type">
          Individual
        </label>
        <input
          className="form-check-input me-4"
          name="entity_type"
          type="radio"
          value="Individual"
          checked={values?.entity_type?.toUpperCase() === "INDIVIDUAL"}
          onChange={handleChange}
          onBlur={handleBlur}
        />

        <label className="form-check-label me-2" htmlFor="entity_type">
          Entity
        </label>
        <input
          className="form-check-input"
          name="entity_type"
          type="radio"
          value="Entity"
          checked={values?.entity_type?.toUpperCase() === "ENTITY"}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <i
          className="fe fe-info ms-2"
          data-tooltip-id="tooltip"
          data-tooltip-html="<strong>List of Entity Types:</strong><br />Corporation Limited Liability Corporation (LLC)<br />Limited Liability
          Partnership (LLP)<br />Limited Partnership (LP)<br />Registered Investment
          Company<br />Revocable Trust Irrevocable<br />Trust Testamentary<br />Trust
          Community<br />Property Tenants in Common Rights of Survivorship<br />Foreign
          Joint Account<br />Self-Directed IRA"
          data-tooltip-place="right"
        />
        <Tooltip id="tooltip" style={{ zIndex: 1000, maxWidth: "50vw" }} />
      </div>
      {errors?.entity_type && (
        <span className="text-danger">{errors?.entity_type}</span>
      )}
    </div>
  );
};

export const Company = ({
  handleChange,
  handleBlur,
  errors,
  values,
  inputClassName = "form-control-sm",
}) => {
  if (values?.entity_type?.toUpperCase() !== "ENTITY") {
    return null;
  }

  return (
    <div className="form-group company-name">
      <label htmlFor="company">
        Legal Company Name <span className="text-danger">*</span>
      </label>
      <input
        id="company"
        name="company"
        value={values.company}
        onChange={handleChange}
        onBlur={handleBlur}
        type="text"
        className={clsx("form-control", inputClassName)}
      />
      {errors?.company && (
        <span className="text-danger">{errors?.company}</span>
      )}
    </div>
  );
};

const OpenOfferingUserInfo = ({ error = null }) => {
  const { offeringId, investmentId } = useParams();
  const history = useHistory();
  const [email, setEmail] = useState();
  const [displayMFA, setDisplayMFA] = useState(false);

  const { data: investment, refetch: reloadInvestment } =
    useCurrentInvestment();
  const investmentUpdate = useInvestmentUpdateStage(
    investmentId,
    "investor-info"
  );
  const registerUser = useRegister(offeringId);
  const updateUser = useUpdateCurrentUser({
    onSuccess: async (data) => {
      await investmentUpdate.mutateAsync({});
      const updatedInvestment = await reloadInvestment();

      const continueUrl = investmentContinueUrlOpenOffering(
        updatedInvestment.id,
        updatedInvestment.status,
        offeringId
      );

      history.replace(continueUrl);
    },
  });

  const createInvestment = useInvestmentCreate();
  const isLoading =
    registerUser.isLoading ||
    updateUser.isLoading ||
    investmentUpdate.isLoading;

  const { data: me } = useCurrentUser();

  const loginUrl = `/offering/${offeringId}/login`;

  const onSubmit = async (data) => {
    setEmail(data.email);
    try {
      if (me && investmentId) {
        await updateUser.mutateAsync({
          first_name: data.first_name,
          middle_name: data.middle_name || null,
          last_name: data.last_name,
          company: data.company || null,
          phone: data.phone || null,
          entity_type: data.entity_type.toUpperCase(),
        });
      } else {
        await registerUser.mutateAsync({
          first_name: data.first_name,
          middle_name: data.middle_name || null,
          last_name: data.last_name,
          email: data.email,
          phone: data.phone || null,
          company: data.company || null,
          entity_type: data.entity_type.toUpperCase(),
        });
        setDisplayMFA(true);
      }
    } catch (error) {
      toast.error(error.response.data.errors.join(". "));
    }
  };

  const onCreateInvestment = useCallback(async () => {
    if (!investmentId) {
      return createInvestment.mutateAsync(offeringId);
    }
  }, [createInvestment, history, investmentId, investment, offeringId]);

  const initialValues = useMemo(
    () => ({
      first_name: me?.first_name || "",
      middle_name: me?.middle_name || "",
      last_name: me?.last_name || "",
      company: me?.company || "",
      phone: me?.phone || "",
      entity_type: me?.entity_type ?? "INDIVIDUAL",
      email: me?.email || "",
    }),
    [me]
  );

  return (
    <div className="container get-started">
      <div className="row">
        <div className="col">
          <h2 className="text-md-start mb-0 mt-4">Let's Get Started</h2>
          <p>
            We just need some initial information to verify you. Please use your
            legal name, it will be used to generate contracts.
          </p>
          <Formik
            initialValues={initialValues}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={userSchema}
            enableReinitialize
            onSubmit={onSubmit}
          >
            {({
              values,
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              isValid,
            }) => (
              <form onSubmit={handleSubmit}>
                <AccountType
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  values={values}
                  errors={errors}
                />
                <Company
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  values={values}
                  errors={errors}
                />
                <div className="row">
                  <div className="col-12 col-sm-4">
                    <FirstName
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      errors={errors}
                      values={values}
                    />
                  </div>
                  <div className="col-12 col-sm-4">
                    <MiddleName
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      values={values}
                      errors={errors}
                    />
                  </div>
                  <div className="col-12 col-sm-4">
                    <LastName
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      errors={errors}
                      values={values}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-sm-6">
                    <Email
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      errors={errors}
                      values={values}
                    />
                  </div>
                  <div className="col-12 col-sm-6">
                    <Phone
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      values={values}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-start align-items-center align-content-center gap-3">
                  <button
                    className="btn btn-sm btn-eq-primary d-inline-flex gap-2 align-items-center"
                    disabled={isLoading || !isValid}
                    type="submit"
                  >
                    <span>Continue</span>
                  </button>
                  {isLoading && (
                    <div
                      className="spinner-grow text-secondary spinner-grow-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </div>
                <br />
                <div className="form-group login">
                  <Link to={loginUrl}>
                    Already have an account, click here to login
                  </Link>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
      <MFAModal
        email={email}
        display={displayMFA}
        onToggle={() => setDisplayMFA(false)}
        onComplete={onCreateInvestment}
      />
    </div>
  );
};

export default OpenOfferingUserInfo;

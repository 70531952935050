import { Formik } from "formik";
import jwtDecode from "jwt-decode";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import FormControl from "../../components/formControl";
import Spinner from "../../components/spinner";

import UserToken from "../../clients/token";
import { loginFormSchema } from "../../constants/forms";
import { sendMfa } from "../../helpers";

const formData = {
  email: "",
  password: "",
};

const Login = (props) => {
  document.title = "Issuer Sign In | EquiDeFi";

  const dispatch = useDispatch();
  const history = useHistory();
  const [spinner, showSpinner] = useState(false);

  useEffect(() => {
    const token = UserToken.token();

    let decoded;

    try {
      // Decode the token. Will fail if token is invalid
      decoded = jwtDecode(token);
    } catch (e) {
      UserToken.clear();
      return;
    }

    const expires = moment(decoded.exp * 1000);

    if (expires.isAfter(moment())) {
      history.push("/offering");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogin = (form) => {
    showSpinner(true);

    const request = {
      email: form.email.toLowerCase().trim(),
      password: form.password,
    };

    sendMfa(request)
      .then((_) => {
        dispatch({ type: "email", payload: request.email });
        showSpinner(false);
        props.history.push("/mfa");
      })
      .catch((_) => {
        toast.error("Incorrect email or password, please try again");
        showSpinner(false);
      });
  };

  return (
    <div className="container-fluid">
      <div className="row vh-100 justify-content-center">
        <div className="col-12 col-md-5 col-lg-6 col-xl-4 px-lg-6 my-5 align-self-center">
          <img
            src="/img/logo.svg"
            width="200px"
            alt="logo"
            className="d-block m-auto mb-5"
          />
          <h1 className="display-4 text-center mb-2">Sign In</h1>
          <p className="text-muted text-center mb-5">Issuer Portal</p>

          <Formik
            initialValues={formData}
            validationSchema={loginFormSchema}
            onSubmit={handleLogin}
            validateOnChange={false}
            validateOnBlur={false}
          >
            {({ handleChange, handleSubmit, values, errors }) => (
              <form noValidate onSubmit={handleSubmit}>
                <FormControl
                  type="email"
                  name="email"
                  title="Email Address"
                  required={true}
                  value={values.email}
                  error={errors.email}
                  onChange={handleChange}
                />

                <FormControl
                  type="password"
                  name="password"
                  title="Password"
                  required={true}
                  value={values.password}
                  error={errors.password}
                  onChange={handleChange}
                />

                <Spinner show={spinner}>
                  <button className="btn w-100 btn-eq-primary" type="submit">
                    Continue
                  </button>
                </Spinner>

                <div className="small text-center text-muted mt-3">
                  <Link to={"/forgot"} className="text-muted">
                    Forgot password?
                  </Link>
                </div>
              </form>
            )}
          </Formik>
        </div>
        <div className="col-12 col-md-7 col-lg-6 col-xl-8 d-none d-lg-block">
          <div
            className="bg-cover h-100 min-vh-100 mt-n1 me-n3"
            style={{ backgroundImage: "url(img/login.jpg)" }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Login;

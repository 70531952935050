/* eslint-disable react-hooks/exhaustive-deps */

const SettingsGroup = ({
  title,
  subtitle,
  settings,
  user,
  adjustValue = (value) => value,
  adjustName = (name) => name,
  onButtonClick = () => {},
  isEditable = false,
}) => {
  return (
    <div className="card">
      <div className="card-header">
        <h3 className="mb-0">{title}</h3>
      </div>
      <div className="table-responsive">
        <table className="table table-nowrap card-table">
          {subtitle && (
            <thead>
              <tr>
                <th className="col-6"> </th>
                <th className="col-5">{subtitle}</th>
                <th className="col-1"></th>
              </tr>
            </thead>
          )}
          <tbody>
            {settings?.map((item) => {
              return (
                <tr key={item.id}>
                  <td className="col-6 align-baseline">
                    {adjustName(item.name)}
                  </td>
                  <td className="col-5 text-start align-baseline">
                    {adjustValue(item.value)}
                  </td>
                  <td className="col-1 text-end align-baseline">
                    {isEditable && (
                      <button
                        className="btn btn-sm btn-light"
                        onClick={() => onButtonClick(item)}
                      >
                        <i className="fe fe-edit" />
                      </button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SettingsGroup;

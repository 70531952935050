import * as FullStory from "@fullstory/browser";
import { useIdleTimer } from "react-idle-timer";
import { useSelector } from "react-redux";
import { Link, Route, Switch, useParams } from "react-router-dom";

import Spinner from "../components/spinner";
import { isAdmin } from "../helpers";
import { useIssuer } from "../hooks/useIssuers";
import { useOffering } from "../hooks/useOfferings";
import issuerNav from "./../data/nav_issuer.json";
import offeringNav from "./../data/nav_offering.json";

const OfferingMenu = () => {
  const { id } = useParams();
  const { data: offering } = useOffering(id);

  if (!offering) {
    return <Spinner show />;
  }

  return (
    <>
      <hr className="navbar-divider my-3" />
      <h3 className="navbar-heading fw-bold text-dark fs-lg text-capitalize">
        {offering.name}
      </h3>
      <ul className="navbar-nav">
        {offeringNav.map((item, index) => {
          let navClass = window.location.pathname.includes(item.link)
            ? "active"
            : "";
          return (
            <li key={index} className="nav-item">
              <Link
                to={`/offering/${id}/${item.link}`}
                className={`nav-link ${navClass}`}
              >
                <i className={`fe fe-${item.icon}`} /> {item.name}
              </Link>
            </li>
          );
        })}
      </ul>
    </>
  );
};
const IssuerMenu = () => {
  const { id } = useParams();
  const { data: issuer } = useIssuer(id);

  if (!issuer) {
    return <Spinner show />;
  }

  return (
    <>
      <hr className="navbar-divider my-3" />
      <h6 className="navbar-heading">{issuer.name}</h6>
      <ul className="navbar-nav">
        {issuerNav.map((item, index) => {
          let navClass = window.location.pathname.includes(item.link)
            ? "active"
            : "";
          return (
            <li key={index} className="nav-item">
              <Link
                to={`/issuer/${issuer.id}/${item.link}`}
                className={`nav-link ${navClass}`}
              >
                <i className={`fe fe-${item.icon}`} /> {item.name}
              </Link>
            </li>
          );
        })}
      </ul>
    </>
  );
};

const Menu = (props) => {
  const user = useSelector((state) => state.user);

  const issuer_ref = useSelector((state) => state.issuer_ref);
  if (process.env.NODE_ENV === "production") {
    FullStory.identify(user.id, {
      displayName: `${user.first_name} ${user.last_name}`,
      email: user.email,
    });
  }

  const doLogout = async () => {
    localStorage.clear();
    window.location = "/";
  };

  const handleOnIdle = (event) => {
    localStorage.clear();
    window.location = "/";
  };

  useIdleTimer({
    timeout: 1000 * 60 * 120,
    onIdle: handleOnIdle,
    debounce: 500,
  });
  return (
    <>
      <nav
        className="navbar navbar-vertical fixed-start navbar-expand-md navbar-light"
        id="sidebar"
      >
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#sidebarCollapse"
            aria-controls="sidebarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <Link to="/offering" className="navbar-brand">
            <div className="text-center">
              <img src="/img/logo.svg" alt="logo" className="img-fluid" />
            </div>
          </Link>

          <div className="collapse navbar-collapse" id="sidebarCollapse">
            <ul className="navbar-nav">
              {isAdmin(user) && (
                <li className="nav-item">
                  <Link to="/brokers" className="nav-link">
                    <i className="fe fe-users" />
                    Brokers
                  </Link>
                </li>
              )}
              <li className="nav-item">
                <Link to="/offering" className="nav-link">
                  <i className="fe fe-home" /> Offerings
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/invoices" className="nav-link">
                  <i className="fe fe-file-text" /> Invoices
                </Link>
              </li>
              {user?.roles?.admin && (
                <li className="nav-item">
                  <Link to="/issuer" className="nav-link">
                    <i className="fe fe-dollar-sign" />
                    Issuers
                  </Link>
                </li>
              )}
            </ul>

            <Switch>
              <Route path="/offering/:id/**" component={OfferingMenu} />
              <Route path="/issuer/:id/**" component={IssuerMenu} />
            </Switch>

            <hr className="navbar-divider my-3" />

            <ul className="navbar-nav">
              <li className="nav-item">
                <Link to="/profile" className="nav-link">
                  <i className="fe fe fe-user" /> My Profile
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/" className="nav-link" onClick={() => doLogout()}>
                  <i className="fe fe-log-out" /> Logout
                </Link>
              </li>
            </ul>

            <div className="navbar-user d-none">
              <div className="dropup">
                <a
                  href="#!"
                  className="avatar avatar-sm dropdown-toggle"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img
                    src={"/img/default-user.png"}
                    alt="..."
                    className="avatar-img rounded-circle"
                  />
                </a>
                <div className="dropdown-menu dropdown-menu-right"></div>
              </div>
            </div>
          </div>
        </div>
      </nav>

      {user && !isAdmin(user) && (
        <nav className="header mb-0 main-content">
          <div className="container-fluid">
            <div className="header-body py-1">
              <div className="row align-items-center">
                <div className="col"></div>
                <div className="col-auto d-flex align-items-center px-1">
                  <div className="fw-bold">Hi {user.first_name} {user.last_name}!</div>
                  <img
                    src={
                      issuer_ref?.photo_url
                        ? issuer_ref?.photo_url
                        : "/img/company.png"
                    }
                    alt="logo"
                    className="issuer-logo-height px-3"
                  />
                </div>
              </div>
            </div>
          </div>
        </nav>
      )}
    </>
  );
};

export default Menu;

import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";

import BrokerClient from "../clients/BrokerClient";

/**
 * @param {import("@tanstack/react-query").UseQueryOptions} options
 */
export const useBrokerList = (options = {}) => {
  return useQuery(["brokers"], async () => BrokerClient.all(), options);
};

/**
 * @param {string} id
 * @param {import("@tanstack/react-query").UseQueryOptions} options
 */
export const useBroker = (id, options = {}) => {
  return useQuery(["brokers", id], async () => BrokerClient.get(id), {
    enabled: !!id,
    ...options,
  });
};

/**
 * @param {import("@tanstack/react-query").UseMutationOptions} options
 */
export const useBrokerCreate = (options = {}) => {
  return useMutation(({ data }) => BrokerClient.create(data), options);
};

/**
 * @param {string} id
 * @param {import("@tanstack/react-query").UseMutationOptions} options
 */
export const useBrokerUpdate = (id, options = {}) => {
  return useMutation(({ data }) => BrokerClient.update(id, data), options);
};

/**
 * TODO: Split API request into BrokerClient and separate react-select data structure
 */
export const searchBroker = async (q) => {
  const response = await axios.get(`/v2/brokers/search`, { params: { q } });
  return response.data.map((value) => ({
    label: `${value.first_name} ${value.last_name} (${value.crd_number})`,
    value: value,
  }));
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import AsyncSelect from "react-select/async";
import Spinner from "../../components/spinner";
import { isAdmin, isOfferingEditor, isOfferingOwner } from "../../helpers";
import { searchBroker } from "../../hooks/useBrokers";
import
  {
    addOfferingBroker,
    removeOfferingBroker,
    useOffering,
    useOfferingBrokers,
  } from "../../hooks/useOfferings";
import Header from "../../layouts/header";

const Brokers = () => {
  const { id } = useParams();
  const user = useSelector((state) => state.user);

  const { data: offering } = useOffering(id);
  const { data: brokers, isLoading: spinner, refetch } = useOfferingBrokers(id);
  const [selectedBroker, setSelectedBroker] = useState();

  const [modal, showModal] = useState(false);

  const handleDelete = async (broker) => {
    try {
      await removeOfferingBroker(id, broker.id);
      await refetch();
    } catch (error) {
      console.error(error);
    }
  };

  const canEdit = useMemo(
    () =>
      (isAdmin(user) ||
        isOfferingEditor(user, offering?.legacy_id) ||
        isOfferingOwner(user, offering?.legacy_id)) &&
      ["In Review", "Draft"].includes(offering?.status),
    [user, offering?.legacy_id, offering?.status]
  );

  return (
    <>
      <Header id={id} name="Offering Brokers" title={offering?.name}>
        {canEdit && (
          <button
            className="btn btn-eq-primary"
            onClick={() => {
              showModal(true);
            }}
          >
            Add Broker
          </button>
        )}
      </Header>
      <div className="container-fluid">
        <Spinner show={!offering ?? spinner}>
          <div className="card">
            <div className="table-responsive">
              <table className="table table-nowrap">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Company</th>
                    <th>CRD Number</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {brokers?.map((item) => {
                    return (
                      <tr key={item.id}>
                        <td className="d-flex flex-column align-baseline">
                          <div>
                            {item.first_name} {item.last_name}
                          </div>
                        </td>
                        <td className="align-baseline">
                          <div className="text-muted">{item.company}</div>
                        </td>
                        <td className="align-baseline">{item.crd_number}</td>
                        <td className="text-end align-baseline">
                          {canEdit && (
                            <button
                              className="btn btn-sm btn-light ms-2"
                              onClick={() => handleDelete(item)}
                            >
                              <i className="fe fe-trash" />
                            </button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </Spinner>

        <Modal
          show={modal}
          onHide={() => showModal(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          dialogClassName="my-modal"
        >
          <Modal.Body>
            <h1>Add Broker to Offering</h1>
            <AsyncSelect
              className="mt-2"
              type="select"
              name="type"
              title="Select a Broker"
              isSearchable={true}
              required={true}
              loadOptions={searchBroker}
              value={selectedBroker}
              onChange={setSelectedBroker}
            />
            <hr />
            <div className="mt-3">
              <button
                className="btn btn-eq-primary"
                type="submit"
                onClick={async () => {
                  try {
                    await addOfferingBroker(id, selectedBroker.value.id);
                    showModal(false);
                    setSelectedBroker(null);
                    await refetch();
                  } catch (error) {
                    console.log(error);
                  }
                }}
              >
                Save
              </button>
              <button
                className="btn btn-link ms-2"
                type="button"
                onClick={() => showModal(false)}
              >
                Cancel
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default Brokers;

import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";



import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import Routes from "./Routes";
import { CONFIG } from "./constants/config";

toast.configure();

const App = (props) => {
  const user = useSelector((state) => state.user);

  axios.defaults.baseURL = CONFIG.EQ_API_URL;
  axios.defaults.headers.common["Authorization"] = `Bearer ${user.token}`;

  return <Routes />;
};

export default App;

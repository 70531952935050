import axios from "axios";
import { Formik } from "formik";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import FormControl from "../../../components/formControl";
import Spinner from "../../../components/spinner";
import { updateOffering } from "../../../helpers";
import { useOffering } from "../../../hooks/useOfferings";

const WizardAddOns = (props) => {
  const history = useHistory();
  const [spinner, showSpinner] = useState(false);

  const { id } = useParams();
  const { data: offering, refetch } = useOffering(id);

  const handleSubmit = async (data) => {
    const selectedAddons = [];
    const prevAddons = [];
    showSpinner(true);

    getAddonData(data, selectedAddons); // fetching addons which is selected
    getAddonData(offering, prevAddons); // fetching previously selected addons

    await updateOffering(offering.id, data);

    if (!arrayCompare(selectedAddons, prevAddons)) {
      axios
        .post(`/offering/${offering.id}/AddOnRequest`, {
          add_on: selectedAddons,
        })
        .then((res) => {
          showSpinner(false);
        })
        .catch((err) => {
          showSpinner(false);
          toast.error("Something went wrong, please try again later.");
        });
    }
    await refetch();
    showSpinner(false);
    history.push(`/offering/${offering.id}/overview`);
  };

  function getAddonData(toggledItems, myArray) {
    const myAddons = Object.keys(toggledItems.subscription);
    if (myAddons) {
      myAddons.forEach((item) => {
        if (toggledItems.subscription[item] === true) {
          const stringUpdate =
            item.charAt(0).toUpperCase() + item.slice(1).split("_").join(" ");
          myArray.push(stringUpdate);
        }
      });
    }
  }

  function arrayCompare(_arr1, _arr2) {
    if (
      !Array.isArray(_arr1) ||
      !Array.isArray(_arr2) ||
      _arr1.length !== _arr2.length
    ) {
      return false;
    }

    // .concat() to not mutate arguments
    const arr1 = _arr1.concat().sort();
    const arr2 = _arr2.concat().sort();

    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }

    return true;
  }

  if (!offering) {
    return <Spinner show />;
  }

  return (
    <>
      <h2>Would you like to subscribe to additional services?</h2>
      <hr />
      <Spinner show={spinner}>
        <Formik
          enableReinitialize={true}
          initialValues={offering}
          onSubmit={handleSubmit}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ handleChange, handleSubmit, values }) => (
            <form noValidate onSubmit={handleSubmit}>
              <FormControl
                type="checkbox"
                name="subscription[investor_relations]"
                label="Investor Relations"
                value={values.subscription.investor_relations}
                onChange={handleChange}
              />
              <p className="text-muted w-75">
                We maintain strong relationships with investor relations and
                public relations firms that are seeking new clients and may be
                perfect for you. Helping you get the word out about your
                business or stock symbol is an integral part of what we do.
              </p>
              <hr />

              <FormControl
                type="checkbox"
                name="subscription[transfer_agent]"
                label="Transfer Agent"
                value={values.subscription.transfer_agent}
                onChange={handleChange}
              />
              <p className="text-muted w-75">
                We offer budget conscious public and private companies
                discounted rates from our SEC registered transfer agent. We
                oversee your cap table and handle virtual meetings, dividends,
                escrow and closings. A transfer agent is required for all
                publicly traded companies and most Reg A companies. We help
                transition private companies that plan to go public and list
                their shares on established markets, and work with crowdfund and
                blockchain-based trading platforms as well. EquiDeFi clients
                receive a 15% discount and, if migrating from another transfer
                agent, up to a $5,000 credit for certain termination costs
                incurred. We are experts in assisting investors with moving
                their Rule 144 and registered shares into brokerage accounts and
                are recognized by nearly all brokerage firms for our superior
                service
              </p>
              <hr />
              <FormControl
                type="checkbox"
                name="subscription[financial_EDGAR_printers]"
                label="Financial EDGAR Printers"
                value={values.subscription.financial_EDGAR_printers}
                onChange={handleChange}
              />
              <p className="text-muted w-75">
                Our referrals for EDGARizing and filing reports with the SEC,
                including registration statements, proxies, ownership, periodic
                and current reporting, allows users to more closely manage costs
                through fixed annual fees for all SEC filings.
              </p>
              <hr />

              <FormControl
                type="checkbox"
                name="subscription[business_loans]"
                label="Business Loans"
                value={values.subscription.business_loans}
                onChange={handleChange}
              />
              <p className="text-muted w-75">
                If you maintain an active issuer account on EquiDeFi.com, and
                even if you have not yet launched a private offering, you can
                receive business loan offers from independent non-bank lenders.
                Subscribing for introductions to short-term and long-term
                lenders is required.
              </p>
              <hr />
              <FormControl
                type="checkbox"
                name="subscription[accredited_investor_database]"
                label="Accredited Investor Database"
                value={values.subscription.accredited_investor_database}
                onChange={handleChange}
              />
              <p className="text-muted w-75">
                COMING SOON - With this new service you can “crowdfund” your
                private offering on our SaaS platform. Find retail accredited
                investors, home offices and private equity investors looking to
                participate in new deals. Screen for interests, size, industry,
                geography, and other metrics tailored to your offering. Under
                present rules, an accredited investor in our database can invest
                in deals for up to 90 days after we issue their credentials. We
                run KYC/AML checks and issue a non-fungible KYC token which
                authorizes their status. You will never pay any transaction
                based fee for our introductions.
              </p>
              <hr />

              <button className="btn btn-eq-primary" type="submit">
                Save &amp; Continue
              </button>
            </form>
          )}
        </Formik>
      </Spinner>
    </>
  );
};

export default WizardAddOns;

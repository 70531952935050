import React, { useState } from "react";

import clsx from "clsx";
import moment from "moment";
import { createPortal } from "react-dom";
import NumberFormat from "react-number-format";
import ManualPaymentModal from "./ManualPaymentModal";
import ManualRefundModal from "./ManualRefundModal";

const PAYMENT_BADGE_MAPS = {
  created: { bg: "bg-warning", text: "Payment Pending" },
  requires_action: { bg: "bg-warning", text: "Requires Action" },
  requires_payment_method: {
    bg: "bg-warning",
    text: "Requires Payment Method",
  },
  processing: { bg: "bg-warning", text: "Payment Processing" },
  succeeded: { bg: "bg-success", text: "Payment Successful" },
  manual_payment_succeeded: { bg: "bg-success", text: "Manual Payment" },
  manual_refund_succeeded: { bg: "bg-success", text: "Manual Refund" },
  payment_failed: { bg: "bg-danger", text: "Payment Failed" },
};

const InvestmentPayments = ({
  investment,
  payments,
  offering,
  canEdit = false,
  onUpdatePayments = () => {},
}) => {
  const [modalPayment, showPaymentModal] = useState(false);
  const [modalRefund, showRefundModal] = useState(false);

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h4 className="card-header-title">Payments</h4>

          {offering?.status === "Active" &&
            investment?.stripe_payments?.length === 0 &&
            canEdit && (
              <>
                <button
                  className="btn btn-sm btn-light"
                  onClick={() => showPaymentModal(true)}
                >
                  Add Manual Payment
                </button>
                <button
                  className="btn btn-sm btn-light ms-2"
                  onClick={() => showRefundModal(true)}
                >
                  Add Manual Refund
                </button>
              </>
            )}
        </div>
        <table className="table table-hover">
          <thead>
            <tr>
              <th>Payment Mode</th>
              <th className="text-center">Status</th>
              <th>Date</th>
              <th className="text-end">Amount</th>
            </tr>
          </thead>
          <tbody>
            {(investment?.stripe_payments || []).map((payment, index) => {
              const badgeConfig = PAYMENT_BADGE_MAPS[payment.status];
              return (
                <tr key={index}>
                  <td className="align-baseline">Stripe Payment</td>
                  <td className="text-center align-baseline">
                    {badgeConfig && (
                      <span className={clsx("badge", badgeConfig.bg)}>
                        {badgeConfig.text}
                      </span>
                    )}
                  </td>
                  <td className="align-baseline">
                    {payment.create_date &&
                      moment(payment.create_date).format(
                        "MMM DD, YYYY h:mm a ZZ"
                      )}
                  </td>
                  <td className="text-end align-baseline">
                    <NumberFormat
                      value={payment.amount}
                      displayType="text"
                      thousandSeparator
                      prefix="$"
                    />
                  </td>
                </tr>
              );
            })}
            {(payments || []).map((payment, index) => {
              const badgeConfig = PAYMENT_BADGE_MAPS[payment.status];
              return (
                <tr key={index}>
                  <td className="align-baseline">
                    {payment.payment_intent
                      ? "Stripe Payment"
                      : "Manual Payment"}
                  </td>
                  <td className="text-center align-baseline">
                    {badgeConfig && (
                      <span className={clsx("badge", badgeConfig.bg)}>
                        {badgeConfig.text}
                      </span>
                    )}
                  </td>
                  <td className="align-baseline">
                    {payment.create_date &&
                      moment(payment.create_date).format(
                        "MMM DD, YYYY h:mm a ZZ"
                      )}
                  </td>
                  <td className="text-end align-baseline">
                    <NumberFormat
                      value={payment.amount}
                      displayType="text"
                      thousandSeparator={true}
                      prefix="$"
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {createPortal(
        <ManualRefundModal
          isVisible={investment?.id && modalRefund}
          investmentId={investment?.id}
          onHide={() => showRefundModal(false)}
          onSubmit={() => onUpdatePayments?.()}
        />,
        document.body
      )}
      {createPortal(
        <ManualPaymentModal
          isVisible={investment?.id && modalPayment}
          investmentId={investment?.id}
          onHide={() => showPaymentModal(false)}
          onSubmit={() => onUpdatePayments?.()}
        />,
        document.body
      )}
    </>
  );
};

export default InvestmentPayments;

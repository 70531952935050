/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery } from "@tanstack/react-query";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import FormControl from "../../components/formControl";
import Spinner from "../../components/spinner";
import Header from "../../layouts/header";

import SecClient from "../../clients/sec.client";
import FloatingSearch from "../../components/floating-search";
import
  {
    createIssuer,
    getIssuerById,
    updateIssuer,
    upload,
  } from "../../helpers";
import useDebounce from "../../hooks/useDebounce";

import { issuerCreateSchema } from "../../constants/forms";
import states from "./../../data/states.json";

const acceptedImageTypes = ['image/jpeg', 'image/png'];

const data = {
  name: "",
  email: "",
  phone: "",
  linkedin_url: "",
  website_url: "",
  profile: "",
  cik: "",
  ein: "",
  sic: "",
  state_of_incorporation: "",
  doing_business_as: "",
  business_address: {},
  address: {
    line_1: "",
    city: "",
    state: "",
    country: "United States",
    postal_code: "",
  },
};

function paddedCikNumber(cik) {
  return `${cik}`.padStart(10, "0");
}

const useSecSearchQuery = (text) =>
  useQuery(
    ["sec-search", text],
    () => SecClient.search(text),

    { enabled: !!text }
  );

const useSecFindQuery = (text, options = {}) =>
  useQuery(
    ["sec-find", text],
    () => SecClient.find(text),

    { ...options }
  );

const SelectPublicIssuer = ({
  isHidden = false,
  onCancel = () => {},
  onSelectIssuer = (cik) => {},
}) => {
  const [inputVisible, setInputVisible] = useState(false);
  const [inputSearchValue, setSearchVal] = useState("");
  const searchVal = useDebounce(inputSearchValue, 500);

  const { data, fetchStatus, isSuccess } = useSecSearchQuery(searchVal);
  const { data: exactMatch } = useSecFindQuery(paddedCikNumber(searchVal), {
    enabled: !!(searchVal && data?.length === 0 && isSuccess),
  });

  if (isHidden) {
    return null;
  }

  return (
    <div className="row">
      <div className="col">
        <div className="mb-4">
          <h3>Are you a public company?</h3>
          <button
            type="button"
            className="btn btn-lg btn-eq-primary active me-3"
            aria-current="page"
            onClick={() => setInputVisible(true)}
          >
            Yes
          </button>
          <button
            type="button"
            onClick={onCancel}
            className="btn btn-lg btn-light"
          >
            No
          </button>
        </div>
        {inputVisible && (
          <FloatingSearch
            placeholder="Search by name, description, type..."
            id="sec-company-search"
            label="Search Public Companies"
            classNames="w-100"
            onChange={(e) => setSearchVal(e.target.value)}
          />
        )}

        <Spinner show={!isSuccess && fetchStatus === "fetching"}>
          {data?.length === 0 && isSuccess && exactMatch && (
            <ul className="list-group mt-3">
              <li className="list-group-item d-flex justify-content-between align-items-start mb-2">
                <div className="ms-2 me-auto">
                  <div className="fw-bold">{exactMatch.name}</div>
                  {/* {exactMatch.tickers}{" "} */}
                  <span className="fw-lighter fst-italic">
                    (<span className="fw-bold">CIK:</span>{" "}
                    {paddedCikNumber(exactMatch.cik)})
                  </span>
                </div>
                <button
                  type="button"
                  onClick={() => onSelectIssuer(exactMatch.cik)}
                  className="btn btn-eq-primary btn-sm"
                >
                  Select
                </button>
              </li>
            </ul>
          )}
          {data?.length > 0 && (
            <ul className="list-group mt-3">
              {data?.map((ticker, i) => (
                <li
                  key={`${ticker.cik_str}-${i}`}
                  className="list-group-item d-flex justify-content-between align-items-start mb-2"
                >
                  <div className="ms-2 me-auto">
                    <div className="fw-bold">{ticker.title}</div>
                    {ticker.ticker}{" "}
                    <span className="fw-lighter fst-italic">
                      (<span className="fw-bold">CIK:</span>{" "}
                      {paddedCikNumber(ticker.cik_str)})
                    </span>
                  </div>
                  <button
                    type="button"
                    onClick={() => onSelectIssuer(ticker.cik_str)}
                    className="btn btn-eq-primary btn-sm"
                  >
                    Select
                  </button>
                </li>
              ))}
            </ul>
          )}
        </Spinner>
      </div>
    </div>
  );
};

const IssuerEditor = (props) => {
  const history = useHistory();
  const { id } = useParams();
  const [issuer, setIssuer] = useState(data);

  const [showForm, setShowForm] = useState(() => !!id);
  const [fineSpinner, setFineSpinner] = useState(false);
  const [spinner, showSpinner] = useState(false);

  const createIssuerMutation = useMutation((_value) => createIssuer(_value));
  const updateIssuerMutation = useMutation((_value) =>
    updateIssuer(id, _value)
  );
  useEffect(() => {
    if (id) {
      showSpinner(true);
      getIssuerById(id).then((issuer) => {
        setIssuer(issuer);
        showSpinner(false);
      });
    }
  }, []);

  const handleOnSubmit = async (data) => {
    showSpinner(true);
    data.address.state = data?.address?.state?.label ?? data.address.state;
    if (id) {
      try {
        const issuer = await updateIssuerMutation.mutateAsync(data);
        toast.success(`Issuer '${data?.name}' has been updated`);
        history.push(`/issuer/${issuer?.id}/offerings`);
        showSpinner(false);
      } catch (err) {
        showSpinner(false);
        toast.error(err.response.data.message);
      }
    } else {
      try {
        const issuer = await createIssuerMutation.mutateAsync(data);
        toast.success(`Issuer '${data.name}' has been created`);
        history.push(`/issuer/${issuer?.id}/offerings`);
        showSpinner(false);
      } catch (err) {
        showSpinner(false);
        toast.error(err.response.data.message);
      }
    }
  };

  const prefillFormData = async (cik) => {
    const data = await SecClient.find(cik);
    const state = states.find(
      (state) => state.shortCode === data.addresses.business.stateOrCountry
    )?.value;

    setIssuer({
      name: data.name,
      phone: data.phone?.replace(/[^\d]*/g, "") ?? "",
      sec_phone: data.phone?.replace(/[^\d]*/g, "") ?? "",
      cik: paddedCikNumber(data.cik),
      ein: data.ein,
      sic: data.sic,
      state_of_incorporation: data.stateOfIncorporation,
      address: {
        line_1: data.addresses.business?.street1,
        line_2: data.addresses.business?.street2,
        postal_code: data.addresses.business?.zipCode,
        city: data.addresses.business.city,
        state,
      },
      business_address: {
        line_1: data.addresses.business?.street1,
        line_2: data.addresses.business?.street2,
        postal_code: data.addresses.business?.zipCode,
        city: data.addresses.business.city,
        state,
      },
    });

    setShowForm(true);
  };

  const handleImageUpload = (e, setFieldValue) => {
    const file = e.target.files[0]
    if(!acceptedImageTypes.includes(file.type)) return toast.error('File format not supported')
    setFineSpinner(true);
    let data = new FormData();
    data.append("file", file);
    upload(data)
      .then((responseData) => {
        setFieldValue("photo_url", responseData.url);
        setFineSpinner(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Header title="EquiDeFi" name={id ? "Edit Issuer" : "Create Issuer"} />
      <div className="container-fluid mb-5">
        <SelectPublicIssuer
          isHidden={showForm}
          onCancel={() => setShowForm(true)}
          onSelectIssuer={(cik) => prefillFormData(cik)}
        />
        {showForm && (
          <Formik
            enableReinitialize={true}
            initialValues={issuer}
            validationSchema={issuerCreateSchema}
            onSubmit={handleOnSubmit}
            validateOnChange={false}
            validateOnBlur={false}
          >
            {({
              handleChange,
              handleSubmit,
              values,
              errors,
              touched,
              isValid,
              dirty,
              handleBlur,
              setFieldValue,
            }) => {
              return (
                <form noValidate onSubmit={handleSubmit}>
                  <div className="row justify-content-between align-items-center form-group">
                    <div className="col">
                      <div className="d-flex flex-column align-items-start">
                        <div className="ms-n2">
                          <h4 className="mb-1">Your Company Logo</h4>
                        </div>

                        <div className="form-control">
                          <div className="row align-items-center">
                            <div className="d-flex gap-4 align-items-center flex-row justify-content-start">
                              <div className="py-2">
                                <div className="issuer-logo">
                                  <Spinner show={fineSpinner}>
                                    <img
                                      className="issuer-logo-height"
                                      src={
                                        values.photo_url
                                          ? values.photo_url
                                          : "/img/company.png"
                                      }
                                      alt="logo"
                                    />
                                  </Spinner>
                                </div>
                              </div>

                              <div className="form-input">
                                <input
                                  type="file"
                                  id="myfile"
                                  name="myfile"
                                  accept={acceptedImageTypes.join(',')}
                                  onChange={(e) =>
                                    handleImageUpload(e, setFieldValue)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <FormControl
                    type="text"
                    name="name"
                    title="Name"
                    placeholder="e.g. ABC Inc"
                    value={values.name}
                    error={errors.name}
                    required
                    onChange={handleChange}
                  />

                  <FormControl
                    type="email"
                    name="email"
                    title="Email"
                    placeholder="e.g. support@abc.com"
                    value={values.email}
                    error={errors.email}
                    required
                    onChange={handleChange}
                  />

                  <FormControl
                    type="phone"
                    name="phone"
                    title="Phone"
                    placeholder="eg: +1 (444) 444-4444"
                    value={values.phone}
                    error={errors.phone}
                    required
                    onChange={handleChange}
                  />

                  <FormControl
                    type="text"
                    name="website_url"
                    title="Website Url"
                    placeholder="eg: https://abcinc.com"
                    value={values.website_url}
                    error={errors.website_url}
                    required
                    onChange={handleChange}
                  />

                  <FormControl
                    type="text"
                    name="linkedin_url"
                    title="LinkedIn Url"
                    placeholder="e.g. https://linkedin.com/abc"
                    value={values.linkedin_url}
                    error={errors.linkedin_url}
                    required
                    onChange={handleChange}
                  />

                  <FormControl
                    type="textarea"
                    name="profile"
                    title="Profile"
                    value={values.profile}
                    error={errors.profile}
                    onChange={handleChange}
                  />

                  {values.cik && (
                    <>
                      <input
                        value={values.sec_phone}
                        name="sec_phone"
                        type="hidden"
                        onChange={handleChange}
                      />
                      <input
                        value={values.state_of_incorporation}
                        name="state_of_incorporation"
                        type="hidden"
                        onChange={handleChange}
                      />
                    </>
                  )}

                  {!id && (
                    <>
                      <hr />

                      <div className="row">
                        <div className="col">
                          <FormControl
                            type="text"
                            name="cik"
                            title="CIK"
                            placeholder="CIK number from SEC"
                            value={values.cik}
                            error={errors.cik}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col">
                          <FormControl
                            type="text"
                            name="ein"
                            title="EIN"
                            placeholder="EIN number from SEC"
                            value={values.ein}
                            error={errors.ein}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col">
                          <FormControl
                            type="text"
                            name="sic"
                            title="SIC"
                            placeholder="Standard Industrial Classification code from the SEC"
                            value={values.sic}
                            error={errors.sic}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  <div className="col">
                    <FormControl
                      type="text"
                      name="doing_business_as"
                      title="Doing Business As"
                      placeholder=""
                      value={values.doing_business_as}
                      error={errors.doing_business_as}
                      onChange={handleChange}
                    />
                  </div>

                  <hr />

                  <h2>Mailing Address</h2>

                  <FormControl
                    type="text"
                    name="address[line_1]"
                    title="Address"
                    placeholder="e.g. 100 Main St"
                    value={values.address?.line_1}
                    error={errors.address?.line_1}
                    onChange={handleChange}
                  />
                  <div className="row">
                    <div className="col">
                      <FormControl
                        type="text"
                        name="address[city]"
                        title="City"
                        placeholder="e.g. New York City"
                        value={values.address?.city}
                        error={errors.address?.city}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col">
                      <FormControl
                        type="select"
                        name="address[state]"
                        title="State"
                        options={states}
                        value={states.find((x) =>
                          [x.value, x.id, x.shortCode, x.label]
                            .map((v) => v.toLowerCase())
                            .includes(values?.address?.state?.toLowerCase())
                        )}
                        required={true}
                        error={errors?.address?.state}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col">
                      <FormControl
                        type="postalcode"
                        name="address[postal_code]"
                        title="Postal Code"
                        placeholder="e.g. 21044"
                        value={values.address?.postal_code}
                        error={errors.address?.postal_code}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <hr />

                  <h2>Business Address</h2>

                  <FormControl
                    type="text"
                    name="business_address[line_1]"
                    title="Address"
                    placeholder="e.g. 100 Main St"
                    value={values.business_address?.line_1}
                    error={errors.business_address?.line_1}
                    onChange={handleChange}
                  />
                  <div className="row">
                    <div className="col">
                      <FormControl
                        type="text"
                        name="business_address[city]"
                        title="City"
                        placeholder="e.g. New York City"
                        value={values.business_address?.city}
                        error={errors.business_address?.city}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col">
                      <FormControl
                        type="select"
                        name="business_address[state]"
                        title="State"
                        options={states}
                        value={states.find((x) =>
                          [x.value, x.id, x.shortCode, x.label]
                            .map((v) => v.toLowerCase())
                            .includes(
                              values?.business_address?.state?.toLowerCase()
                            )
                        )}
                        required
                        error={errors?.business_address?.state}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col">
                      <FormControl
                        type="postalcode"
                        name="business_address[postal_code]"
                        title="Postal Code"
                        placeholder="e.g. 21044"
                        value={values.business_address?.postal_code}
                        error={errors.business_address?.postal_code}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <hr />

                  <Spinner show={spinner}>
                    <div className="d-flex flex-row justify-content-start">
                      <button
                        disabled={!(isValid && dirty)}
                        className="btn btn-eq-primary me-2"
                        type="submit"
                      >
                        {id ? "Update" : "Create"}
                      </button>
                      <Link
                        to={id ? `/issuer/${id}/offerings` : "/issuer"}
                        className="btn btn-outline-secondary"
                      >
                        Cancel
                      </Link>
                    </div>
                  </Spinner>
                </form>
              );
            }}
          </Formik>
        )}
      </div>
    </>
  );
};

export default IssuerEditor;

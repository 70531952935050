import { useFormik } from "formik";
import React from "react";
import { FormCheck } from "react-bootstrap";
import * as Yup from "yup";

const DisqualifyingEventQuestionnaire = ({
  user,
  stage,
  issuerName,
  values = {},
  disabled = false,
  showButton = true,
  onChange = () => {},
  onContinue = () => {},
}) => {
  const formik = useFormik({
    initialValues: {
      bad_actor: undefined,
      ...values,
    },
    validationSchema: Yup.object({
      bad_actor: Yup.string().required(),
    }),
    onSubmit: (values) => {
      onContinue({
        bad_actor: values.bad_actor === "true",
      });
    },
    enableReinitialize: true,
  });

  const handleFormChange = (evt) => {
    onChange({ [evt.target.name]: evt.target.value });
    formik.handleChange(evt);
  };

  if (!user || stage !== "disqualifying") {
    return null;
  }

  return (
    <div className="mb-6">
      <form onSubmit={formik.handleSubmit}>
        <h2 className="mt-4">Rule 506(c) Disqualifying Event Questionnaire</h2>
        <p>
          This Questionnaire is being furnished to you to obtain information in
          connection with an offering (the “Offering”) of securities by{" "}
          {issuerName} (the “Company”) under Rule 506 of the Securities Act of
          1933 (the “Securities Act”). As used in this Rule 506 Disqualifying
          Event Questionnaire (this “Questionnaire”), “you” also refers to any
          entity on whose behalf you are responding. Important Note: Please
          answer every question. If the Company has completed portions of the
          Questionnaire on your behalf, please confirm the accuracy of that
          information. If your answer to a question is “Yes,” please provide a
          detailed explanation in the corresponding space provided. Unless
          otherwise stated, your answers should be given as of the date you sign
          the Questionnaire. Certain questions are necessarily broad in scope.
          If you have doubts regarding whether something should be included in
          your response please err on the side of over-inclusion. The Company
          may have additional questions following your submission of this
          Questionnaire in connection with the Offering. Once you have completed
          the Questionnaire, please sign it to indicate: (i) your consent for
          the Company to rely upon the information provided in this
          Questionnaire; (ii) your acknowledgement that the Securities and
          Exchange Commission (the “SEC”) may require the Company to publicly
          disclose the information provided in this Questionnaire, and your
          consent to such public disclosure; (iii) your agreement to promptly
          notify the Company of any changes in information provided in the
          Questionnaire occurring after the date you sign the Questionnaire; and
          (iv) your confirmation that the information contained in the
          Questionnaire is true and correct, to the best of your knowledge and
          belief after a reasonable investigation, as of the date you sign the
          Questionnaire.
        </p>

        <p>
          THE EXISTENCE AND CONTENTS OF THE QUESTIONNAIRE, AS WELL AS YOUR
          ANSWERS AND ALL NOTES AND DRAFTS PREPARED BY YOU, ARE CONSIDERED
          CONFIDENTIAL AND PROPRIETARY BY THE COMPANY AND SHOULD BE TREATED
          ACCORDINGLY. THE COMPANY MAY DISCLOSE THE CONTENTS OF THIS
          QUESTIONNAIRE TO THE EXTENT REQUIRED BY APPLICABLE LAW, AS REASONABLY
          REQUIRED TO ESTABLISH AN EXEMPTION FROM STATE OR FEDERAL REGISTRATION
          REQUIREMENTS FOR THE OFFERING OR AS REQUIRED BY THE SEC.
        </p>

        <p>
          <strong>
            This Questionnaire must be completed by the following persons:
          </strong>
        </p>

        <ul>
          <li>The Company</li>
          <li>Any predecessor of the Company or affiliated Company;</li>
          <li>
            Any director, executive officer, other officer participating in the
            Offering
          </li>
          <li>
            Any beneficial owner of 20% or more of the Company&apos;s
            outstanding voting equity securities, calculated on the basis of
            voting power
          </li>
          <li>
            Any promoter connected with the Company in any capacity at the time
            of the sale of securities
          </li>
          <li>
            Any person that has been or will be paid (directly or indirectly)
            remuneration for solicitation of purchasers in connection with sales
            of securities in the Offering (a “compensated solicitor”); and any
            director, executive officer, other officer participating in the
            Offering, general partner or managing member of any such compensated
            solicitor
          </li>
        </ul>

        <p>
          <strong>
            <em>
              At the end of the questionnaire please select “Yes” if one of the
              provided statements is accurate. Please select “No” if none of the
              provided statements are accurate.”
            </em>
          </strong>
        </p>

        <p>
          <strong>
            Have you been convicted, at any time within the past ten years, of
            any felony or misdemeanor?
          </strong>
        </p>
        <ul>
          <li>in connection with the purchase or sale of any security</li>
          <li>involving the making of any false filing with the SEC; or</li>
          <li>
            arising out of the conduct of the business of an underwriter,
            broker, dealer, municipal securities dealer, investment advisor or
            paid solicitor of purchasers of securities?
          </li>
        </ul>

        <p>
          <strong>
            Are you subject to any order, judgment or decree of any court of
            competent jurisdiction, entered at any time within the past five
            years, that restrains or enjoins you from engaging or continuing to
            engage in any conduct or practice?
          </strong>
        </p>

        <ul>
          <li>in connection with the purchase or sale of any security</li>
          <li>involving the making of any false filing with the SEC; or</li>
          <li>
            arising out of the conduct of the business of an underwriter,
            broker, dealer, municipal securities dealer, investment advisor or
            paid solicitor of purchasers of securities?
          </li>
        </ul>

        <p>
          <strong>
            Are you subject to a final order of a state securities commission
            (or an agency or officer of a state performing like functions), a
            state authority that supervises or examines banks, savings
            associations, or credit unions, a state insurance commission (or an
            agency or officer of a state performing like functions), an
            appropriate federal banking agency, the Commodity Futures Trading
            Commission (“CFTC”), or the National Credit Union Administration
            (“NCUA”) that:
          </strong>
        </p>

        <ul>
          <li>bars you from:</li>
          <li>
            association with an entity regulated by such commission, authority,
            agency or officer;
          </li>
          <li>
            engaging in the business of securities, insurance or banking; or
          </li>
          <li>
            engaging in savings association or credit union activities; or
          </li>
          <li>
            constitutes a final order based on a violation of any law or
            regulation that prohibits fraudulent, manipulative, or deceptive
            conduct entered at any time within the past ten years?
          </li>
        </ul>

        <p>
          <strong>
            Are you subject to an order of the SEC entered pursuant to section
            15(b) or 15B(c) of the Securities Exchange Act of 1934 (the
            “Exchange Act”) or section 203(e) or 203(f) of the Investment
            Advisers Act of 1940 (the “Advisers Act”) that:
          </strong>
        </p>

        <ul>
          <li>
            suspends or revokes your registration as a broker, dealer, municipal
            securities dealer or investment adviser
          </li>
          <li>
            places limitations on your activities, functions or operations; or
          </li>
          <li>
            bars you from being associated with any entity or from participating
            in the offering of any penny stock?
          </li>
        </ul>

        <p>
          <strong>
            Are you subject to any order of the SEC, entered at any time within
            the past five years, that orders you to cease and desist from
            committing or causing a future violation of:
          </strong>
        </p>

        <ul>
          <li>
            any scienter-based anti-fraud provision of the federal securities
            laws, including, but not limited to, Section 17(a) of the Securities
            Act, Section 10(b) of the Exchange Act and Rule 10b-5 thereunder,
            Section 15(c) of the Exchange Act and Section 206 of the Advisers
            Act, or any other rule or regulation thereunder; or
          </li>

          <li>Section 5 of the Securities Act?</li>
        </ul>

        <p>
          Have you been suspended or expelled from membership in, or suspended
          or barred from association with a member of, a registered national
          securities exchange or a registered national or affiliated securities
          association for any act or omission to act constituting conduct
          inconsistent with just and equitable principles of trade?
        </p>
        <p>
          Have you filed (as a registrant or issuer), or were you named as an
          underwriter in any registration statement or Regulation A offering
          statement filed with the SEC that, at any time within the past five
          years, was the subject of a refusal order, stop order, or order
          suspending the Regulation A exemption, or are you the subject of an
          investigation or proceeding to determine whether a stop order or
          suspension order should be issued?
        </p>
        <p>
          Are you subject to a United States Postal Service false representation
          order entered at any time within the past five years, or are you
          subject to a temporary restraining order or preliminary injunction
          with respect to conduct alleged by the United States Postal Service to
          constitute a scheme or device for obtaining money or property through
          the mail by means of false representations?
        </p>
        <p>
          Are you a member of or regulated by any financial industry regulatory
          organization, including, but not limited to, a state insurance
          commission (or an agency or officer of a state performing like
          functions), the CFTC, the NCUA, the Financial Industry Regulatory
          Authority (FINRA), the Financial Accounting Standards Board (FASB),
          the Federal Deposit Insurance Company (FDIC), Federal Reserve System
          or any state bar or professional licensing authority?
        </p>
        <p>
          <strong>
            Please select “Yes” if one of the provided statements is accurate.
            Please select “No” if none of the provided statements are accurate.”
          </strong>
        </p>

        <div className="inline-radio mb-3">
          <FormCheck
            inline
            type="radio"
            onChange={handleFormChange}
            disabled={disabled}
            aria-disabled={disabled}
            label="Yes"
            name="bad_actor"
            value={true}
            checked={`${formik.values.bad_actor}` === "true"}
            id="bad_actor_true"
          />
          <FormCheck
            inline
            type="radio"
            onChange={handleFormChange}
            disabled={disabled}
            aria-disabled={disabled}
            label="No"
            name="bad_actor"
            value={false}
            checked={`${formik.values.bad_actor}` === "false"}
            id="bad_actor_false"
          />
        </div>

        {showButton && (
          <button
            type="submit"
            className="btn btn-sm btn-eq-primary"
            disabled={!formik.dirty ? true : !formik.isValid}
          >
            Continue <i className="fe fe-arrow-right-circle ms-1"></i>
          </button>
        )}
      </form>
    </div>
  );
};

export default DisqualifyingEventQuestionnaire;

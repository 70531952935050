import React from 'react'

const Spinner = ({ show, children }) => {
  return (
    <>
      {show ? (
        // <div
        //   className="d-flex align-items-center justify-content-center"
        //   style={{ height: "70vh" }}
        // >
        <div className="text-center p-4 " style={{ height: "auto" }}>
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        // </div>
        <>{children}</>
      )}
    </>
  );
};

export default Spinner;

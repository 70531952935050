export const DRAFT = "Draft";
export const INVITE_SENT = "Invite Sent";
export const QUESTIONNAIRE_PENDING = 'Questionnaire Pending';
export const QUESTIONNAIRE_APPROVED = 'Questionnaire Approved';
export const QUESTIONNAIRE_REJECTED = 'Questionnaire Rejected';
export const ACCREDITATION_PENDING = "Accreditation Pending";
export const ACCREDITATION_SUBMITTED = "Accreditation Submitted";
export const ACCREDITATION_APPROVED = "Accreditation Approved";
export const ACCREDITATION_REJECTED = "Accreditation Rejected";
export const ENTER_PASSWORD = "Enter Password";
export const COMPLETE_PROFILE = "Complete Profile";
export const INVESTOR_PENDING = "Investor Pending";
export const INVESTOR_REVIEW = "Investor Review";
export const INVESTOR_APPROVED = "Investor Approved";
export const INVESTOR_NAME_MISMATCH = "Investor Name Mismatch";
export const INVESTOR_REJECTED = "Investor Rejected";
export const RECON_PENDING = "Recon Pending";
export const AGREEMENT_PENDING = "Agreement Pending";
export const AGREEMENT_SIGNED = "Agreement Signed";
export const PAYMENT_PENDING = "Payment Pending";
export const REFUND_PENDING = "Refund Pending";
export const PAYMENT_SUCCESSFUL = "Payment Successful";
export const PAYMENT_PROCESSING = "Payment Processing";
export const PAYMENT_FAILED = "Payment Failed";
export const ACCEPTED = "Accepted";
export const COMPLETED = "Completed";
export const CLOSED = "Closed";

/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import FileSaver from "file-saver";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import NumberFormat from "react-number-format";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import Swal from "sweetalert";

import { QUESTIONNAIRE_PENDING, QUESTIONNAIRE_REJECTED } from "@equidefi/shared/constants/investments";
import InfoCard from "../../../components/card";
import AccreditationReviewModal from "../../../components/investments/AccreditationReviewModal";
import Agreements from "../../../components/investments/Agreements/Agreements";
import InvestmentAccreditation from "../../../components/investments/InvestmentAccreditation/InvestmentAccreditation";
import InvestmentInquiry from "../../../components/investments/InvestmentInquiry";
import InvestmentPayments from "../../../components/investments/InvestmentPayments";
import Spinner from "../../../components/spinner";
import
  {
    IconControl,
    getInvestmentById,
    getSettingById,
    getSettingsByOfferingId,
    isAdmin,
    isIssuerEditor,
    isIssuerOwner,
    isOfferingViewer,
  } from "../../../helpers";
import { useInvestment } from "../../../hooks/investments";
import { useOffering, useOfferingSettings } from "../../../hooks/useOfferings";
import Header from "../../../layouts/header";

const noResendMail = ["Accepted", "Completed", "Closed"];
const editInvestmentAmount = ["Payment Successful", "Agreement Signed"];

const InvestmentOverview = (props) => {
  const { id, investment_id } = useParams();
  const { data: offering } = useOffering(id);
  const { data: offeringSettings } = useOfferingSettings(id);

  const user = useSelector((state) => state.user);
  const componentRef = useRef();

  const { data: investmentData, refetch: refetchInvestment } =
    useInvestment(investment_id);
  const [investment, setInvestment] = useState({});
  const [spinner, showSpinner] = useState(false);
  const [modalInvestorReview, showInvestorReviewModal] = useState(false);
  const [modalQuestionareReview, showQuestionareReviewModal] =
    useState(false);
  const [modalInvestmentEditModal, showInvestmentEditModal] = useState(false);
  const [statusAgreements, setAgreements] = useState();

  const [editInvestment, setEditInvestment] = useState(false);
  const [reviewDescription, setReviewDescription] = useState("");
  const [investmentAmount, setInvestmentAmount] = useState();
  const [close, setClose] = useState();

  useEffect(() => {
    getInvestment();
  }, []);

  useEffect(() => {
    if (investment) {
      setInvestmentAmount(investment.approved_amount || investment.amount);
    }
  }, [investment]);

  useEffect(() => {
    if (investment && modalInvestmentEditModal === false) {
      setInvestmentAmount(investment.approved_amount || investment.amount);
    }
    if (investment && investment?.agreements) {
      loadAgreementRecipients();
    }
  }, [investment, modalInvestmentEditModal]);

  // Download Questionnaire
  const handlePdfDownload = () => {
    showSpinner(true);
    axios.get(`investment/${investment_id}/questionnaire`).then((response) => {
      let toBytes = new Uint8Array(response.data.data),
        toBinary = ``;
      toBytes.forEach((res) => (toBinary += String.fromCharCode(res)));
      const file = window.btoa(toBinary);
      FileSaver.saveAs(
        `data:application/pdf;base64,${file}`,
        "investor_questionnaire.pdf"
      );
      showSpinner(false);
    });
  };

  // Print Questionnaire
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const paid = useMemo(() => {
    let value = 0;
    investment?.payments?.forEach((payment) => {
      if (payment.status.includes("succeeded")) value += payment.amount;
    });
    return value;
  }, [investment]);

  const balance = useMemo(() => {
    if (!investment) return 0;

    if (investment?.approved_amount) {
      return investment?.approved_amount - paid;
    }

    return investment?.amount - paid;
  }, [investment, paid]);

  const isAuthorizedSignatory = useMemo(() => {
    return (
      getSettingById(offeringSettings ?? [], "authorised_signatory_email") ===
      user.email
    );
  }, [offeringSettings, user?.email]);

  const getInvestment = () => {
    getInvestmentById(investment_id).then((investment) => {
      setInvestment(investment);
      showSpinner(false);
    });
  };

  const handleInvestorApprove = () => {
    showSpinner(true);

    let data = {
      description: reviewDescription,
    };

    axios
      .post(`/investment/${investment.id}/status/investor_approved`, data)
      .then((response) => {
        getInvestment();
        showSpinner(false);
        showInvestorReviewModal(false);
        toast.success("Investor has been Approved");
      });
  };

  const handleInvestorReject = () => {
    showSpinner(true);

    let data = {
      description: reviewDescription,
    };

    axios
      .post(`/investment/${investment.id}/status/investor_rejected`, data)
      .then((response) => {
        getInvestment();
        showSpinner(false);
        showInvestorReviewModal(false);
        toast.success("Investor has been Rejected");
      });
  };

  const downloadAgreement = (agreement) => {
    showSpinner(true);
    axios
      .get(`investment/${investment.id}/agreement/${agreement.envelope_id}`)
      .then((response) => {
        let toBytes = new Uint8Array(response.data.data),
          toBinary = ``;
        toBytes.forEach((res) => (toBinary += String.fromCharCode(res)));
        const file = window.btoa(toBinary);
        FileSaver.saveAs(
          `data:application/pdf;base64,${file}`,
          `${agreement.name.replace(/[^a-zA-Z ]/g, "")}}`
        );
        showSpinner(false);
      });
  };

  const loadAgreementRecipients = () => {
    if (investment?.agreements) {
      let count = 0;
      axios
        .get(
          `investment/${investment.id}/agreement/${investment?.agreements[0]?.envelope_id}/recipients`
        )
        .then((res) => {
          setAgreements(res.data);
          res.data?.forEach((DocuSign, index) => {
            if (DocuSign?.status === "completed") {
              count++;
            }
          });
          count === res.data.length ? setClose(true) : setClose(false);
        });
    }
  };

  const handleResend = () => {
    showSpinner(true);
    axios.post(`/investment/${investment.id}/notification`).then((response) => {
      toast.success("Email has been sent again");
      showSpinner(false);
    });
  };

  const handleAcceptAll = () => {
    Swal({
      title: `Accept Investments?`,
      text: `You will be redirected to DocuSign to countersign the pending investments, do you want to continue?`,
      buttons: ["Cancel", "Yes"],
      icon: "success",
      dangerMode: true,
    }).then((status) => {
      if (status) {
        showSpinner(true);
        let thread = 0;
        const agreement = investment.agreements?.filter(
          (x) =>
            x.investor_status === "complete" && x.issuer_status !== "complete"
        );
        if (agreement?.length > 0) {
          thread++;
          axios
            .get(
              `investment/${investment.id}/agreement/${agreement[0].envelope_id}/url/issuer`
            )
            .then((response) => {
              window.open(response.data.url, "_blank");
              thread--;
              if (thread === 0) showSpinner(false);
            })
            .catch((error) => {
              console.log(error);
              thread--;
              if (thread === 0) showSpinner(false);
            });
        }
      }
    });
  };

  const downloadClosingDocument = async () => {
    const response = await axios.get(`investment/${investment_id}/closing`);

    const toBytes = new Uint8Array(response.data.data);
    const toBinary = toBytes.reduce(
      (memo, res) => (memo += String.fromCharCode(res)),
      ``
    );
    const file = window.btoa(toBinary);

    FileSaver.saveAs(
      `data:application/pdf;base64,${file}`,
      "transfer_agent_letter.pdf"
    );
  };

  const handleCloseAll = () => {
    Swal({
      title: `Close Investments?`,
      text: `The closing letter for all accepted investors will be sent to the Transer Agent for closing, do you want to continue?`,
      buttons: ["Cancel", "Yes"],
      icon: "success",
      dangerMode: true,
    }).then((status) => {
      if (status) {
        showSpinner(true);
        const total = investmentAmount;
        getSettingsByOfferingId(id).then((settings) => {
          const bankType = getSettingById(settings, "payment_bank_type");
          if (
            bankType.toLowerCase() === "escrow" ||
            bankType.toLowerCase() === "escrow est"
          ) {
            toast.success(
              `The funds in the amount of $${total.toLocaleString()} will be wired to your bank account within 24 hours.`
            );
          } else {
            toast.success(`Investment closed successfully.`);
          }
        });
        const body = [investment.id];
        axios.post(`investment/close`, body).then((response) => {
          investment.status = "Completed";
          getInvestment();
          showSpinner(false);
        });
      }
    });
  };

  const reverse = (item) => {
    return [...item].reverse();
  };

  const handleEdit = (value) => {
    setInvestmentAmount(value);
  };

  const saveEditedAmount = async () => {
    investment.approved_amount = investmentAmount;
    investment.approved_quantity = investmentAmount / offering.unit_price;
    investment.edited = true;
    showSpinner(true);
    showInvestmentEditModal(false);
    if (investment.edited)
      await axios.post(
        `investment/${investment.id}/approved_quantity/${investment.approved_quantity}`
      );
    else if (investment.approved_quantity === undefined)
      await axios.post(
        `investment/${investment.id}/approved_quantity/${investment.quantity}`
      );
    setEditInvestment(false);
    showSpinner(false);
  };

  const canEdit = isAdmin(user) || !isOfferingViewer(user, offering?.legacy_id);

  return (
    <>
      <Header
        id={id}
        title={investment.offering?.name}
        name={investment.investor?.name}
      >
        {/* {investment?.status === "Accreditation Pending" && canEdit && (
          <ApproveAccreditationAction investment={investment} />
        )} */}
        {investment?.status === "Investor Review" && canEdit && (
          <button
            className="btn btn-eq-primary"
            onClick={() => showInvestorReviewModal(true)}
          >
            Review Investor
          </button>
        )}
        {(investment?.status === QUESTIONNAIRE_PENDING ||
          investment?.status === QUESTIONNAIRE_REJECTED) &&
          canEdit && (
            <button
              className="btn btn-eq-primary"
              onClick={() => {
                showQuestionareReviewModal(true);
              }}
            >
              Review Accreditation
            </button>
          )}
        {investment?.status === "Payment Successful" && canEdit && (
          <button
            className="btn btn-eq-primary"
            onClick={() => handleAcceptAll()}
            disabled={!isAuthorizedSignatory}
          >
            Accept
          </button>
        )}
        {investment?.status === "Accepted" && canEdit && (
          <>
            <div>
              {!close ? (
                <IconControl
                  placement="left"
                  button="true"
                  buttonName="Close"
                  tooltipTitle="Investment can be closed only when all parties have signed the agreement"
                />
              ) : (
                <button
                  className={"btn btn-eq-primary"}
                  onClick={() => handleCloseAll()}
                >
                  Close
                </button>
              )}
            </div>
          </>
        )}
      </Header>
      <div className="container-fluid">
        <Spinner show={spinner}>
          <div className="row">
            {canEdit && <InfoCard title="Status">{investment.status}</InfoCard>}
            <InfoCard title="Total Subscription">
              {investment?.approved_quantity &&
              investment?.approved_quantity !== investment?.quantity ? (
                <>
                  <span className="me-2">
                    <NumberFormat
                      value={investment.approved_quantity}
                      displayType="text"
                      thousandSeparator={true}
                      decimalScale={0}
                    />
                  </span>
                  <del className="small text-muted">
                    <NumberFormat
                      value={investment.quantity}
                      displayType="text"
                      thousandSeparator={true}
                      decimalScale={0}
                    />
                  </del>
                </>
              ) : (
                <NumberFormat
                  value={investment.quantity}
                  displayType="text"
                  thousandSeparator={true}
                  decimalScale={0}
                />
              )}
            </InfoCard>
            <InfoCard
              title="Investment Amount"
              {...(isAuthorizedSignatory &&
              editInvestmentAmount.includes(investment.status) &&
              (isIssuerOwner(user, investmentData?.issuer.id) ||
                isAdmin(user) ||
                isIssuerEditor(user, investmentData?.issuer.id))
                ? { editable: true }
                : { editable: false })}
              onClick={() => showInvestmentEditModal(true)}
            >
              {investment?.approved_amount &&
              investment?.amount !== investment?.approved_amount &&
              !editInvestment ? (
                <>
                  <div className="d-flex justify-content-between">
                    <div>
                      <span className="me-2">
                        <NumberFormat
                          value={investment.approved_amount}
                          displayType="text"
                          thousandSeparator={true}
                          decimalScale={0}
                          prefix="$"
                        />
                      </span>
                      <del className="small text-muted">
                        <NumberFormat
                          value={investment.amount}
                          displayType="text"
                          thousandSeparator={true}
                          decimalScale={0}
                          prefix="$"
                        />
                      </del>
                    </div>
                  </div>
                </>
              ) : (
                <div className="d-flex justify-content-between">
                  <NumberFormat
                    value={investment.amount}
                    displayType="text"
                    thousandSeparator={true}
                    decimalScale={0}
                    prefix="$"
                  />
                </div>
              )}
            </InfoCard>
            <InfoCard title="Total Payments">
              <NumberFormat
                displayType="text"
                thousandSeparator={true}
                value={paid}
                prefix="$"
              />
            </InfoCard>
            <InfoCard title="Total Due">
              <NumberFormat
                displayType="text"
                thousandSeparator={true}
                value={balance}
                prefix="$"
              />
            </InfoCard>
          </div>

          <div className="row">
            <div className="col">
              <Agreements
                offering={offering}
                investment={investment}
                statusagreements={statusAgreements}
                onDownloadAgreement={(a) => downloadAgreement(a)}
              />

              <InvestmentPayments
                canEdit={canEdit}
                investment={investmentData}
                offering={offering}
                payments={reverse(investmentData?.payments ?? [])}
                onUpdatePayments={refetchInvestment}
              />

              <InvestmentInquiry />
              <InvestmentAccreditation investment={investmentData} />

              {investmentData?.question_response && (
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-header-title">
                      Investor Questionnaire
                    </h4>
                    <button
                      className="btn btn-sm btn-light"
                      onClick={() => showInvestorReviewModal(true)}
                    >
                      View
                    </button>
                  </div>
                </div>
              )}

              {(isAdmin(user) && investment?.status === "Closed") || (
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-header-title">
                      Download TAIL
                      <span className="badge bg-info ms-2 mb-1 text-uppercase">
                        Admin Only
                      </span>
                    </h4>
                    <span></span>
                    <button
                      className="btn btn-sm btn-light"
                      onClick={() => downloadClosingDocument()}
                    >
                      View
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className="col-4">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-header-title">Recent Activity</h4>
                  {offering?.status === "Active" &&
                    !noResendMail?.includes(investment?.status) &&
                    canEdit && (
                      <button
                        className="btn btn-sm btn-light resend-mail"
                        onClick={() => handleResend()}
                      >
                        <IconControl
                          placement="top"
                          iconName="fe fe-mail"
                          tooltipTitle="Resend Email"
                        />
                      </button>
                    )}
                </div>
                <div className="card-body p-0 px-4">
                  <div className="list-group list-group-flush">
                    {reverse(investment?.status_history || [])?.map(
                      (status, index) => {
                        return (
                          <div className="list-group-item" key={index}>
                            {status?.status}
                            <div className="text-muted">
                              {status?.description}
                            </div>
                            <div className="row small text-muted">
                              <div className="col">
                                {status?.user?.name || "EquiDeFi Admin"}
                              </div>
                              <div className="col-auto">
                                {" "}
                                {moment(status.create_date).format("lll")}
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Spinner>
      </div>

      <AccreditationReviewModal
        investment={investment}
        show={modalQuestionareReview}
        onHide={() => showQuestionareReviewModal(false)}
        onApprove={() => {
          getInvestment();
          showQuestionareReviewModal(false);
        }}
        onReject={() => {
          getInvestment();
          showQuestionareReviewModal(false);
        }}
      />

      <Modal
        centered
        show={modalInvestmentEditModal}
        onHide={() => showInvestmentEditModal(false)}
      >
        <Modal.Header>Edit Investment Amount</Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <NumberFormat
              className="form-control"
              allowNegative={false}
              thousandSeparator={true}
              prefix="$"
              decimalScale={0}
              value={investmentAmount}
              onValueChange={({ value }) => handleEdit(value)}
              isAllowed={({ floatValue }) => {
                if (!floatValue) {
                  return true;
                }
                return floatValue <= investment.amount;
              }}
            />
          </div>
          <button className="btn btn-eq-primary" onClick={saveEditedAmount}>
            Save
          </button>
          <button
            className="btn btn-light ms-3"
            onClick={() => {
              showInvestmentEditModal(false);
            }}
          >
            Cancel
          </button>
        </Modal.Body>
      </Modal>

      <Modal
        centered
        size="xl"
        show={modalInvestorReview}
        onHide={() => showInvestorReviewModal(false)}
      >
        <Modal.Header closeButton>
          <div className="d-flex justify-content-between align-items-center w-100">
            <span>Review Investor Questionnaire</span>{" "}
            {investment?.status !== "Invite Sent" && (
              <>
                <div>
                  {spinner ? (
                    <button className="btn btn-eq-primary me-3 ">
                      <span className="loading">Downloading...</span>
                    </button>
                  ) : (
                    <button
                      className="btn btn-eq-primary me-3 "
                      onClick={handlePdfDownload}
                    >
                      Download
                    </button>
                  )}
                  <button className="btn btn-eq-primary" onClick={handlePrint}>
                    Print
                  </button>
                </div>
              </>
            )}
          </div>
        </Modal.Header>
        <Modal.Body>
          <div ref={componentRef}>
            {investment.question_response &&
              offering?.questionnaire.map((section, index) => {
                return (
                  <div key={index}>
                    <h2 className="mb-0">
                      {section.title !== "Congrats! Almost finished!"
                        ? section.title
                        : ""}
                    </h2>
                    <h3 className="mb-0">{section.subtitle}</h3>
                    <div dangerouslySetInnerHTML={{ __html: section.text }} />
                    {section.questions.map((question, qIndex) => {
                      return (
                        <div key={qIndex}>
                          {investment.question_response[question.id] && (
                            <label>
                              {investment.question_response
                                .entity_individual === "entity" &&
                              question.id === "investor_name"
                                ? "Name of Investor"
                                : question.text}
                            </label>
                          )}

                          {question.type === "radio" && (
                            <>
                              {question.options?.map((option, index) => {
                                return (
                                  <div key={index}>
                                    <div className="form-check mb-2">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        defaultChecked={
                                          investment.question_response[
                                            question.id
                                          ] === option.value
                                        }
                                        disabled
                                      />
                                      <label className="form-check-label ms-2">
                                        {option.text}
                                      </label>
                                    </div>
                                  </div>
                                );
                              })}
                            </>
                          )}

                          {question.type === "radio" &&
                            question.id !== "entity_individual" &&
                            investment.question_response[question.id] ===
                              "yes" && (
                              <>
                                <div>
                                  <input
                                    type="text"
                                    name={question?.id}
                                    id={question?.id}
                                    className="form-control"
                                    placeholder={
                                      investment.question_response[
                                        question.id.concat("_reason")
                                      ]
                                    }
                                    disabled
                                  />
                                </div>
                              </>
                            )}

                          {question.type === "checkbox" && (
                            <>
                              {question.options?.map((option, index) => {
                                return (
                                  <div key={index}>
                                    <div className="form-check mb-2">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        defaultChecked={
                                          investment.question_response[
                                            option.id
                                          ] === true
                                        }
                                        disabled
                                      />
                                      <label className="form-check-label ms-2">
                                        {option.text}
                                      </label>
                                    </div>
                                  </div>
                                );
                              })}
                            </>
                          )}

                          {[
                            "address_line_1",
                            "address_city",
                            "address_state",
                            "address_postal_code",
                          ].includes(question.id) &&
                            investment.question_response[question.id] && (
                              <p className="fw-bold">
                                {investment.question_response[question.id]}
                              </p>
                            )}

                          {[
                            "text",
                            "ssn",
                            "ein",
                            "email",
                            "phone",
                            "zip",
                          ].includes(question.type) && (
                            <p className="fw-bold">
                              {investment.question_response[question.id]}
                            </p>
                          )}
                        </div>
                      );
                    })}
                    {section.title === "Congrats! Almost finished!" ? (
                      <></>
                    ) : (
                      <hr />
                    )}
                  </div>
                );
              })}
          </div>

          {offering?.status === "Active" &&
            investment?.status === "Investor Review" && (
              <Spinner show={spinner}>
                <div className="form-group">
                  <label className="mb-2">Description / Reason</label>
                  <textarea
                    className="form-control"
                    as="textarea"
                    rows="5"
                    name="description"
                    value={reviewDescription}
                    onChange={(e) => setReviewDescription(e.target.value)}
                  />
                </div>
                <button
                  className="btn btn-eq-primary"
                  onClick={() => handleInvestorApprove()}
                >
                  Approve
                </button>
                <button
                  className="btn btn-light ms-3"
                  onClick={() => handleInvestorReject()}
                >
                  Reject
                </button>
              </Spinner>
            )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default InvestmentOverview;

import API from "./api";

class InvoiceClient {
  static async get(id) {
    const response = await API().get(`/v2/invoices/${id}`);
    return response.data;
  }
}

export default InvoiceClient;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { Formik } from "formik";
import swal from "sweetalert";

import Spinner from "../../components/spinner";
import Header from "../../layouts/header";

import FormControl from "../../components/formControl";
import
  {
    createUser,
    getIssuerById,
    getUserByEmail,
    getUsersByIssuerId,
    removeIssuerUser,
    updateIssuerUserRole,
  } from "../../helpers";

import { isEmpty } from "lodash";
import { issuerUserSchema } from "../../constants/forms";
import roles from "../../data/roles.json";

const defaultValues = {
  first_name: "",
  middle_name: null,
  last_name: "",
  role: "",
};

const OfferingUsers = (props) => {
  const dispatch = useDispatch();

  const { id } = useParams();
  const issuer = useSelector((state) => state.issuer);

  const [users, setUsers] = useState([]);
  const [modal, showModal] = useState(false);
  const [spinner, showSpinner] = useState(false);
  const [formData, setFormData] = useState(defaultValues);

  useEffect(() => {
    const load = async () => {
      const issuer = await getIssuerById(id);
      dispatch({ type: "issuer", payload: issuer });
      getUsers();
    };

    load();
  }, []);

  const getUsers = () => {
    showSpinner(true);
    getUsersByIssuerId(id).then((users) => {
      console.log(
        "users",
        users.filter((user) => isEmpty(user.roles?.issuer) === false)
      );
      let newUsers = users
        .filter((user) => isEmpty(user.roles?.issuer) === false)
        .map((user) => ({
          ...user,
          role: user.roles?.issuer[issuer.id],
        }));

      setUsers(newUsers);
      setFormData(defaultValues);
      showSpinner(false);
      showModal(false);
    });
  };

  const handleUserRole = (form) => {
    showSpinner(true);
    getUserByEmail(form.email)
      .then((user) => {
        updateIssuerUserRole(user.id, issuer.id, form.role).then((_) => {
          getUsers();
          toast.success(
            `${user?.first_name} ${user?.last_name} has been added to ${issuer?.name}`
          );
        });
      })
      .catch((error) => {
        createUser(form).then((user) => {
          updateIssuerUserRole(user.id, issuer.id, form.role).then((_) => {
            getUsers();
            toast.success(
              `An invite has been sent to ${user?.email} to join ${issuer?.name}`
            );
          });
        });
      });
  };

  const handleDelete = async (user) => {
    const status = await swal({
      title: `Remove User`,
      text: `Are you sure you want to remove ${[
        user.first_name,
        user.last_name,
      ].join(" ")}?`,
      buttons: ["Cancel", "Yes"],
      icon: "error",
      dangerMode: true,
    });

    if (!status) return;

    await removeIssuerUser(user.id, issuer.id);
    getUsers();
  };

  return (
    <>
      <Header name="Users" title={issuer?.name}>
        <button
          className="btn btn-eq-primary"
          onClick={() => {
            setFormData(defaultValues);
            showModal(true);
          }}
        >
          New User
        </button>
      </Header>

      <div className="container-fluid">
        <div className="card">
          <Spinner show={spinner}>
            <div className="table-responsive">
              <table className="table table-nowrap table-hover mb-0">
                <thead>
                  <tr>
                    <th>User</th>
                    <th>Title / Company</th>
                    <th>Email</th>
                    <th className="text-center">Role</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {users?.map((user, index) => {
                    return (
                      <tr key={index}>
                        <td className="align-baseline">
                          {user.first_name} {user.last_name}
                        </td>
                        <td className="align-baseline">
                          {[user.title, user.company]
                            .filter(Boolean)
                            .join(", ")}
                        </td>
                        <td className="align-baseline">{user.email}</td>
                        <td className="text-center align-baseline">
                          <span className="badge bg-secondary text-capitalize">
                            {user.roles.issuer[issuer.id]}
                          </span>
                        </td>
                        <td className="text-end align-baseline">
                          <button
                            className="btn btn-sm btn-light"
                            onClick={() => {
                              setFormData(user);
                              showModal(true);
                            }}
                          >
                            <i className="fe fe-edit"></i>
                          </button>
                          <button
                            className="btn btn-sm btn-light ms-1"
                            onClick={() => handleDelete(user)}
                          >
                            <i className="fe fe-trash"></i>
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Spinner>
        </div>
      </div>

      <Modal
        show={modal}
        onHide={() => showModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="my-modal"
      >
        <Modal.Body>
          <Spinner show={spinner}>
            <h1>{formData.id ? "Edit" : "Add"} User</h1>
            <Formik
              enableReinitialize={true}
              initialValues={formData}
              validationSchema={issuerUserSchema}
              onSubmit={handleUserRole}
              validateOnChange={false}
              validateOnBlur={false}
            >
              {({ handleChange, handleSubmit, values, errors }) => {
                const selectedRole = values.role
                  ? roles.filter((x) => x.value === values.role)[0]
                  : null;
                return (
                  <form noValidate onSubmit={handleSubmit}>
                    {!formData.id && (
                      <>
                        <div className="row">
                          <div className="col-4">
                            <FormControl
                              type="text"
                              name="first_name"
                              title="First Name"
                              required={true}
                              placeholder="John"
                              value={values.first_name}
                              error={errors.first_name}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-4">
                            <FormControl
                              type="text"
                              name="middle_name"
                              title="Middle Name"
                              placeholder="J."
                              value={values.middle_name}
                              error={errors.middle_name}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="col-4">
                            <FormControl
                              type="text"
                              name="last_name"
                              title="Last Name"
                              required={true}
                              placeholder="Doe"
                              value={values.last_name}
                              error={errors.last_name}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <FormControl
                          type="email"
                          name="email"
                          title="Email"
                          placeholder="e.g. john@gmail.com"
                          required={true}
                          value={values.email}
                          error={errors.email}
                          onChange={handleChange}
                        />
                      </>
                    )}

                    <FormControl
                      type="select"
                      name="role"
                      title="Role"
                      required={true}
                      options={roles}
                      value={selectedRole}
                      error={errors.role}
                      onChange={handleChange}
                    />

                    <hr />

                    <button className="btn btn-eq-primary" type="submit">
                      Submit
                    </button>
                    <button
                      className="btn btn-outline-secondary ms-2"
                      type="button"
                      onClick={() => showModal(false)}
                    >
                      Cancel
                    </button>
                  </form>
                );
              }}
            </Formik>
          </Spinner>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OfferingUsers;

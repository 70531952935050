import StripeClient from "../clients/StripeClient";
import { useMutation } from "@tanstack/react-query";

export const useStripeSetup = (issuerId) => {
  const { mutateAsync, isLoading, error } = useMutation(() =>
    StripeClient.createSetupIntent(issuerId)
  );

  return {
    setupMutate: mutateAsync,
    setupLoading: isLoading,
    setupError: error,
  };
};

export const usePaymentMethod = (issuerId, setupIntentId) => {
  const { mutateAsync, isLoading, error } = useMutation(() =>
    StripeClient.savePaymentMethod(issuerId, setupIntentId)
  );

  return {
    paymentMutate: mutateAsync,
    setupLoading: isLoading,
    setupError: error,
  };
};

export const getSetupIntent = (issuerId) => {
  return new Promise(function (resolve, reject) {
    StripeClient.getSetupIntent(issuerId)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const getPaymentMethod = (issuerId) => {
  return new Promise(function (resolve, reject) {
    StripeClient.getPaymentMethod(issuerId)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

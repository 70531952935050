import React from "react";

import clsx from "clsx";
import styles from "./agreements.module.css";

const Agreements = ({
  offering,
  investment,
  statusagreements,
  onDownloadAgreement,
}) => {
  return (
    <div className="card">
      <div className="card-header">
        <h4 className="card-header-title">Agreements</h4>
      </div>
      <div className="card-body p-0 px-4">
        <div className="list-group list-group-flush">
          <div className="col">
            {offering?.agreements?.map((agreement, i) => {
              return (
                <div key={i} className="col">
                  {agreement.name}
                </div>
              );
            })}
          </div>
          <div className="d-flex justify-content-between ">
            <div>
              <div className="d-flex mt-1">
                {investment?.status === "Invite Sent" ||
                investment?.status === "Investor Review" ||
                investment?.status === "Investor Approved" ? (
                  <>
                    <div>
                      <span className="badge bg-warning ms-1 mb-1">
                        Investor
                      </span>
                      <span className="badge bg-warning ms-1 mb-1">Issuer</span>
                    </div>
                  </>
                ) : (
                  statusagreements?.map((DocuSign, index) => {
                    const statusBg =
                      DocuSign?.status === "completed" ? "success" : "warning";
                    const status =
                      DocuSign?.status === "completed" ? "complete" : "pending";

                    return (
                      <div className="col" key={index}>
                        <span
                          className={clsx(
                            styles.docusignRole,
                            `badge bg-${statusBg} ms-1`
                          )}
                        >
                          {DocuSign.roleName} {status}
                        </span>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
            {offering?.agreements?.map((agreement, index) => {
              const myAgreement =
                investment?.agreements?.filter(
                  (x) => x.template_id === agreement.template_id
                ) || [];

              const investorSigned =
                myAgreement.length > 0 &&
                myAgreement[0].investor_status === "complete";

              return (
                <div key={index}>
                  <div className="row g-2">
                    <div className="col-auto mb-2">
                      {investorSigned && (
                        <button
                          className="btn btn-sm btn-light ms-1"
                          onClick={() => onDownloadAgreement(myAgreement[0])}
                        >
                          Download
                        </button>
                      )}
                      {/* {amount > 0 && balance === 0 && !issuerSigned && <button className='btn btn-sm btn-eq-primary ms-1' onClick={() => { handleAccept(myAgreement[0].envelope_id) }}>Accept Investment</button>} */}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Agreements;

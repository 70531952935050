import API from "./api";

class UserClient {
  static async invite(data) {
    const response = await API().post("/v2/users/invite", data);
    return response.data;
  }

  static async me() {
    try {
      const response = await API().get("/me");
      return response.data;
    } catch (e) {
      return null;
    }
  }
}

export default UserClient;

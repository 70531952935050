import React from 'react';

const Card = ({ title, children, editable, onClick }) => {



  return (
    <div className='col'>
      <div className='card'>
        <div className='card-body'>
          <div className='row align-items-center'>
            <div className='col'>
              <div className='d-flex justify-content-between'>
                <h6 className='text-uppercase text-muted'>{title}</h6>
                {editable && <span onClick={onClick}><i className='fe fe-edit' /></span>}
              </div>
              <h2 className='mb-0'>{children}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
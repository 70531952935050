import { useMutation, useQuery } from "@tanstack/react-query";

import InvestmentClient from "../clients/InvestmentClient";

/**
 * @param {string} id
 * @param {import("@tanstack/react-query").QueryObserverOptions} options
 */
export const useInvestment = (id, options = {}) => {
  return useQuery(
    ["investment", id],
    () => InvestmentClient.get(id),
    options ?? {}
  );
};

/**
 * @param {string} id
 * @param {import("@tanstack/react-query").UseMutationOptions} options
 */
export const useInvestmentAddRefund = (id, options = {}) => {
  return useMutation(
    ({ data }) => InvestmentClient.legacyAddRefund(id, data),
    options
  );
};

/**
 * @param {string} id
 * @param {import("@tanstack/react-query").UseMutationOptions} options
 */
export const useInvestmentAddManualPayment = (id, options = {}) => {
  return useMutation(
    ({ data }) => InvestmentClient.legacyAddManualPayment(id, data),
    options
  );
};

/**
 * @param {string} id
 * @param {"accreditation" | "investor-info" | "approve-accreditation"} stage
 * @param {import("@tanstack/react-query").UseMutationOptions} options
 */
export const useInvestmentUpdateStage = (id, stage, options = {}) => {
  return useMutation(
    ({ data } = {}) => InvestmentClient.updateStage(id, stage, data),
    options
  );
};

/**
 * @param {string} id
 * @param {import("@tanstack/react-query").QueryObserverOptions} options
 */
export const useInvestmentInquiry = (id, options = {}) => {
  return useQuery(
    ["investment", id, "inquiry"],
    () => InvestmentClient.getInquiry(id),
    {
      enabled: !!id,
      ...options,
    }
  );
};

/**
 * @param {string} id
 * @param {import("@tanstack/react-query").UseMutationOptions} options
 */
export const useUpdateInvestment = (id, options = {}) => {
  return useMutation(({ data }) => InvestmentClient.update(id, data), options);
};

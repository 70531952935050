export const PersonaDashboardBase = new URL("https://app.withpersona.com");

export const INQUIRY_STATUS_ALERT_TYPE = {
  completed: "light",
  approved: "success",
  declined: "danger",
  needs_review: "secondary",
  pending: "light",
  created: "light",
  expired: "warning",
};

export const INQUIRY_STATUS_TEXT = {
  completed: "Completed",
  approved: "Approved",
  declined: "Declined",
  needs_review: "Needs Review",
  pending: "Pending",
  created: "Created",
  expired: "Expired",
};

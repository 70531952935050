import { useFormik } from "formik";
import React from "react";
import * as Yup from "yup";

import RadioListItem from "../RadioListItem";

const IndividualAccreditationCertification = ({
  user,
  stage,
  disabled = false,
  showButton = true,
  values = {},
  onContinue = () => {},
}) => {
  const formik = useFormik({
    initialValues: {
      accreditation_type: undefined,
      ...values,
    },
    validationSchema: Yup.object({
      accreditation_type: Yup.string().required(),
    }),
    onSubmit: (values) => {
      onContinue(values);
    },
  });

  if (!user || stage !== "accreditation" || user?.entity_type === "ENTITY") {
    return null;
  }

  return (
    <div className="mb-4">
      <h2 className="mt-4">Accredited Investor Certification - Individual</h2>
      <p>
        The undersigned makes one of the following representations regarding its
        income, net worth, status as a “family client” of a “family office,”
        and/or certain professional certifications or designations and certain
        related matters and has checked the applicable representation is listed
        below.
      </p>

      <p>
        <strong>Please select the provided accurate statement.</strong>
      </p>

      <form onSubmit={formik.handleSubmit}>
        <ul className="list-unstyled">
          <li>
            <RadioListItem
              formik={formik}
              name="accreditation_type"
              value="INCOME"
              disabled={disabled}
              onChange={formik.handleChange}
            >
              The undersigned&apos;s income during each of the last two years
              exceeded $200,000 or, if the undersigned is married or has a
              spousal equivalent, the joint income of the undersigned and the
              undersigned&apos;s spouse or spousal equivalent, as applicable,
              during each of the last two years exceed $300,000, and the
              undersigned reasonably expects the undersigned&apos;s income, from
              all sources during this year, will exceed $200,000 or, if the
              undersigned is married or has a spousal equivalent, the joint
              income of undersigned and the undersigned&apos;s spouse or spousal
              equivalent, as applicable, from all sources during this year will
              exceed $300,000.
            </RadioListItem>
          </li>
          <li>
            <RadioListItem
              formik={formik}
              name="accreditation_type"
              value="NET_WORTH"
              disabled={disabled}
              onChange={formik.handleChange}
            >
              The undersigned&apos;s net worth, including the net worth of the
              undersigned&apos;s spouse or spousal equivalent, as applicable, is
              in excess of $1,000,000 (excluding the value of the
              undersigned&apos;s primary residence).
            </RadioListItem>
          </li>
          <li>
            <RadioListItem
              formik={formik}
              name="accreditation_type"
              value="CERTIFIED"
              disabled={disabled}
              onChange={formik.handleChange}
            >
              The undersigned is a holder in good standing of one or more of the
              following certifications or designations administered by the
              Financial Industry Regulatory Authority, Inc. (FINRA): the
              Licensed General Securities Representative (Series 7), Licensed
              Investment Adviser Representative (Series 65), or Licensed Private
              Securities Offerings Representative (Series 82).
            </RadioListItem>
          </li>
          <li>
            <RadioListItem
              formik={formik}
              name="accreditation_type"
              value="FAMILY_CLIENT"
              disabled={disabled}
              onChange={formik.handleChange}
            >
              The undersigned is a “family client,” as defined in rule
              202(a)(11)(G)-1 under the Investment Advisers Act of 1940, as
              amended (the “Advisers Act”), of a family office as defined in
              rule 202(a)(11)(G)-1 under the Advisers Act, (i) with assets under
              management in excess of $5,000,000, (ii) that is not formed for
              the specific purpose of acquiring the securities offered, and
              (iii) whose prospective investment is directed by a person who has
              such knowledge and experience in financial and business matters
              that such family office is capable of evaluating the merits and
              risks of the prospective investment, and whose prospective
              investment is directed by such family office pursuant to clause
              (iii) of this sentence.
            </RadioListItem>
          </li>
        </ul>

        <p>
          <strong>Please select the provided accurate statement.</strong>
        </p>

        {showButton && (
          <button
            type="submit"
            className="btn btn-sm btn-eq-primary"
            disabled={!formik.dirty ? true : !formik.isValid}
          >
            Continue <i className="fe fe-arrow-right-circle ms-1"></i>
          </button>
        )}
      </form>
    </div>
  );
};

export default IndividualAccreditationCertification;

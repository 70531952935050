/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { groupBy } from "lodash";
import Spinner from "../../components/spinner";
import { isOfferingViewer, updateSettingById } from "../../helpers";
import { useOffering, useOfferingSettings } from "../../hooks/useOfferings";
import Header from "../../layouts/header";
import SettingsGroup from "./components/SettingsGroup";

const Settings = (props) => {
  const { id } = useParams();
  const { data: offering } = useOffering(id);
  const {
    data: settings,
    isLoading: settingsIsLoading,
    refetch: refetchSettings,
  } = useOfferingSettings(id);

  const user = useSelector((state) => state.user);
  const [spinner, showSpinner] = useState(false);
  const [setting, setSetting] = useState({});
  const [modal, showModal] = useState(false);

  const updateSetting = async () => {
    showSpinner(true);
    try {
      await updateSettingById(offering.id, setting.id, setting.value);
      await refetchSettings();
      showModal(false);
      setSetting({});
    } catch (e) {}
    showSpinner(false);
  };

  const settingGroups = useMemo(
    () => groupBy(settings, "category"),
    [settings]
  );

  const handleEditSetting = useCallback((item) => {
    setSetting(item);
    showModal(true);
  }, []);

  const isEditable = useMemo(
    () =>
      !isOfferingViewer(user, offering?.legacy_id) &&
      ["In Review", "Draft"].includes(offering?.status),
    [user, offering?.legacy_id, offering?.status]
  );

  return (
    <>
      <Header id={id} name="Settings" title={offering?.name} />

      <Spinner show={settingsIsLoading || spinner}>
        <div className="container-fluid">
          <SettingsGroup
            user={user}
            title="Email Settings"
            subtitle="Email Credentials"
            onButtonClick={handleEditSetting}
            isEditable={isEditable}
            settings={settingGroups.email}
          />

          <SettingsGroup
            user={user}
            title="Email Templates"
            subtitle="Sendgrid Template Values"
            onButtonClick={handleEditSetting}
            isEditable={isEditable}
            settings={settingGroups.email_template}
          />

          <SettingsGroup
            user={user}
            title="Bank Settings"
            subtitle="Account Summary"
            onButtonClick={handleEditSetting}
            isEditable={isEditable}
            settings={settingGroups.bank}
          />

          <SettingsGroup
            user={user}
            title="Authorized Signatory"
            subtitle="ISSUER Authorized SIGNATORY DETAILS"
            adjustName={(name) =>
              name.replace(/^Authori(s|z)ed Signatory\s/i, "")
            }
            onButtonClick={handleEditSetting}
            isEditable={isEditable}
            settings={settingGroups.docusign}
          />

          <SettingsGroup
            user={user}
            title="Transfer Agent"
            subtitle="Authorized Agent Details"
            adjustName={(name) => name.replace(/^Transfer Agent:\s/i, "")}
            onButtonClick={handleEditSetting}
            isEditable={isEditable}
            settings={settingGroups.transfer_agent}
          />

          <SettingsGroup
            user={user}
            title="Escrow Agent"
            subtitle="Authorized Agent Details"
            adjustName={(name) => name.replace(/^Escrow Agent:\s/i, "")}
            onButtonClick={handleEditSetting}
            isEditable={isEditable}
            settings={settingGroups.escrow_agent}
          />

          <SettingsGroup
            user={user}
            title="Other Settings"
            onButtonClick={handleEditSetting}
            isEditable={isEditable}
            settings={settingGroups.other}
          />

          <Modal
            show={modal}
            onHide={() => {
              setSetting({});
              showModal(false);
            }}
          >
            <Modal.Header>
              <h3 className="mb-0">{setting.name}</h3>
            </Modal.Header>
            <Modal.Body>
              <input
                type="text"
                name="value"
                className="form-control"
                value={setting.value}
                onChange={(e) =>
                  setSetting({ ...setting, value: e.target.value })
                }
              />
              <hr />
              <button
                className="btn btn-eq-primary"
                onClick={() => updateSetting()}
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-link me-2"
                onClick={() => {
                  setSetting({});
                  showModal(false);
                }}
              >
                Cancel
              </button>
            </Modal.Body>
          </Modal>
        </div>
      </Spinner>
    </>
  );
};

export default Settings;

import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Switch } from "react-router-dom";

import AdminLayout from "./layouts/admin";
import PublicLayout from "./layouts/public";
import AppRoute from "./layouts/route";

import CompleteProfile from "./pages/auth/completeProfile";
import Forgot from "./pages/auth/forgot";
import Login from "./pages/auth/login";
import MFA from "./pages/auth/mfa";
import Reset from "./pages/auth/reset";

import OfferingList from "./pages/offering";
import OfferingCreate from "./pages/offering/create";
import OfferingOverview from "./pages/offering/overview";
import OfferingWizard from "./pages/offering/wizard";

import Investment from "./pages/offering/InvestmentOverview";
import OfferingInvestments from "./pages/offering/investors";

import BrokerList from './pages/brokers';
import BrokerEditor from './pages/brokers/editor';

import OfferingAgreements from "./pages/offering/agreements";
import OfferingBrokers from "./pages/offering/brokers";
import BulkInvite from "./pages/offering/bulkInvite";
import OfferingContacts from "./pages/offering/contacts";
import OfferingDocuments from "./pages/offering/documents";
import OfferingEvents from "./pages/offering/events";
import OfferingSettings from "./pages/offering/settings";

import issuerList from "./pages/issuer";
import issuerEditor from "./pages/issuer/editor";
import issuerOfferings from "./pages/issuer/offerings";
import issuerUsers from "./pages/issuer/users";

import MissingPage from "./pages/MissingPage";
import MyProfile from "./pages/auth/profile";
import InvoiceList from "./pages/invoices";
import { Invoice } from "./pages/invoices/Invoice";
import Payment from "./pages/invoices/Payment";
import Success from "./pages/invoices/Success";
import OfferingCertificate from "./pages/offering/Certificate";

import UserClient from "./clients/UserClient";
import ScrollToTop from "./components/ScrollToTop";

const useCurrentUser = () => {
  return useQuery(["user", "me"], () => UserClient.me(), {
    refetchInterval: 100_000,
    refetchIntervalInBackground: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
  })
}

const Routes = () => {
  const user = useSelector((state) => state.user);
  const hasAccess = user?.roles?.admin;

  useCurrentUser();

  return (
    <Router>
      {/* Uses a hook under the hook. Some nuance may be needed page-to-page */}
      <ScrollToTop />
      <Switch>
        <AppRoute exact path="/" layout={PublicLayout} component={Login} />
        <AppRoute exact path="/mfa" layout={PublicLayout} component={MFA} />
        <AppRoute
          exact
          path="/forgot"
          layout={PublicLayout}
          component={Forgot}
        />
        <AppRoute
          exact
          path="/reset/:id/:token"
          layout={PublicLayout}
          component={Reset}
        />
        <AppRoute
          exact
          path="/complete-profile/:token"
          layout={PublicLayout}
          component={CompleteProfile}
        />

        {hasAccess && (
          <AppRoute
            exact
            path="/brokers"
            layout={AdminLayout}
            component={BrokerList}
          />
        )}
        {hasAccess && (
          <AppRoute
            exact
            path="/brokers/create"
            layout={AdminLayout}
            component={BrokerEditor}
          />
        )}
        {hasAccess && (
          <AppRoute
            exact
            path="/brokers/:id/broker"
            layout={AdminLayout}
            component={BrokerEditor}
          />
        )}

        {hasAccess && (
          <AppRoute
            exact
            path="/issuer"
            layout={AdminLayout}
            component={issuerList}
          />
        )}
        {hasAccess && (
          <AppRoute
            exact
            path="/issuer/create"
            layout={AdminLayout}
            component={issuerEditor}
          />
        )}
        {hasAccess && (
          <AppRoute
            exact
            path="/issuer/:id/edit"
            layout={AdminLayout}
            component={issuerEditor}
          />
        )}
        {hasAccess && (
          <AppRoute
            exact
            path="/issuer/:id/offerings"
            layout={AdminLayout}
            component={issuerOfferings}
          />
        )}
        {hasAccess && (
          <AppRoute
            exact
            path="/issuer/:id/users"
            layout={AdminLayout}
            component={issuerUsers}
          />
        )}

        <AppRoute
          exact
          path="/invoices"
          layout={AdminLayout}
          component={InvoiceList}
        />
        <AppRoute
          exact
          path="/payment"
          layout={AdminLayout}
          component={Payment}
        />
        <AppRoute
          exact
          path="/payment/success"
          layout={AdminLayout}
          component={Success}
        />
        <AppRoute
          exact
          path="/invoice/:id"
          layout={AdminLayout}
          component={Invoice}
        />
        <AppRoute
          exact
          path="/offering"
          layout={AdminLayout}
          component={OfferingList}
        />
        <AppRoute
          exact
          path="/offering/create"
          layout={AdminLayout}
          component={OfferingCreate}
        />
        <AppRoute
          exact
          path="/offering/:id/edit"
          layout={AdminLayout}
          component={OfferingCreate}
        />
        <AppRoute
          exact
          path="/offering/:id/certificate"
          layout={AdminLayout}
          component={OfferingCertificate}
        />
        <AppRoute
          exact
          path="/offering/:id/wizard/:section"
          layout={AdminLayout}
          component={OfferingWizard}
        />
        <AppRoute
          exact
          path="/offering/:id/overview"
          layout={AdminLayout}
          component={OfferingOverview}
        />
        <AppRoute
          exact
          path="/offering/:id/investments"
          layout={AdminLayout}
          component={OfferingInvestments}
        />
        <AppRoute
          exact
          path="/offering/:id/investment/:investment_id/overview"
          layout={AdminLayout}
          component={Investment}
        />
        <AppRoute
          exact
          path="/offering/:id/contacts"
          layout={AdminLayout}
          component={OfferingContacts}
        />
        <AppRoute
          exact
          path="/offering/:id/brokers"
          layout={AdminLayout}
          component={OfferingBrokers}
        />
        <AppRoute
          exact
          path="/offering/:id/documents"
          layout={AdminLayout}
          component={OfferingDocuments}
        />
        <AppRoute
          exact
          path="/offering/:id/agreements"
          layout={AdminLayout}
          component={OfferingAgreements}
        />
        <AppRoute
          exact
          path="/offering/:id/events"
          layout={AdminLayout}
          component={OfferingEvents}
        />
        <AppRoute
          exact
          path="/offering/:id/settings"
          layout={AdminLayout}
          component={OfferingSettings}
        />
        <AppRoute
          exact
          path="/offering/:id/bulk-invite"
          layout={AdminLayout}
          component={BulkInvite}
        />

        <AppRoute
          exact
          path="/profile"
          layout={AdminLayout}
          component={MyProfile}
        />
        <AppRoute
          exact
          path="*"
          layout={PublicLayout}
          component={MissingPage}
        />
      </Switch>
    </Router>
  );
};

export default Routes;

/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import axios from "axios";
import { Formik } from "formik";
import moment from "moment-timezone";
import swal from "sweetalert";

import Spinner from "../../components/spinner";
import Header from "../../layouts/header";
// import { SelectField } from '../../components/selectField';
//import { USStates } from '../../constants';
import { eventFormSchema } from "../../constants/forms";
import { getOfferingById, isOfferingViewer } from "../../helpers";
import { filterPassedTime } from "../../helpers/utils";
import { useOffering } from "../../hooks/useOfferings";
// import state from 'sweetalert/typings/modules/state';

// import states from "../../data/states.json";

const Events = (props) => {
  const dispatch = useDispatch();

  const { id } = useParams();
  const { data: offering, refetch: refetchOffering } = useOffering(id);
  // const offering = useSelector((state) => state.offering);
  const user = useSelector((state) => state.user);

  const defaultEvent = {
    name: "",
    date: "",
    timezone: "",
    allow_offline: false,
    event_url: "",
    address: {
      line_1: "",
      city: "",
      state: "",
      country: "United States",
      postal_code: "",
    },
  };

  const [modal, showModal] = useState(false);
  const [spinner, showSpinner] = useState(false);
  const [event, setEvent] = useState(defaultEvent);
  const [eventDate, setEventDate] = useState(null);

  const handleDelete = async (event) => {
    const status = await swal({
      title: `Remove Event`,
      text: `Are you sure you want to remove ${event.name}?`,
      buttons: ["Cancel", "Yes"],
      icon: "error",
      dangerMode: true,
    });

    if (status) {
      showSpinner(true);
      axios
        .delete(`offering/${offering.id}/event/${event.id}`)
        .then((response) => {
          getOfferingById(id).then((offering) => {
            dispatch({ type: "offering", payload: offering });
            toast.success(`${event.name} has been removed.`);
            showSpinner(false);
          });
        });
    }
  };

  const handleSubmit = async (form) => {

    let date = moment.utc(form.date, form.timezone) // converting the time to UTC format
    const data = {
      name: form.name,
      description: form.description,
      date: date.toISOString(),
      timezone: form.timezone,
      allow_offline: form.allow_offline,
      event_url: form.event_url,
      address: {
        line_1: form.address?.line_1,
        line_2: form.address?.line_2,
        city: form.address?.city,
        state: form.address?.state,
        country: "United States",
        postal_code: form.address?.postal_code,
      },
    };
    const url = form.id
      ? `offering/${offering.id}/event/${event.id}`
      : `offering/${offering.id}/event`;

    await axios.post(url, data);
    await refetchOffering();
    showSpinner(false);
    showModal(false);
  };


  const setLocalZone = (date, timezone) => {
    const dateWithoutZone = moment.tz(date, timezone).format("YYYY-MM-DDTHH:mm:ss.SSS")
    const localZone = moment(dateWithoutZone).format("Z")
    const dateWithLocalZone = [dateWithoutZone, localZone].join("")
    return new Date(dateWithLocalZone)
  }

  const setOtherZone = (date, timezone) => {
    const dateWithoutZone = moment(date).format("YYYY-MM-DDTHH:mm:ss.SSS")
    const otherZone = moment.tz(date, timezone).format("Z")
    const dateWithOtherZone = [dateWithoutZone, otherZone].join("")
    return new Date(dateWithOtherZone)
  }

  const canEdit = !isOfferingViewer(user, offering?.legacy_id)

  return (
    <>
      <Header id={id} name="Events" title={offering?.name}>
        {canEdit && (
          <button
            className="btn btn-eq-primary"
            onClick={() => {
              setEvent(defaultEvent);
              showModal(true);
            }}
          >
            New Event
          </button>
        )}
      </Header>

      <Spinner show={spinner}>
        <div className="container-fluid">
          <div className="card">
            <div className="table-responsive">
              <table
                size="sm"
                className="table table-hover table-nowrap card-table "
              >
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Date</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {offering?.events?.map((item) => {
                    return (
                      <tr key={item.id}>
                        <td className="align-baseline">{item.name}</td>
                        <td className="align-baseline">
                          <span className="ms-2">{moment.tz(item.date, item.timezone).format('lll z')}</span>
                        </td>
                        <td className="text-end align-baseline">
                          {canEdit && (
                            <button
                              className="btn btn-sm btn-light ms-1"
                              onClick={() => {
                                setEvent(item);
                                setEventDate(item.date);
                                showModal(true);
                              }}
                            >
                              <i className="fe fe-edit" />
                            </button>
                          )}
                          {canEdit && (
                            <button
                              className="btn btn-sm btn-light ms-1"
                              onClick={() => handleDelete(item)}
                            >
                              <i className="fe fe-trash" />
                            </button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          <Modal show={modal} onHide={() => showModal(false)}>
            <Modal.Body>
              <h2 className="mb-0">{event.id ? "Edit" : "Add"} Event</h2>
              <hr />
              <Formik
                enableReinitialize
                initialValues={event}
                validationSchema={eventFormSchema}
                onSubmit={handleSubmit}
                validateOnChange={false}
                validateOnBlur={false}
              >
                {({
                  handleChange,
                  handleSubmit,
                  values,
                  errors,
                  touched,
                  handleBlur,
                  setFieldValue,
                }) => (
                  <form noValidate onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label>
                        Name <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        placeholder=" eg: Deloitte"
                        value={values.name || ""}
                        onChange={handleChange}
                      />
                      {errors.name && touched.name && (
                        <div className="text-danger mt-2 ms-1 h5">
                          {errors.name}
                        </div>
                      )}
                    </div>
                    <div className="form-group d-flex flex-column">
                      <label>
                        Timezone <span className="text-danger">*</span>
                      </label>
                      <select
                        className="form-control"
                        name="timezone"
                        onChange={handleChange}
                      >
                        <option selected disabled>Select Timezone</option>
                        <option value={"America/New_york"} selected={values.timezone === "America/New_york" ? true : false}>Eastern Time Zone</option>
                        <option value={"America/Matamoros"} selected={values.timezone === "America/Matamoros" ? true : false}>Central Time Zone</option>
                        <option value={"America/Edmonton"} selected={values.timezone === "America/Edmonton" ? true : false}>Mountain Time Zone</option>
                        <option value={"America/Los_Angeles"} selected={values.timezone === "America/Los_Angeles" ? true : false}>Pacific Time Zone</option>
                        <option value={"America/Sitka"} selected={values.timezone === "America/Sitka" ? true : false}>Alaska Time Zone</option>
                        <option value={"Pacific/Honolulu"} selected={values.timezone === "Pacific/Honolulu" ? true : false} > Hawaii-Aleutian Time Zone</option>
                      </select>
                      {errors.timezone && touched.timezone && (
                        <div className="text-danger mt-2 ms-1 h5">
                          {errors.timezone}
                        </div>
                      )}
                    </div>
                    <div className="form-group d-flex flex-column">
                      <label className={`${!values.timezone ? 'text-muted' : ''}`} data-toggle="tooltip" data-placement="top" title={`Please select timezone`}>
                        Date <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" style={{ width: '18px' }}  >
                          <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" /></svg><span className="text-danger">*</span>
                      </label>

                      <DatePicker
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        autoComplete="off"
                        name="date"
                        showTimeSelect
                        className="form-control"
                        filterTime={filterPassedTime}
                        minDate={moment().toDate()}
                        dateFormat="MMM d, yyyy h:mm aa"
                        disabled={!values.timezone}
                        selected={values.date ? setLocalZone(values.date, values.timezone) : null}
                        onChange={(date) => { setOtherZone(date, values.timezone); setFieldValue("date", setOtherZone(date, values.timezone)); }}
                      />
                      {errors.date && touched.date && (
                        <div className="text-danger mt-2 ms-1 h5">
                          {errors.date}
                        </div>
                      )}
                    </div>

                    <div className="row d-flex align-items-center">
                      <div className="col">
                        <label className="h4 mb-0">
                          Do you have a offline event
                        </label>
                      </div>
                      <div className="col-auto">
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="allow_offline"
                            onChange={(e) => {
                              handleChange(e);
                              setFieldValue("address", event.address);
                              setFieldValue("event_url", event.event_url);
                            }}
                            value={values.allow_offline}
                            checked={values.allow_offline}
                          />
                        </div>
                      </div>
                    </div>
                    {!values.allow_offline && (
                      <div className="form-group mt-3">
                        <label>
                          Event URL <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="event_url"
                          placeholder="eg: meet.google.com/qtc"
                          value={values.event_url}
                          onChange={handleChange}
                        />
                        {errors.event_url && touched.event_url && (
                          <div className="text-danger mt-2 ms-1 h5">
                            {errors.event_url}
                          </div>
                        )}
                      </div>
                    )}

                    {values.allow_offline && (
                      <>
                        <div className="form-group">
                          <label>
                            Address line 1{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            name="address[line_1]"
                            className="form-control"
                            placeholder=""
                            value={values.address?.line_1}
                            onChange={handleChange}
                          />
                          {errors.address?.line_1 &&
                            touched.address?.line_1 && (
                              <div className="text-danger mt-2 ms-1 h5">
                                {errors.address?.line_1}
                              </div>
                            )}
                        </div>
                        <div className="form-group">
                          <label>Address line 2</label>
                          <input
                            type="text"
                            name="address[line_2]"
                            className="form-control"
                            placeholder=""
                            value={values.address?.line_2}
                            onChange={handleChange}
                          />
                        </div>

                        <div className="row">
                          <div className="col col-12 col-md-6">
                            <div className="form-group">
                              <label>
                                City <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                name="address[city]"
                                className="form-control"
                                placeholder=""
                                value={values.address?.city}
                                onChange={handleChange}
                              />
                              {errors.address?.city &&
                                touched.address?.city && (
                                  <div className="text-danger mt-2 ms-1 h5">
                                    {errors.address?.city}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="col col-12 col-md-6">
                            <div className="form-group">
                              <label>
                                State <span className="text-danger">*</span>
                              </label>
                              {/* <SelectField required error={errors.address?.state} id='role' name='address[state]' placeholder='Select' options={states} value={values.address?.state} onChange={setFieldValue} /> */}
                              <input
                                type="text"
                                name="address[state]"
                                className="form-control"
                                placeholder=""
                                value={values.address?.state}
                                onChange={handleChange}
                              />
                              {errors.address?.state &&
                                touched.address?.state && (
                                  <div className="text-danger mt-2 ms-1 h5">
                                    {errors.address?.state}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="col col-12 col-md-6">
                            <div className="form-group">
                              <label>
                                Country <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                name="address[country]"
                                className="form-control"
                                value={values.address?.country}
                                disabled
                              />
                            </div>
                          </div>
                          <div className="col col-12 col-md-6">
                            <div className="form-group">
                              <label>
                                Postal Code{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                name="address[postal_code]"
                                className="form-control"
                                placeholder=""
                                value={values.address?.postal_code}
                                onChange={handleChange}
                              />
                              {errors.address?.postal_code &&
                                touched.address?.postal_code && (
                                  <div className="text-danger mt-2 ms-1 h5">
                                    {errors.address?.postal_code}
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    <div className="form-group">
                      <label>Description</label>
                      <textarea
                        className="form-control"
                        as="textarea"
                        rows="5"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                      />
                    </div>
                    <hr />
                    <div className="mt-3">
                      <button className="btn btn-eq-primary" type="submit">
                        {event.id ? "Update" : "Continue"}
                      </button>
                      <button
                        className="btn btn-link ms-2"
                        type="button"
                        onClick={() => showModal(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </Modal.Body>
          </Modal>
        </div>
      </Spinner>
    </>
  );
};

export default Events;
